import React from 'react'
import { Col, Row } from 'rsuite';
import AreaTrafficTable from './AreaTrafficTable';
import JourneyTrafficTable from './JourneyTrafficTable';
import TransportLineTrafficTable from './TransportLineTrafficTable';
import { t } from 'i18next';
import MediaQueryContext from 'context/MediaQueryContext';

const StopPlaceTrafficPanelContent = React.forwardRef(({
    height = 500,
    ...props
}, ref) => {
    
    const stopPlacesRef = React.useRef();
    const transportLinesRef = React.useRef();
    const journeysRef = React.useRef();

    const {isMobile} = React.useContext(MediaQueryContext);

    React.useImperativeHandle(ref, () => ({
        tables: [{
            label: t('FT/StopPlaceTraffic'),
            ref: stopPlacesRef
        }, {
            label: t('FT/TransportLineTraffic'),
            ref: transportLinesRef
        }, {
            label: t('FT/JourneyTraffic'),
            ref: journeysRef
        }]
    }))

    return (
        <Row>
            <Col xs={24} lg={12}>

                <AreaTrafficTable 
                    ref={stopPlacesRef}
                    virtualized
                    height={isMobile ? height/3 : height}
                    {...props}
                    defaultVisibleColumns={{}} />
                
            </Col>
            <Col xs={24} lg={12}>
                
                <TransportLineTrafficTable
                    ref={transportLinesRef}
                    selectedIntervalOnly
                    virtualized
                    height={isMobile ? height/3 : height/2}
                    {...props} />

                <JourneyTrafficTable
                    ref={journeysRef}
                    virtualized
                    height={isMobile ? height/3 : height/2}
                    {...props} />
            </Col>
        </Row>
    )
});

export default StopPlaceTrafficPanelContent