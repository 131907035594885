import { arrayStats } from "utils/math";
import { t } from "i18next";
import areaLabeling from "features/onboardCount/lib/arcGISLabels/areaLabeling";
import areaRenderer from "features/onboardCount/lib/arcGISRenderers/areaRenderer";
import stopPlaceTrafficRenderer from "features/onboardCount/lib/arcGISRenderers/stopPlaceTrafficRenderer";
import { onboardCountColors } from "../colors";
import { getTraffic } from "../traffic";
import { EOV, Join } from "features/webmap";


function getAreaFeatures(areas, countInterval) {
    if (!areas) return {};

    const areaValues = [];
    const records = areas.map(area => {
        const traffic = getTraffic(area, countInterval);
        areaValues.push(traffic.alight.avg, traffic.board.avg);
        return {
                elementId: area.id, 
                elementType: 'area',
                geometry: area.geometry,
                name: area.name,
                alight: traffic.alight.avg,
                board: traffic.board.avg,
        };
    });

    
    const stats = arrayStats(areaValues);

    console.log({records})

    return {
        records,
        stats,
    };
};

function getAreaGeometry (record) {
    return record.geometry
}

function getAreaCenterPoint (record) {
    return {
        x: record.geometry.map(({x}) => x).avg(),
        y: record.geometry.map(({y}) => y).avg(),
    }
}


function getAreaMarkerJoin({
    records,
    labelStopPlaces = true,
}) {
    return Join({
        name: 'area',
        index: 0,
        records,
        fieldTypes: {
            elementId: 'integer',
            elementType: 'string',
            name: 'string',
            alight: 'integer',
            board: 'integer',
        },
        baseField: 'elementId',
        targetField: 'elementId',
        objectIdField: 'id',
        geometryType: 'polygon',
        getGeometry: getAreaGeometry,
        definitionExpression: 'alight > 0 OR board > 0',
        labelingInfo: labelStopPlaces && areaLabeling(),
        renderer: areaRenderer(),
        legendEnabled: false,
    })
};


function getAreaAlightJoin({
    records,
    stats,
    minSize = 5,
    maxSize = 100,
}) {
    return Join({
        records,
        index: 7,
        fieldTypes: {
            elementId: 'integer',
            alight: 'integer',
        },
        fieldLabels: {
            alight: t('FT/DailyAverageAlightCount'),
        },
        baseField: 'elementId',
        targetField: 'elementId',
        objectIdField: 'id',
        label: t('FT/Areas'),
        legendEnabled: true,
        geometryType: 'point',
        getGeometry: getAreaCenterPoint,
        definitionExpression: 'alight > 0',
        renderer: stopPlaceTrafficRenderer({
            field: 'alight',
            minSize,
            maxSize,
            ...stats
        }),
    });
};


function getAreaBoardJoin({
    records,
    stats,
    minSize = 5,
    maxSize = 100,
}) {
    return Join({
        records,
        index: 7,
        fieldTypes: {
            elementId: 'integer',
            board: 'integer',
        },
        fieldLabels: {
            board: t('FT/DailyAverageBoardCount'),
        },
        baseField: 'elementId',
        targetField: 'elementId',
        objectIdField: 'id',
        label: t('FT/Areas'),
        legendEnabled: true,
        geometryType: 'point',
        getGeometry: getAreaCenterPoint,
        definitionExpression: 'board > 0',
        renderer: stopPlaceTrafficRenderer({
            field: 'board',
            color: onboardCountColors.board,
            minSize,
            maxSize,
            ...stats
        }),
    });
};


export function getAreaJoins(areas, {
    minSize=5,
    maxSize=100,
    labelStopPlaces=true,
} = {}) {
    const { records, stats } = getAreaFeatures(areas);

    return [
        getAreaMarkerJoin({ records, labelStopPlaces }),
        getAreaAlightJoin({ records, stats, minSize, maxSize }),
        getAreaBoardJoin({ records, stats, minSize, maxSize }),
    ];
};

