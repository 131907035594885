import React from "react";
import { Breadcrumb, Container, Content, Header, IconButton, Nav, Sidebar, Whisper } from "rsuite";

import { NavBar } from "components/pages";
import SideNavBar from "components/pages/SideNavBar";
import Footer from "components/pages/Footer";
import { AngleDown, AngleUp, CloseIcon, MapIcon, MenuIcon } from "components/icons";
import MediaQueryContext from "context/MediaQueryContext";
 

const MobileTitle = ({
    title,
    children
}) => {
    const [visible, setVisible] = React.useState(false);
    return <>
        <Nav.Item icon={<MapIcon />} style={{width: '100%', justifyContent: 'center', position: 'relative'}} onClick={() => setVisible(!visible)}>
            <span style={{marginRight: 4}}>{ title || '' }</span> {visible ? <AngleUp /> : <AngleDown />}
        </Nav.Item>
        { visible && (
            <div style={{position: 'absolute', left: 0, top: '100%', right: 0, padding: 10, backgroundColor: 'white', zIndex: 100}}>
                {children}
            </div>
        )}
    </>
}


const SidebarPage = ({
    navbar,
    floating=true,
    children,
    title,
    simpleTitle,
    breadcrumbData,
    extraHeader,
    sidenavClass = SideNavBar,
    sidenavWidth = 260,
    sidenavCollapsedWidth = 76,
    activeKey,
    rightSide,
    centeredContent,
    hideFooter,
    ...props
}) => {
    const {isMobile} = React.useContext(MediaQueryContext);
    const [expanded, setExpanded] = React.useState(!isMobile);
    const collapsedWidth = isMobile ? 0 : sidenavCollapsedWidth;
    const sidebarWidth = expanded ? sidenavWidth : collapsedWidth;


    const Sidenav = sidenavClass;
    const SidebarComponent = () => (
        <Sidebar 
            width={isMobile ? 0 : sidebarWidth}
            collapsible
        >
            { sidebarWidth > 0 && (
                <Sidenav activeKey={activeKey} expanded={expanded} setExpanded={setExpanded} width={sidebarWidth} />
            )}
            {collapsedWidth === 0 && (
                <IconButton 
                    icon={expanded ? <CloseIcon /> : <MenuIcon />} 
                    appearance={expanded ? 'primary' : 'default'}
                    color={expanded ? 'red' : 'blue'} 
                    style={{
                        position: 'fixed', 
                        top: 10, 
                        left: 10, 
                        zIndex: 10}} 
                    onClick={() => setExpanded(!expanded)} />
            )}

        </Sidebar>
    )
    const contentStyle = {
        maxWidth: isMobile ? '100%' : `calc(100% - ${(centeredContent ? 2 : 1) * sidebarWidth}px`
    }
    return (
        <Container>
            <Header>
                { navbar && (
                    <NavBar floating className={extraHeader ? "navbarWithExtra" : undefined}>
                        { isMobile 
                            ? <MobileTitle title={simpleTitle}> {title} </MobileTitle>
                            : title 
                        }
                        { !breadcrumbData ? <></> : (
                            <Breadcrumb separator='>' className="navbarBreadcrumb" as={Nav.Item}>
                                { breadcrumbData.map(({label, link, active}, i) => (
                                    <Breadcrumb.Item key={i} href={link} active={active}>
                                        {label}
                                    </Breadcrumb.Item>
                                ))}
                            </Breadcrumb>
                        )}
                    </NavBar>
                )}
            </Header>
            <Container>
                {sidenavClass && !rightSide && <SidebarComponent />}
                <Container style={sidenavClass ? contentStyle : undefined}>
                    <Header>
                        {extraHeader}
                    </Header>
                    <Content {...props}>
                        {children}
                    </Content>
                    {!hideFooter && <Footer />}
                </Container>
                {sidenavClass && rightSide && <SidebarComponent />}
            </Container>
        </Container>
    );
};


export default SidebarPage;
