import stopPlaceLabeling from "features/onboardCount/lib/arcGISLabels/stopPlaceLabeling";
import stopPlaceMarkerRenderer from "features/onboardCount/lib/arcGISRenderers/stopPlaceMarkerRenderer";
import { t } from "i18next";

import { arrayStats } from "utils/math";
import { getTraffic } from "../traffic";
import { EOV, Join } from "features/webmap";


const DEFAULT_AREA_SCALE = 10000;


function getStopPlaceFeatures(stopPlaces, countInterval) {
    if (!stopPlaces) return {};

    const stopPlaceValues = [];
    const records = stopPlaces.map(stopPlace => {
        const traffic = getTraffic(stopPlace, countInterval);
        stopPlaceValues.push(traffic.alight.avg, traffic.board.avg); 
        return {
                elementId: stopPlace.id,
                elementType: 'stopPlace',
                geometry: stopPlace.geometry,
                name: stopPlace.name,
                alight: traffic.alight.avg,
                board: traffic.board.avg,
        };
    });

    
    const stats = arrayStats(stopPlaceValues);

    return {
        records,
        stats,
    };
};


function getStopPlaceMarkerJoin({
    records,
    minScale = DEFAULT_AREA_SCALE,
    labelStopPlaces,
}) {
    return Join({
        name: 'stopPlace',
        records,
        index: 6,
        fieldTypes: {
            elementId: 'integer',
            elementType: 'string',
            name: 'string',
            alight: 'integer',
            board: 'integer',
        },
        filedLabels: {
            alight: t('FT/DailyAverageAlightCount'),
            board: t('FT/DailyAverageBoardCount')
        },
        baseField: 'elementId',
        targetField: 'elementId',
        objectIdField: 'id',
        geometryType: 'point',
        getGeometry: record => record.geometry,
        definitionExpression: 'alight > 0 OR board > 0',
        minScale,
        labelingInfo: labelStopPlaces && stopPlaceLabeling(),
        renderer: stopPlaceMarkerRenderer(),
        legendEnabled: false,
    });
};


export function getStopPlaceJoins(stopPlaces, {
    labelStopPlaces
} = {}) {
    const { records } = getStopPlaceFeatures(stopPlaces);

    return [
        getStopPlaceMarkerJoin({ records, labelStopPlaces }),
    ]
};

