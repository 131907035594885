import React from "react";
import { ButtonGroup, ButtonToolbar, SelectPicker, Toggle } from "rsuite";
import { t } from "i18next";

import { ESRIIcon } from "components/icons";
import { SimpleModal } from "components/modals";
import { AuthContext } from "features/auth";

import MapSettings from "./MapSettings";
import WebmapContext from "../context/WebmapContext";
import { LabeledIconButton } from "components/button";
import ViewPreferences from "./ViewPreferences";
import { CrossSectionRoadCountContext } from "features/crossSectionRoadCount";
import { AdminIcon, LegendIcon, SaveIcon, ShowExtentIcon, ViewPreferencesIcon, ZoomInIcon, ZoomOutIcon, ZoomToSelectionIcon } from "./icons";
import PillInput from "components/inputs/PillInput";
import MediaQueryContext from "context/MediaQueryContext";


const MapToolbar = ({
    size = 'sm'
}) => {
    const {user} = React.useContext(AuthContext);
    const crossSectionRoadCount = React.useContext(CrossSectionRoadCountContext);
    const { isMobile } = React.useContext(MediaQueryContext);
    
    const {
        webmap, 
        arcGISMap
    } = React.useContext(WebmapContext);

    const labelToolbarButtons = isMobile ? false : webmap?.userConfig?.labelToolbarButtons;

    const [mapSettingsVisible, setMapSettingsVisible] = React.useState(false);
    const [viewPreferencesVisible, setViewPreferencesVisible] = React.useState(false);
    const [legendVisible, setLegendVisible] = React.useState(arcGISMap?.legend?.visible);

    function toggleLegend() {
        const newValue = !legendVisible;
        setLegendVisible(newValue);
        if (arcGISMap?.legend) {
            arcGISMap.legend.visible = newValue;
        }
    };

    React.useEffect(() => {
        setLegendVisible(arcGISMap?.legend?.visible);
    }, [arcGISMap?.legend?.visible])

    React.useEffect(() => {
        const configData = {};
        (webmap?.configurations || []).forEach( ({id, items}) => {
            configData[id] = items[0]?.id;
        });
        arcGISMap?.setConfigurations(configData);
    }, [webmap, arcGISMap]);

    return <>
        <ButtonToolbar className="mapToolbar">
            { webmap?.configurations.map( ({id, items}) => (
                <SelectPicker
                    key={id}
                    placement="bottomEnd"
                    searchable={false}
                    cleanable={false}
                    value={arcGISMap?.configurations?.[id]}
                    onChange={value => arcGISMap?.setConfigurations({
                        ...arcGISMap?.configurations,
                        [id]: value
                    })}
                    data={ items.map(({id, name}) => ({
                        key: id,
                        label: name,
                        value: id
                    }))} />
            )) }

            <LabeledIconButton
                size={size}
                toggle 
                label={t('Map/Legend')}
                title={t('Map/Legend')}
                showLabel={labelToolbarButtons}
                icon={<LegendIcon />}
                checked={legendVisible}
                onChange={toggleLegend} />


            { crossSectionRoadCount?.mapToolbarButtons?.length && (
                <ButtonGroup size={size}>
                    { crossSectionRoadCount?.mapToolbarButtons?.map((props, i) => (
                        <LabeledIconButton key={i} showLabel={labelToolbarButtons} {...props} />
                    )) }
                </ButtonGroup>
            )}
            

            <ButtonGroup>
                <LabeledIconButton
                    size={size}
                    disabled={!arcGISMap?.canZoomIn()}
                    label={t('Map/ZoomIn')}
                    title={t('Map/ZoomIn')}
                    showLabel={labelToolbarButtons}
                    icon={<ZoomInIcon />}
                    onClick={arcGISMap?.zoomIn} />

                <LabeledIconButton
                    size={size}
                    disabled={!arcGISMap?.canZoomOut()}
                    label={t('Map/ZoomOut')}
                    title={t('Map/ZoomOut')}
                    showLabel={labelToolbarButtons}
                    icon={<ZoomOutIcon />}
                    onClick={arcGISMap?.zoomOut} />

                <LabeledIconButton
                    size={size}
                    label={t('Map/ShowExtent')}
                    title={t('Map/ZoomToExtent')}
                    showLabel={labelToolbarButtons}
                    icon={<ShowExtentIcon />}
                    onClick={arcGISMap?.zoomToExtent} />

                <LabeledIconButton
                    size={size}
                    disabled={ !arcGISMap?.selection?.length }
                    label={t('Map/Selection')}
                    title={t('Map/ZoomToSelection')}
                    showLabel={labelToolbarButtons}
                    icon={<ZoomToSelectionIcon />}
                    onClick={arcGISMap?.zoomToSelection} />
                
            </ButtonGroup>

            <LabeledIconButton
                size={size}
                label={t('Save')}
                title={t('Map/SaveAsImage') + (webmap?.isDemo ? `\n${t('Map/UnavailableInDemo')}` : '')}
                showLabel={labelToolbarButtons}
                icon={<SaveIcon />}
                appearance='default'
                disabled={webmap?.isDemo}
                onClick={arcGISMap?.exportImage} />

            <ButtonGroup>
                <LabeledIconButton
                    size={size}
                    label={t('Map/View')}
                    title={t('Map/ViewPreferences')}
                    showLabel={labelToolbarButtons}
                    icon={<ViewPreferencesIcon />}
                    appearance='default' 
                    onClick={ () => setViewPreferencesVisible(true) }/>

                { !user?.roles?.includes('admin') ? <></> : (
                    <LabeledIconButton
                        size={size}
                        label={t('Map/Administration')}
                        showLabel={labelToolbarButtons}
                        icon={<AdminIcon />}
                        appearance={mapSettingsVisible ? 'primary' : 'default'}
                        onClick={() => setMapSettingsVisible(!mapSettingsVisible)} />
                )}
            </ButtonGroup>
        </ButtonToolbar>
        <SimpleModal title={t('Map/ViewPreferences')} open={viewPreferencesVisible} setOpen={setViewPreferencesVisible}>
            <ViewPreferences />
        </SimpleModal>
        <SimpleModal title={t('Map/Settings')} open={mapSettingsVisible} setOpen={setMapSettingsVisible}>
            <MapSettings />
        </SimpleModal>
        
    </>
};

export default MapToolbar;