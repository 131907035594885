import { compareStrings } from "utils/compare";
import { noop } from "utils/constants";

const Organization = ({
    id, 
    name,
    abbrev
}) => {
    const organization = {
        elementType: 'organization',
        elementId: Number(id),
        id: Number(id),
        name,
        abbrev,
        compare: noop, 
    };

    organization.compare = function (other) {
        return compareOrganizations(organization, other);
    };

    return organization;
};


export function compareOrganizations (a, b) {
    return compareStrings(a?.name, b?.name);
}


export default Organization;
