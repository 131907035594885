import { AvatarCard } from 'components/cards';
import React from 'react'
import TransportLineBadge from '../badges/TransportLineBadge';
import { AreaIcon } from '../icons';
import { t } from 'i18next';

const AreaCard = ({
    size="md",
    name,
    transportLines = [],
}) => {
    return (
        <AvatarCard size={size}
            icon={<AreaIcon />}
            title={<>
                {name}
                <span style={{marginRight: 4}} />
                {transportLines?.sortBy()?.map(transportLine => (
                    <TransportLineBadge 
                        size={size}
                        color={transportLine.color} 
                        name={transportLine.name} 
                        operator={undefined}/>
                ))}
            </>}
            hint={`${t('FT/Area')}: ${name} (${transportLines.map(transportLine => transportLine.name).join(", ")})`}
        />
    )
};

export default AreaCard