import React from 'react'
import { Nav } from 'rsuite'
import { noop } from 'utils/constants'

const PillInput = ({
    size,
    data = [],
    value,
    onChange = noop
}) => {
    return <Nav className={`pillInput${size ? ' ' + size : ''}`} appearance='pills' activeKey={value} onSelect={onChange}>
        { data.map(item => (
            <Nav.Item eventKey={item.value}>
                {item?.label}
            </Nav.Item>
        )) }
    </Nav>
}

export default PillInput