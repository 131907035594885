import 'assets/css/card.css';

import React from 'react'
import { Loader, Panel } from 'rsuite'
import { formatNumber } from 'utils/format';

const StatCard = ({
    icon,
    name,
    color,
    title,
    value,
    unit,
    vertical,
    loading,
    ...props
}) => {
    return (
        <Panel bodyFill bordered {...props}>
            <div className={`statCard${vertical ? ' vertical' : ''}`} title={title}>
                <div className="name" style={color ? { color: color?.hex, backgroundColor: color?.setAlpha(0.1)?.hex } : undefined}>
                    <div>
                        {icon}
                    </div>
                    <div>
                        {name}
                    </div>
                </div>
                <div className="content" >
                    <div className="value">
                        {loading ? <Loader /> : (isNaN(value) || value === undefined ? '-' : formatNumber(value))}
                    </div>
                    <div className="unit">
                        {unit}
                    </div>
                </div>
            </div>
        </Panel>
    )
}

export default StatCard