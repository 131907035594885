import 'assets/css/card.css';

import React from "react";
import { Panel } from "rsuite";

import CardHeader from './CardHeader';
import { t } from 'i18next';


const AvatarCard = ({
    title,
    subtitle,
    hint,
    size="md",
    inline,
    bordered,
    filled,
    height,
    imageURL,
    usePlaceholder,
    maintenance,
    icon,
    actions,
    children,
    onClick,
    ...props
}) => {
    const headerProps = {
        title,
        subtitle,
        imageURL,
        usePlaceholder,
        icon,
        actions,
        size,
        disabled: maintenance
    };

    if (maintenance) {
        headerProps.imageURL = "/img/Maintenance.png";
        headerProps.hint = t('Map/UnderMaintenanceDescription');
        headerProps.subtitle = t('Map/UnderMaintenance');
    }

    return (
        <Panel 
            className={`card avatarCard ${size}${inline ? ' inline' : ''} ${props?.className || ''}`}
            bordered={bordered}
            shaded={bordered} 
            bodyFill={ !children }
            header={<CardHeader {...headerProps} />}
            disabled={maintenance}
            style={{
                height, 
                ...(filled ? {backgroundColor: 'white'} : {}),
            }}
            title={hint}
        >
            {children}
            {onClick && !maintenance ? <div className="clickable" onClick={onClick} /> : <></>}
        </Panel>
    );
};


export default AvatarCard;
