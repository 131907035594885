import 'assets/css/form.css';

import { Form, SelectPicker } from "rsuite";
import { t } from "i18next";

import { FormGroup } from 'components/forms';


const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 20, 50];


const PageSizeForm = ({
    size,
    pageSize = 10,
    setPageSize = () => {},
    options = DEFAULT_PAGE_SIZE_OPTIONS,
}) => {
    return (
        <Form layout="inline">
            <FormGroup
                name="pageSize"
                label={t('PageSize')}
                accepter={SelectPicker}
                size={size}
                searchable={false}
                cleanable={false}
                data={options.map(option => ({label: `${option}`, value: option}))}
                value={pageSize}
                onChange={setPageSize} 
            />
        </Form>
    );
};


export default PageSizeForm;
