import 'assets/css/carousel.css'

import React from 'react'
import { Carousel, Panel } from 'rsuite'
import CarouselPage from './CarouselPage'
import { DeleteIcon, EditIcon, PlusIcon } from 'components/icons';
import { t } from 'i18next';
import { noop } from 'utils/constants';
import ConfirmDeleteModal from 'components/modals/ConfirmDeleteModal';
import ActionButton from 'components/cards/ActionButton';
import ActionToolbar from 'components/cards/ActionToolbar';

const CarouselPanel = React.forwardRef(({
    welcome,

    itemLabel,
    getItems = noop,
    deleteItem,

    modalClass,
    defaultModalValues,

    actions = [],
    
    getTitle = item => item?.title || item?.name,
    getSubtitle = item => item?.subtitle,
    getCoverURL = item => item?.coverUrl,
    getActions = noop,
    onItemClick = noop,
    endpoint,
    
    height,
    interval=8,
    ...props
}, ref) => {

    const [items, setItems] = React.useState([]);

    const [selectedItem, setSelectedItem] = React.useState();
    const [modalVisible, setModalVisible] = React.useState();
    const [deleteModalVisible, setDeleteModalVisible] = React.useState();

    const Modal = modalClass;

    function refresh() {
        return getItems().then(setItems)
    }
    
    const DeleteModal = ({
        item,
        ...props
    }) => (
        <ConfirmDeleteModal
            onDelete={() => deleteItem(item).then(refresh)}
            itemLabel={itemLabel}
            {...props} />  
    )
    
    const panelActions = []
    if (modalClass) {
        panelActions.push({
            onClick: () => {
                setSelectedItem(defaultModalValues);
                setModalVisible(true);
            },
            icon: <PlusIcon />,
            appearance: "primary" ,
            title: t('CreateOption')
        })
    }

    function getItemActions (item) {
        const itemActions = [];

        if (item?.id && modalClass) {
            itemActions.push({
                onClick: () => {
                    setSelectedItem(item);
                    setModalVisible(true);
                },
                icon: <EditIcon />,
                appearance: "primary" ,
                color: "yellow",
                title: t('Edit')
            })
        }
        if (item?.id && deleteItem) {
            itemActions.push({
                onClick: () => {
                    setSelectedItem(item);
                    setDeleteModalVisible(true);
                },
                icon: <DeleteIcon />,
                appearance: "primary" ,
                color: "red",
                title: t('Delete')
            })
        }
        return itemActions
    }   

    const welcomeItem = {
        title: t('Home/Welcome'),
        subtitle: <img src='/logo_white.png' height={60} />,
    }

    React.useImperativeHandle(ref, () => ({
        refresh: refresh
    }));

    React.useEffect(() => {
        refresh()
    }, [getItems])


    return <>
        <Panel className={`carouselPanel${items?.length < 1 ? ' empty' : ''}`} shaded>
            <ActionToolbar>
                { [...panelActions, ...actions]?.map(action => <ActionButton {...action} />)}
            </ActionToolbar>
            <Carousel autoplay autoplayInterval={interval*1000} style={{height}}>
                {[...(welcome ? [welcomeItem] : []), ...items].map((item, i) =>
                    <div key={i}>
                        <CarouselPage
                            backgroundUrl={getCoverURL(item)}
                            title={getTitle(item)} 
                            subtitle={getSubtitle(item)}
                            actions={[...(getItemActions(item) || []), ...(getActions(item) || [])]}
                            linkUrl={item?.id ? `/${endpoint}/${item?.id}` : undefined}
                            onClick={() => onItemClick(item)}
                        />
                    </div>
                )}

            </Carousel>
        </Panel>
        { modalClass && <Modal open={modalVisible} setOpen={setModalVisible} item={selectedItem} onClose={refresh}/>}
        { <DeleteModal open={deleteModalVisible} setOpen={setDeleteModalVisible} item={selectedItem} onClose={refresh}/>}
        
    </>
});

export default CarouselPanel