import { noop } from "utils/constants";

const DAY_TYPE_ORDER = [
    1, //	mindennap
    2, //	munkanap
    9, //	hétfői munkanap
    20, //	hétfő-csütörtök munkanap
    10, //	kedd-péntek munkanap
    21, //	pénteki munkanap
    7, //	iskolaszüneti nap
    22, //	hétfő-csütörtök iskolaszüneti nap
    23, //	pénteki iskolaszüneti nap
    14, //	hétköznapi vágányzár
    15, //	hétvégi vágányzár
    5, //	szabadnap
    11, //	szabad- és munkaszüneti nap
    6, //	munkaszüneti nap
    19, //	ünnepnap
    8, //	Régiségvásár
    16, //	szenteste napja
    17, //	szilveszter napja
];


const DayType = ({
    id, 
    name,
}) => {
    const dayType = {
        elementType: 'dayType',
        elementId: Number(id),
        id: Number(id),
        name,
        priority: 0,
        compare: noop, 
    };

    dayType.priority = DAY_TYPE_ORDER.indexOf(dayType.id);
    dayType.compare = function (other) {
        return compareDayTypes(dayType, other);
    };

    return dayType;
};


export function compareDayTypes (a, b) {
    return a?.priority - b?.priority;
}


export default DayType;
