import React from "react";

import { Col, Grid, Row } from "rsuite";

import { CollectionPanel } from "features/collection";
import SimplePage from "layouts/SimplePage";
import { BlogEntryListPanel } from 'features/blog';
import { AuthContext } from 'features/auth';
import AnnouncementCarousel from "features/blog/components/AnnouncementCarousel";
import WebMapPanel from "features/webmap/components/WebMapPanel";
import BlogEntryPanel from "features/blog/components/BlogEntryPanel";
import SidebarPage from "layouts/SidebarPage";
import SideNavBar from "components/pages/SideNavBar";
import PillInput from "components/inputs/PillInput";
import { t } from "i18next";

const HomePage = () => {

    
    const { user } = React.useContext(AuthContext);
    const [ collectionSort, setCollectionSort ] = React.useState('lastViewed');
    const [ webmapSort, setWebmapSort ] = React.useState('lastViewed');

    const sortData = [{
        label: t('LastViewed'),
        value: 'lastViewed'
    }, {
        label: t('LastCreated'),
        value: 'lastCreated'
    }, {
        label: t('MostViewed'),
        value: 'mostViewed'
    }]

    return (
        <SidebarPage activeKey="home">
            <Grid className="mainGrid">
                <Row>
                    <Col xs={24}>
                        <AnnouncementCarousel height={300}/>
                    </Col>
                    { user?.blogActive && (  
                        <Col xs={24}>        
                            <BlogEntryPanel 
                                showAll
                                alertAction={undefined}
                                getActions={undefined}
                                hidePlaceholder 
                                hidePagination 
                                unread 
                                featured={false} 
                                defaultPageSize={3} />
                        </Col>
                    )}
                    <Col xs={24}>         
                        <CollectionPanel 
                            sort={collectionSort}
                            showAll
                            hidePlaceholder 
                            hidePagination
                            defaultPageSize={4}
                            defaultView="grid"
                            maxColumns={4}
                        >
                            <PillInput 
                                size="sm"
                                data={sortData}
                                value={collectionSort}
                                onChange={setCollectionSort}
                            />
                        </CollectionPanel>                    
                    </Col>
                    <Col xs={24} >
                        <WebMapPanel 
                            sort={webmapSort}
                            showAll
                            hidePlaceholder
                            hidePagination
                            defaultPageSize={4}
                            defaultView="grid"
                            maxColumns={4}
                        >
                            <PillInput 
                                size="sm"
                                data={sortData}
                                value={webmapSort}
                                onChange={setWebmapSort}
                            />
                        </WebMapPanel>
                    </Col>
                </Row>
            </Grid>
        </SidebarPage>
    );
};


export default HomePage;
