import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext'
import { ModuleSidebar } from 'features/webmapModule';
import React from 'react'
import SelectionTable from '../tables/SelectionTable';
import { Button, Col, Message, Row } from 'rsuite';
import StatCard from 'components/cards/StatCard';
import { t } from 'i18next';
import StationForm from '../forms/StationForm';
import { VEHICLE_CLASS_CONVERSIONS } from 'features/crossSectionRoadCount/utils/constants';
import { asRoman, sum } from 'utils/math';
import { formatString } from 'utils/format';

const CrossSectionRoadCountSidebar = ({
    xs = 12,
    sm = 6,
    lg = 4,
}) => {
    const module = React.useContext(CrossSectionRoadCountContext);
    if (!module?.countId) return null;
    
    const {
        station,
    } = module?.selectedElements || {};

    const {
        stationCode,
        stationYear,
        stationMonth,
        stationDayTypeId,
    } = module?.filters;

    function getVehicleClassTraffic(code) {
        const codes = VEHICLE_CLASS_CONVERSIONS?.[code] || [code];
        return sum(codes.map(code => Math.round(station?.vehicleClasses?.[code]?.amount || 0)))
    }
    
    if (!stationCode) return null;

    const traffic = stationMonth && stationDayTypeId
        ? station?.monthlyTrafficByYear?.[`${stationYear}`]
        : station?.yearlyTrafficByYear?.[`${stationYear}`]

    return (
        <ModuleSidebar module={module}>

            <SelectionTable />

            <div style={{marginBottom: 20}} />

            <StationForm />
            
            { station?.id && !station?.startSection ? (
                <Message type="error">
                    { station?.validStation ? <>
                        {formatString(t('OKKF/InvalidStationWithCode'), {stationCode: station.validStation})}
                        <div style={{paddingTop: 10, textAlign: "right"}}>
                            <Button appearance='primary' color='red' onClick={() => module?.applyFilters({stationCode: station.validStation})}>
                                { formatString(t('OKKF/ChangeStationTo'), {stationCode: station.validStation})}
                            </Button>
                        </div>
                    </> : t('OKKF/InvalidStation')}
                </Message>
            ) : <></>}
            
            <div style={{marginBottom: 20}} />

            <Row className='statCardsRow'>
                
                <Col xs={xs} sm={sm} lg={lg} >
                    <StatCard 
                        vertical
                        key='dut'
                        name={stationMonth ? 
                            t('OKKF/MADT') : 
                            t('OKKF/AADT')
                        }
                        title={stationMonth ? 
                            t('OKKF/MonthlyAverageDailyTraffic') : 
                            t('OKKF/AnnualAverageDailyTraffic')
                        }
                        unit={t('OKKF/UnitVehiclePerDay')}
                        loading={!station}
                        value={Math.round(station?.averageUnits)}/>

                </Col>
                <Col xs={xs} sm={sm} lg={lg} >
                    <StatCard 
                        vertical
                        key='dt'
                        name={stationMonth ? 
                            t('OKKF/MADT') : 
                            t('OKKF/AADT')
                        }
                        title={stationMonth ? 
                            t('OKKF/MonthlyAverageDailyTraffic') : 
                            t('OKKF/AnnualAverageDailyTraffic')
                        }
                        unit={t('OKKF/VehiclePerDay')}
                        loading={!station}
                        value={Math.round(station?.amount)}/>

                </Col>
                
                <Col xs={xs} sm={sm} lg={lg} >
                    <StatCard 
                        vertical
                        key='hht'
                        name={t('OKKF/HHT')}
                        title={t('OKKF/HighestHourlyTraffic')}
                        unit={t('OKKF/UnitVehiclePerHour')}
                        loading={!station}
                        value={stationMonth ? undefined : Math.round(station?.peakHourUnits)}/>
                </Col>
                <Col xs={xs} sm={sm} lg={lg} >
                    <StatCard 
                        vertical
                        key='adua'
                        name={t('OKKF/ADUA')}
                        title={t('OKKF/AverageDailyUnitAxes')}
                        unit={t('OKKF/UnitAxisPerDay')}
                        loading={!station}
                        value={stationMonth ? undefined : Math.round(station?.axisUnits)}/>
                </Col>

                { Object.values(traffic?.vehicleClasses || {}).map(vehicleClass => (
                    <Col xs={xs} sm={sm} lg={lg} key={vehicleClass?.code}>
                        <StatCard 
                            vertical
                            key={vehicleClass?.code}
                            name={vehicleClass?.code}
                            title={vehicleClass?.name}
                            unit={t('OKKF/VehiclePerDay')}
                            loading={!station}
                            value={Math.round(vehicleClass?.amount)}/>
                    </Col>
                ))}
                

            </Row>
            
            <div style={{marginBottom: 20}} />
            
            
        </ModuleSidebar>
    )
}

export default CrossSectionRoadCountSidebar