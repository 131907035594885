import React from 'react'
import { Nav } from 'rsuite'

import "assets/css/layout.css"
import { noop } from 'utils/constants';
import { WebmapContext } from 'features/webmap';


const SideNavMenuItem = ({
    eventKey,
    parentKeys = [],
    children = [],
    module,
    view,
    setValue = noop,
    ...props
}) => {

    const header = !parentKeys?.length;

    const data = {...props}
    Object.entries(data).forEach(([name, value]) => {
        if (typeof value === 'function') {
            data[name] = value(module);
        }
    }) 

    const {
        label,
        abbrev,
        onDisabledClick
    } = data || {};
    
    delete data?.label;
    delete data?.abbrev;


    if (children?.length && parentKeys.length > 1) {
        return (
            <Nav.Menu title={label} {...data} icon={<span className="icon">{data?.icon}</span>} {...(data?.disabled ? {disabled: false, onClick: onDisabledClick} : {})} >
                { children.map(item => (
                    <SideNavMenuItem 
                        module={module} 
                        view={view}
                        eventKey={item?.key} 
                        {...item} 
                    />
                ))}
            </Nav.Menu>
        )
    } else {
        return <>
            <div style={{marginTop: 4}}></div>
            <Nav.Item className={header ? "header" : undefined} {...data} icon={<span className="icon">{data?.icon}</span>} onClick={() => {
                setValue(eventKey);
                if (view) {
                    module.setView(view);
                }
            }}>
                { abbrev || label }
            </Nav.Item>
            { children.map(item => (
                <SideNavMenuItem 
                    module={module} 
                    view={view} 
                    eventKey={item?.key} 
                    {...item} 
                />
            ))}
        </>
    }
}


const SideNavMenu = ({
    title,
    icon,
    view,
    navs = {},
    module,
    ...props
}) => {

    const currentView = React.useContext(WebmapContext)?.view;
    
    return (
        <Nav className={`sideNavMenu ${currentView === view ? 'active' : ''} ${props?.className || ''}`}>
            <Nav.Item icon={<span className="icon">{icon}</span>} className="title" onClick={() => module.setView(view)}>
                {title}
            </Nav.Item>
            { navs?.children?.map(item => (
                <SideNavMenuItem 
                    module={module} 
                    view={view}
                    eventKey={item.key} 
                    {...item} 
                />
            ))}
        </Nav>
    )
}

export default SideNavMenu