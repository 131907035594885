import 'assets/css/onboardCount.css';

import React from 'react'


const TransportLineBadge = ({
    size="md",
    color,
    name,
    operator,
}) => {
    return <>
        {operator?.id && (
            <div className="operatorIcon">
                <img
                    src={`/img/ft/organizations/${operator?.id}.png`}
                    alt={operator?.name}
                    title={operator?.name}    
                />
            </div>
        )}
        <div className={`transportLineBadge ${size}`} style={{backgroundColor: color?.hex, color: 'white'}}>
            {name}
        </div>
    </>
}

export default TransportLineBadge