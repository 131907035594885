import React from 'react';
import Chart from 'chart.js/auto';
import { Bar, Pie } from 'react-chartjs-2';


Chart.defaults.font.family = "'Avenir Next LT Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";


export const Dataset = ({
    name,
    type,
    getValue,
    color,
    getColor,
    opacity = 0.5,
    disableLegend = false,
    ...props
}) => {
    return {
        name,
        type,
        label: props?.label,
        disableLegend,
        getValue: getValue || (item => item?.[name]),
        color,
        getColor,
        opacity,
        props,
    }
}


const DataChart = React.forwardRef(({
    chartId = 'dataChart',
    type = 'bar',
    data = [],
    sets = [],
    title,
    getLabel = item => item?.label || item?.name || item,
    legendPlaceholder = 0,
    padding = {},
    fullWidth=false,
    size = {},
    ...props
}, ref) => {
    const style = {...size}

    let ChartType;
    switch (type) {
        case 'pie':
            ChartType = Pie;
        default:
            ChartType = Bar
            if (!fullWidth) {
                style.maxWidth = 60 * data.length + legendPlaceholder;
                style.minWidth = 360 + legendPlaceholder
                style.marginLeft = 'auto';
                style.marginRight = 'auto';
            }
    }

    const plugins = [{
        id: 'backgroundColor',
        beforeDraw: (chart, args, options) => {
          const {ctx} = chart;
          ctx.save();
          ctx.globalCompositeOperation = 'destination-over';
          ctx.fillStyle = options.color || '#ffffff';
          ctx.fillRect(0, 0, chart.width, chart.height);
          ctx.restore();
        }
      }];

    const options = {
        maintainAspectRatio: false,
        maxBarThickness: 50,
        responsive: true,
        ...props,
        layout: { padding: {
            top: 10,
            right: 10,
            bottom: 10,
            left: 10,
            ...padding,
            ...(legendPlaceholder ? {
                [props?.plugins?.legend?.position || 'right']: legendPlaceholder 
            } : {}),
        }},
        plugins: {
            ...(props?.plugins || {}),
            title: {
                display: true,
                text: title.split('\n'),
                font: {
                    size: 14,

                },
                ...(props?.plugins?.title || {}),
            },
            legend: {
                ...(props?.plugins?.legend || {}),
                labels: {
                    filter: (item) => datasets.filter(ds => !ds.disableLegend && item.text.includes(ds.label)).length,
                    ...(props?.plugins?.legend?.labels || {}),
                }
            },
            backgroundColor: {
                color: 'white',
            }
        }
    }

    const labels = data.map(getLabel)

    const datasets = sets.map(dataset => ({
        type: dataset.type || type,
        data: data.map(dataset.getValue),
        backgroundColor: dataset?.getColor 
            ? data.map(d => dataset.getColor(d)?.setAlpha(dataset.opacity || 0)?.hex)
            : dataset.color.setAlpha(dataset.opacity || 0)?.hex,
        borderColor: dataset?.getColor 
            ? data.map(d => dataset.getColor(d)?.hex)
            : dataset.color?.hex,
        labels,
        borderWidth: 1,
        ...dataset.props,
    }))

    React.useImperativeHandle(ref, () => ({
        saveAsImage( filename ) {
            var a = document.createElement("a"); //Create <a>
            const chartCanvas = document.getElementById(chartId);
            a.href = chartCanvas.toDataURL(); //Image Base64 Goes here
            a.download = filename + ".png"; //File name Here
            a.click(); //Downloaded file
        }
    }))

    return <div style={style}>
        <ChartType 
            id={chartId} 
            data={{datasets, labels}} 
            options={options} 
            plugins={plugins} />
    </div>
});

export default DataChart