import React from 'react';
import { AvatarCard } from 'components/cards';
import TransportLineBadge from '../badges/TransportLineBadge';
import { t } from 'i18next';


const TransportLineCard = ({
    size = 'md',
    name,
    color,
    inline,
    operator,
}) => {
    return (
        <AvatarCard
            size={size}
            icon={<>
                <TransportLineBadge size={size} name={name} color={color} operator={operator}/>
            </>}
            title={t('FT/AllServicePatterns')}
            hint={`${t('FT/TransportLine')}: ${name}`}
            inline={inline}
        />
    );
};


export default TransportLineCard;
