import React from 'react'
import CountIntervalCellContent, { countIntervalCellCSV } from './cells/CountIntervalCellContent'
import { t } from 'i18next'
import TransportLineCard from '../cards/TransportLineCard'
import ServicePatternCard from '../cards/ServicePatternCard'
import DataTable from 'features/dataTable/components/DataTable'
import TransportLine from 'features/onboardCount/elements/TransportLine'
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import { getSelectedRows, getTransportLineFilters } from 'features/onboardCount/utils/filter'
import CountInterval, { getAvailableCountIntervalIds, getMeasuredCountIntervalIds } from 'features/onboardCount/elements/CountInterval'


function getColumnTypes (countIntervals = [], dayTypes = {}) {
    return [{
        name: 'countInterval',
        label: t('FT/CountInterval'),
        selectable: true,
        values: countIntervals.map(key => ({
            key,
            label: <CountIntervalCellContent value={key} dayTypes={dayTypes}/>,
            csvLabel: countIntervalCellCSV({value: key,  dayTypes}),
        }))
    }, {
        name: 'traffic',
        label: t('FT/TrafficData'),
        values: [{
            key: 'alight',
            label: 'Lelszállók száma',
            abbrev: 'Le'
        }, {
            key: 'board',
            label: 'Felszállók száma',
            abbrev: 'Fel'
        }, {
            key: 'onward',
            label: 'Keresztmetszeti forgalom',
            abbrev: 'Forgalom'
        }]
    }, {
        name: 'stat',
        label: t('FT/StatType'),
        values: [{
            key: 'avg',
            label: 'Átlag',
            abbrev: 'Átl.',
            sortable: true,
        }]
    }]
}


const TransportLineTrafficTable = React.forwardRef(({
    servicePatternsOnly,
    selectedIntervalOnly,
    ...props
}, ref) => {

    const count = React.useContext(OnboardCountContext);
    
    const {
        countIntervals = {},
        transportLines = {},
        servicePatterns = {},
        dayTypes = {},
    } = count?.elements || {};

    const {
        areaId,
        stopPlaceId,
        sectionId,
        transportLineIds = [],
        servicePatternIds = [],
        countInterval,
        countDate,
    } = count?.filters || {};

    const selectedRows = getSelectedRows(transportLineIds, servicePatternIds, servicePatterns);

    const availableIntervals = countDate
        ? getMeasuredCountIntervalIds(transportLines, true)
        : getAvailableCountIntervalIds(countIntervals, count?.filters);
        

    const selectedColumns = (selectedIntervalOnly || availableIntervals.length === 1 )? [] : [countInterval];

    const visibleColumns = {
        traffic: sectionId ? ['onward'] : areaId || stopPlaceId ? ['alight', 'board'] : ['board'],
    };

    let data;
    if (servicePatternsOnly) {

        data = servicePatternIds.length
            ? Object.values(servicePatterns).filter(servicePattern => servicePatternIds.includes(servicePattern.id))
            : transportLineIds.length 
                ? Object.values(servicePatterns).filter(servicePattern => transportLineIds.includes(servicePattern.transportLine.id))
                : Object.values(transportLines).map(transportLine => TransportLine({...transportLine, servicePatterns}))
    } else {
        data = Object.values(transportLines).map(transportLine => TransportLine({...transportLine, servicePatterns}))
    }
    
    const visibleCountIntervals = selectedIntervalOnly 
        ? (countDate 
            ? [CountInterval(countDate).str]
            : [countInterval] )
        : (countDate 
            ? getMeasuredCountIntervalIds(data, true)
            : getAvailableCountIntervalIds(countIntervals, count?.filters));

    const columnTypes = getColumnTypes(visibleCountIntervals, dayTypes);

    function handleSelectRow( values ) {
        console.log(values)
        count?.applyFilters(getTransportLineFilters(values, servicePatterns));
    }

    function handleSelectColumn( values ) {
        count?.applyFilters({countInterval: values[0]});
    }

    console.log({data})

    return (
        <DataTable
            ref={ref}
            title={t('FT/TransportLineTraffic')}

            hover
            bordered
            cellBordered
            columnWidth={120}

            data={data}
            columnTypes={columnTypes}
            dataAttribute='traffic'
            visibleColumns={visibleColumns}

            isTree
            getChildren={row => row?.servicePatterns}
            getRowKey={row => `${row?.elementType}-${row?.elementId}`}

            multiselect
            onSelectRow={handleSelectRow}
            selectedRows={selectedRows}
            rowHeight={46}

            headerHeight={availableIntervals.length > 1 && !selectedIntervalOnly ?  80 : 40}

            onSelectColumn={handleSelectColumn}
            selectedColumns={selectedColumns}

            renderRowHeader={(rowData, csv) => {
                if (csv) {
                    return ''
                }
                switch (rowData?.elementType) {
                    case 'transportLine':
                        return <TransportLineCard size='xs' className={'inline'} {...rowData} />
                    case 'servicePattern' :
                        return <ServicePatternCard size='xs' className={'inline'} {...rowData} />
                    }
                }
            }
            csvExtraColumns={[{
                label: t('FT/TransportLine'),
                getValue: row => row?.transportLine?.name || row?.name || t('FT/AllTransportLines')
            }, {
                label: t('FT/Route'),
                getValue: row => row?.routeName || t('FT/AllRoutes'),
            }, {
                label: t('FT/ServicePattern'),
                getValue: row => row?.elementType === 'servicePattern' ? row?.name : t('FT/AllServicePatterns'),
            }, {
                label: t('Type'),
                getValue: row => row?.transportLine?.typeName || row?.typeName,
            }]}

            {...props}
        />
    )
})

export default TransportLineTrafficTable