import 'assets/css/layover.css';

import React from "react";


const Layover = ({
    className,
    dark, 
    large,
    position,
    children,
    ...props
}) => {
    const positionClass = position ? ' ' + position.replace('-', ' ') : '';
    return (
        <div className={`layover${ dark ? ' dark' : '' }${ large ? ' large' : '' }${ positionClass } ${className || ''}`} {...props}>
            {children}
        </div>
    );
};
    

export default Layover;
