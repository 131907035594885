import React from 'react'
import { t } from 'i18next'
import DataTable from 'features/dataTable/components/DataTable'
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import { getStopPlaceFilters } from 'features/onboardCount/utils/filter'
import { secondsToString } from 'features/onboardCount/utils/time'
import { getInvalidTraffic } from 'features/onboardCount/utils/traffic'
import { sum } from 'utils/math'


function getColumnTypes (journeys = []) {
    return [{
        name: 'journey',
        label: t('FT/Journey'),
        selectable: true,
        total: 'left',
        values: journeys.map(journey => ({
            key: `journey-${journey?.id}`,
            label: secondsToString(journey?.startTime),
            abbrev: secondsToString(journey?.startTime),
        }))
    }, {
        name: 'traffic',
        label: t('FT/TrafficData'),
        values: [{
            key: 'alight',
            label: 'Lelszállók száma',
            abbrev: 'Le'
        }, {
            key: 'board',
            label: 'Felszállók száma',
            abbrev: 'Fel',
            cellProps: {style: {backgroundColor: '#f8f8f8'}}
        }, {
            key: 'onward',
            label: 'Továbbutazók száma',
            abbrev: 'Tov.'
        }]
    }, {
        name: 'stat',
        label: t('FT/StatType'),
        hideable: true,
        values: [{
            key: 'avg',
            label: 'Átlag',
            abbrev: 'Átl.',
            sortable: true,
        }, {
            key: 'min',
            label: 'Minimum',
            abbrev: 'Min.',
            sortable: true,
            noTotals: true,
        }, {
            key: 'max',
            label: 'Maximum',
            abbrev: 'Max.',
            sortable: true,
            noTotals: true,
        }]
    }]
}


const JourneyStopTrafficTable = React.forwardRef(({
    renderBeginningCount,
    renderEndingCount,
    ...props
}, ref) => {

    const count = React.useContext(OnboardCountContext);
    
    const {
        journeys = {},
        journeyStops = {},
    } = count?.elements || {};

    const {
        journeyId,
        areaId,
        stopPlaceId
    } = count?.filters || {};

    const stops = {};

    function getRowKey (row) {
        return row?.orderNum
    }

    Object.values(journeyStops).map(journeyStop => {
        const orderNum = journeyStop.orderNum;

        if (!stops?.[orderNum]) {
            stops[orderNum] = { 
                orderNum, 
                ...journeyStop.stopPlace,
                traffic: {},
                selectable: true,
            };
        }

        stops[orderNum].traffic[`journey-${journeyStop.journey.id}`] = Object.values(journeyStop.traffic)[0];
    })

    if (renderBeginningCount) {
        const beginningCountOrderNum = 0;
        stops[beginningCountOrderNum] = {
            orderNum: beginningCountOrderNum,
            name: t('FT/BeginningCount'),
            traffic: {},
            disabled: true,
        };

        Object.values(journeys).map(journey => {
            stops[beginningCountOrderNum].traffic[`journey-${journey.id}`] = {
                ...getInvalidTraffic(),
                board: Object.values(journey.traffic)[0]?.beginningCount,
            };
        })

    }

    if (renderEndingCount) {
        const endingCountOrderNum = Object.keys(stops).length + 1;
    
        stops[endingCountOrderNum] = {
            orderNum: endingCountOrderNum,
            name: t('FT/EndingCount'),
            traffic: {},
            disabled: true,
        }
    
        Object.values(journeys).map(journey => {
            stops[endingCountOrderNum].traffic[`journey-${journey.id}`] = {
                ...getInvalidTraffic(),
                alight: Object.values(journey.traffic)[0]?.endingCount,
            };
        })
    }

    const selectedRows = Object.values(stops).filter(stop => !stop?.disabled && ((areaId === stop?.areaId) || (stopPlaceId === stop?.id))).map(getRowKey);
    const selectedColumns = journeyId ? [`journey-${journeyId}`] : [];

    const data = Object.values(stops);
    const columnTypes = getColumnTypes(Object.values(journeys).sortBy());

    function handleSelectRow( values ) {
        count.applyFilters(getStopPlaceFilters(values.map(key => `stopPlace-${stops[key].id}`)));
    }

    function handleSelectColumn( values ) {
        count.applyFilters({
            journeyId: values[0] ? Number(values[0].split('-')[1]) : undefined
        })
    }

    return (
        <DataTable
            ref={ref}
            title={t('FT/TransportLineTraffic')}

            hover
            bordered
            cellBordered
            columnWidth={80}
            minColumnWidth={48}
            rowHeight={24}
            headerHeight={24}

            data={data}
            columnTypes={columnTypes}
            dataAttribute='traffic'

            getRowKey={getRowKey}
            rowHeaderKey={'orderNum'}

            onSelectRow={handleSelectRow}
            selectedRows={selectedRows}

            onSelectColumn={handleSelectColumn}
            selectedColumns={selectedColumns}
            renderRowHeader={rowData => rowData?.name}

            {...props}
        />
    )
});

export default JourneyStopTrafficTable