import { Icon } from '@rsuite/icons';
import { faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faArrowDown, faArrowLeft, faArrowRight, faArrowUp, faAt, faBars, faBell, faBezierCurve, faBullhorn, faBus, faCalendar, faCalendarAlt, faCaretLeft, faCaretRight, faChartBar, faChartPie, faCheckCircle, faCircle, faClock, faClose, faCog, faCogs, faEdit, faEnvelope, faEnvelopeOpen, faExclamationCircle, faExternalLinkAlt, faFileAlt, faFilter, faFolder, faGrip, faHome, faInfoCircle, faKey, faLayerGroup, faLevelDownAlt, faLevelUpAlt, faLink, faList, faMap, faMapMarker, faMapMarkerAlt, faMinusCircle, faPause, faPhone, faPlus, faProjectDiagram, faQuestionCircle, faRefresh, faRoad, faSave, faSignOut, faSplotch, faSuitcase, faTable, faTachometerAltFast, faTimesCircle, faTools, faTrash, faTrashAlt, faUndo, faUnlockAlt, faUser, faUserCircle, faUserCog, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FaIcon = ({ icon,  ...props }) => {
    return (
        <Icon as={FontAwesomeIcon} icon={icon} {...props} />
    );
};

export const ESRIIcon = ({name, withLabel, ...props}) => <i 
    {...(withLabel ? {style: {marginRight: 4}} : {})} 
    {...props} 
    className={`esri-icon-${name}`} />

export default FaIcon;

export const UserIcon = ({ ...props }) => <FaIcon icon={faUser} {...props} />

export const ProfileIcon = ({ ...props }) => <FaIcon icon={faUserCircle} {...props} />

export const RegisterUserIcon = ({ ...props }) => <FaIcon icon={faUserPlus} {...props} />

export const CloseIcon = ({ ...props }) => <FaIcon icon={faClose} {...props} />

export const CaretLeft = ({ ...props }) => <FaIcon icon={faCaretLeft} {...props} />

export const CaretRight = ({ ...props }) => <FaIcon icon={faCaretRight} {...props} />

export const AngleLeft = ({ ...props }) => <FaIcon icon={faAngleLeft} {...props} />

export const AngleRight = ({ ...props }) => <FaIcon icon={faAngleRight} {...props} />

export const AngleUp = ({ ...props }) => <FaIcon icon={faAngleUp} {...props} />

export const AngleDown = ({ ...props }) => <FaIcon icon={faAngleDown} {...props} />

export const ArrowDownIcon = ({ ...props }) => <FaIcon icon={faArrowDown} {...props} />

export const ArrowUpIcon = ({ ...props }) => <FaIcon icon={faArrowUp} {...props} />

export const ArrowRightIcon = ({ ...props }) => <FaIcon icon={faArrowRight} {...props} />

export const ArrowLeftIcon = ({ ...props }) => <FaIcon icon={faArrowLeft} {...props} />

export const ResetIcon =({ ...props }) =><FaIcon icon={faUndo} {...props} />

export const InfoIcon = ({ ...props }) => <FaIcon icon={faInfoCircle} {...props} />

export const StartTimeIcon = ({ ...props }) => <FaIcon icon={faClock} {...props} />

export const StopIcon = ({ ...props }) => <FaIcon icon={faPause} {...props} />

export const StopPlaceIcon = ({ ...props }) => <FaIcon icon={faMapMarkerAlt} {...props} />

export const AreaIcon = ({ ...props }) => <FaIcon icon={faSplotch} {...props} />

export const ServicePatternIcon = ({ ...props }) => <FaIcon icon={faProjectDiagram} {...props} />

export const ServicePatternGroupIcon = ({ ...props }) => <FaIcon icon={faBezierCurve} {...props} />

export const NetworkPerformanceIcon = ({ ...props }) => <FaIcon icon={faTachometerAltFast} {...props} />

export const ArrivalIcon = ({ ...props }) => <FaIcon icon={faLevelDownAlt} {...props} />

export const DepartureIcon = ({ ...props }) => <FaIcon icon={faLevelUpAlt} {...props} />

export const AlightIcon = ({ ...props }) => <ArrowDownIcon {...props} />

export const BoardIcon = ({ ...props }) => <ArrowUpIcon {...props} />

export const OnwardIcon = ({ ...props }) => <ArrowRightIcon {...props} />

export const FilterIcon = ({ ...props }) => <FaIcon icon={faFilter} {...props} />

export const MapIcon = ({ ...props }) => <FaIcon icon={faMap} {...props} />

export const TableIcon =({ ...props }) =>  <FaIcon icon={faTable} {...props} />

export const BarChartIcon =({ ...props }) =>  <FaIcon icon={faChartBar} {...props} />

export const PieChartIcon =({ ...props }) =>  <FaIcon icon={faChartPie} {...props} />

export const CalendarIcon =({ ...props }) => <FaIcon icon={faCalendar} {...props} />

export const SaveIcon = ({ ...props }) => <FaIcon icon={faSave} {...props} />

export const EditIcon = ({ ...props }) => <FaIcon icon={faEdit} {...props} />

export const DeleteIcon = ({ ...props }) => <FaIcon icon={faTrashAlt} {...props} />

export const PlusIcon = ({ ...props }) => <FaIcon icon={faPlus} {...props} />

export const AdminIcon = ({ ...props }) => <FaIcon icon={faUserCog} {...props} />

export const BlogIcon = ({ ...props }) => <FaIcon icon={faCogs} {...props} />

export const LogoutIcon = ({ ...props }) => <FaIcon icon={faSignOut} {...props} />

export const SubscriptionIcon = ({ ...props }) => <FaIcon icon={faKey} {...props} />

export const EmailIcon = ({ ...props }) => <FaIcon icon={faAt} {...props} />

export const HomeIcon = ({ ...props }) => <FaIcon icon={faHome} {...props} />

export const AddressIcon = ({ ...props }) => <FaIcon icon={faEnvelope} {...props} />

export const PhoneIcon = ({ ...props }) => <FaIcon icon={faPhone} {...props} />

export const HelpIcon = ({ ...props }) => <FaIcon icon={faQuestionCircle} {...props} />

export const BorderCrossingIcon = ({ ...props }) => <FaIcon icon={faMinusCircle} {...props} />

export const RoadSectionIcon = ({ ...props }) => <FaIcon icon={faRoad} {...props} />

export const LinkIcon = ({ ...props }) => <FaIcon icon={faExternalLinkAlt} {...props} />

export const MaintenanceIcon = ({ ...props }) => <FaIcon icon={faTools} {...props} />

export const UnreadIcon = ({ ...props }) => <FaIcon icon={faEnvelope} {...props} />

export const ReadIcon = ({ ...props }) => <FaIcon icon={faEnvelopeOpen} {...props} />

export const AlertIcon = ({ ...props }) => <FaIcon icon={faExclamationCircle} {...props} />

export const CollectionIcon = ({ ...props }) => <FaIcon icon={faLayerGroup} {...props} />

export const OrganizationIcon = ({ ...props }) => <FaIcon icon={faSuitcase} {...props} />

export const GridIcon = ({ ...props }) => <FaIcon icon={faGrip} {...props} />

export const ListIcon = ({ ...props }) => <FaIcon icon={faList} {...props} />

export const BlogEntryIcon = ({ ...props }) => <FaIcon icon={faCog} {...props} />

export const AnnouncementIcon = ({ ...props }) => <FaIcon icon={faBullhorn} {...props} />

export const UpdateIcon = ({ ...props }) => <FaIcon icon={faRefresh} {...props} />

export const MessageIcon = ({ unread, ...props }) => unread 
    ? <UnreadIcon {...props} style={{...(props?.style || {}), color: 'var(--rs-color-orange)'}} />
    : <ReadIcon {...props} style={{...(props?.style || {}), color: 'var(--rs-gray-500)'}} />; 


export const TimeIcon = ({ ...props }) => <FaIcon icon={faClock} {...props} />

export const ValidIcon = ({ ...props }) => <FaIcon icon={faCheckCircle} {...props} />

export const PastValidIcon = ({ ...props }) => <FaIcon icon={faTimesCircle} {...props} />

export const FutureValidIcon = ({ ...props }) => <FaIcon icon={faCircle} {...props} />

export const PasswordIcon = ({ ...props }) => <FaIcon icon={faUnlockAlt} {...props} />

export const OnboardCountIcon = ({ ...props }) => <FaIcon icon={faBus} {...props} />

export const CrossSectionRoadCountIcon = ({ ...props }) => <FaIcon icon={faRoad} {...props} />

export const LocationIcon = ({ ...props }) => <FaIcon icon={faMapMarkerAlt} {...props} />

export const IntervalIcon = ({ ...props }) => <FaIcon icon={faCalendarAlt} {...props} />

export const MenuIcon = ({ ...props }) => <FaIcon icon={faBars} {...props} />
