import FormInput from 'components/inputs/FormInput';
import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext'
import { ModuleFilter } from 'features/webmapModule';
import React from 'react'
import SearchFormFields from '../forms/SearchFormFields';
import { t } from 'i18next';
import { Station } from 'features/crossSectionRoadCount/elements';

const SearchFilter = () => {
    const module = React.useContext(CrossSectionRoadCountContext);
    const [stations, setStations] = React.useState([]);

    React.useEffect(() => {
        if (module && module?.map?.queryFeatures) {
            module.map.queryFeatures({ 
                layer: 'segment',
                where: "KUTKA NOT IN ('8', '9')"
            })
                .then(features => features.map(f => f.attributes))
                .then(items => items.map(Station))
                .then(stations => Object.fromEntries(stations.map(station => [station.code, station])))
                .then(setStations)
        }

    }, [module])

    const [values, setValues] = React.useState({});

    function applyValues () {
        if (!module?.applyFilters) return;
        const filterValues = {}
        if (values?.selectedSection) {
            filterValues.selectedSection = values.selectedSection
        } else if (values?.settlement) {
            filterValues.settlement = values.settlement
        } else {
            filterValues.stationCode = values?.stationCode
        }

        module.applyFilters(filterValues)
    }


    return (
        <ModuleFilter 
            name='search'
            label={t('OKKF/SearchOnMap')}
            acceptor={FormInput}
            disabled={!module.initialized}
            popoverWidth={320}
            instructionText={module?.tutorialStep === 4 && t('OKKF/SearchFilterInstructions')}
            placement={'bottomEnd'}
            submitLabel={t('Search')}
            value={t('OKKF/Search')}
            onSubmit={applyValues}
        >
            <SearchFormFields 
                onChange={setValues} />

        </ModuleFilter>
    )
}

export default SearchFilter