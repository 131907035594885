import 'assets/css/webmapModule.css'

import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext';
import { ModuleFilter } from 'features/webmapModule';
import React from 'react'
import FormInput from 'components/inputs/FormInput';
import { t } from 'i18next';
import MapViewFormFields from '../forms/MapViewFormFields';
import { VIEW_TYPE_ABBREVIATIONS } from 'features/crossSectionRoadCount/utils/constants';
import { asRoman } from 'utils/math';

const MapViewFilter = () => {
    const module = React.useContext(CrossSectionRoadCountContext);

    const [values, setValues] = React.useState({});

    const {
        viewType,
        year,
        month,
        dayTypeId,
        vehicleClassCodes,
    } = module?.filters;

    function applyValues () {
        if (!module?.applyFilters) return;

        module.applyFilters(values);
    }

    let label = VIEW_TYPE_ABBREVIATIONS[viewType];
    if (year) {
        label += ` - ${year}`;
    }
    if (month && dayTypeId) {
        label += ` ${asRoman(month)}. hó ${dayTypeId}. naptípus`
    }

    if (vehicleClassCodes?.length) {
        label += ` (${vehicleClassCodes.join(', ')})`
    }

    return (
        <ModuleFilter 
            name="mapView"
            label={t('OKKF/MapView')}
            help={t('OKKF/MapViewHelp')}
            acceptor={FormInput}
            disabled={!module.initialized}
            popoverWidth={320}
            instructionText={module?.tutorialStep === 1 && t('OKKF/MapViewFilterInstructions')}
            value={values}
            submitLabel={t('OKKF/SetMapView')}
            renderValue={() => {
                return label
            }}
            onSubmit={applyValues}
        >
            <MapViewFormFields 
                includeRoadsView
                includeVehiceClassCodes
                values={values}
                onChange={setValues} />

        </ModuleFilter>
    )
}

export default MapViewFilter