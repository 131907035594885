import { faCogs, faCompressArrowsAlt, faExpandArrowsAlt, faList, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faSave, faUserCog } from "@fortawesome/free-solid-svg-icons";
import FaIcon from "components/icons";


export const LegendIcon = ({ ...props }) => <FaIcon icon={faList} {...props} />;

export const ShowExtentIcon = ({ ...props }) => <FaIcon icon={faExpandArrowsAlt} {...props} />;

export const ZoomInIcon = ({ ...props }) => <FaIcon icon={faMagnifyingGlassPlus} {...props} />;

export const ZoomOutIcon = ({ ...props }) => <FaIcon icon={faMagnifyingGlassMinus} {...props} />;

export const ZoomToSelectionIcon = ({ ...props }) => <FaIcon icon={faCompressArrowsAlt} {...props} />;

export const SaveIcon = ({ ...props }) => <FaIcon icon={faSave} {...props} />;

export const ViewPreferencesIcon = ({ ...props }) => <FaIcon icon={faUserCog} {...props} />;

export const AdminIcon = ({ ...props }) => <FaIcon icon={faCogs} {...props} />;