import 'assets/css/form.css';

import React from "react";
import { Button, ButtonToolbar, DatePicker, Form, Input, SelectPicker, TagPicker, Toggle } from "rsuite";
import { t } from "i18next";

import { FormGroup } from 'components/forms';
import { noop } from "utils/constants";
import { HTMLInput, ImageURLInput } from 'components/inputs';
import { AuthContext } from 'features/auth';
import { getWebMapList } from 'features/webmap/webmapAPI';
import { getModuleTypeLabels } from '../utils';


const BLOG_ENTRY_CATEGORIES = {
    'update': t('Blog/Update'),
    'maintenance': t('Blog/Maintenance')
};

const ANNOUNCEMENT_CATEGORIES = {
    'announcement': t('Blog/Announcement')
};


const BlogEntryForm = ({ 
    formData,
    onSubmit = noop,
    ...props 
}) => {
    const { user } = React.useContext(AuthContext);

    const [ webmaps, setWebmaps ] = React.useState([]);

    const [ title, setTitle ] = React.useState(formData?.title || '');
    const [ category, setCategory ] = React.useState(formData?.category);
    const [ content, setContent ] = React.useState(formData?.content || '');
    const [ webmapId, setWebmapId ] = React.useState(formData?.webMapId);
    const [ tags, setTags ] = React.useState(formData?.tags || []);
    const [ publicFrom, setPublicFrom ] = React.useState(formData?.publicFrom ? new Date(formData?.publicFrom) : undefined);
    const [ publicUntil, setPublicUntil ] = React.useState(formData?.publicUntil ? new Date(formData?.publicUntil) : undefined);
    const [ published, setPublished ] = React.useState(formData?.published || false);
    const [ featured, setFeatured ] = React.useState(formData?.announcement || false);
    const [ coverUrl, setCoverUrl ] = React.useState(formData?.coverUrl);

    const announcement = formData?.announcement || false;

    const submitData = {
        title,
        category,
        featured,
        coverUrl,
        content,
        tags,
        webmapId,
        publicFrom,
        publicUntil,
        published,
    };

    React.useEffect(() => {
        getWebMapList().then(({ data }) => {
            if (data?.length) {
                setWebmaps(data);
            }
        });
    }, [user])

    return (
        <Form {...props}>

            <FormGroup
                name='title'
                label={ t('Title') }
                accepter={Input}
                value={title}
                onChange={setTitle}
            />

            <FormGroup 
                name='category'
                label={ t('Blog/Category') }
                accepter={SelectPicker}
                searchable={false}
                data={Object.entries(announcement ? ANNOUNCEMENT_CATEGORIES : BLOG_ENTRY_CATEGORIES).map(([value, label]) => ({value, label}))}
                value={category}
                onChange={setCategory}
            />

            { announcement && <>
                <FormGroup 
                    name='coverURL'
                    label={ t('CoverURL') }
                    accepter={ImageURLInput}
                    value={coverUrl}
                    onChange={setCoverUrl}
                />
            </>}
            
            <FormGroup
                name='publicFrom'
                label={ t('Blog/PublicFrom') }
                accepter={DatePicker}
                format='yyyy.MM.dd HH:mm'
                value={publicFrom}
                onChange={setPublicFrom}
            />
            
            <FormGroup
                name='publicUntil'
                label={ t('Blog/PublicUntil') }
                accepter={DatePicker}
                format='yyyy.MM.dd HH:mm'
                value={publicUntil}
                onChange={setPublicUntil}
            />

            <FormGroup
                name='published'
                label={ t('Blog/Published') }
                accepter={Toggle}
                value={published}
                onChange={setPublished}
            />

            <FormGroup 
                name='content'
                label={ t('Content') }
                accepter={HTMLInput}
                value={content}
                onChange={setContent}
            />

            <FormGroup 
                name='tags'
                label={ t('Blog/Tags') }
                accepter={TagPicker}
                searchable={false}
                cleanable={false}
                data={Object.entries(getModuleTypeLabels()).filter(([name, label]) => user?.blogTags?.includes(name)).map(([name, label]) => ({
                    value: name,
                    label: label
                }))}
                value={tags}
                onChange={setTags}
            />

            <FormGroup 
                name='webmap'
                label={ t('WebMap') }
                accepter={SelectPicker}
                data={webmaps.map(webmap => ({
                    value: webmap.id,
                    label: `${webmap?.name}` 
                        + (webmap?.location ? ` (${webmap?.location})` : '')
                        + ' - '
                        + webmap?.organization?.name
                }))}
                value={webmapId}
                onChange={setWebmapId}
            />

            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" onClick={() => onSubmit(submitData)}>
                        { formData ? t('Save') : t('CreateBlogEntry') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );
};


export default BlogEntryForm;
