import { Col,  Grid,  Row } from "rsuite";

import { CoverCard } from "components/cards";

const TileGrid = ({
    data = [],
    renderItem = item => <CoverCard title={item.name} height={180} />,
    maxColumns = 24,
    xs = 24,
    sm = 24,
    md = 12,
    lg = 8,
    xl = 6,
    xxl = 4,
}) => {
    return (
        <Row>
            { data.map( (item, index) => (
                <Col 
                    key={index} 
                    xs={Math.max(Math.floor(24 / maxColumns), xs)} 
                    sm={Math.max(Math.floor(24 / maxColumns), sm)} 
                    md={Math.max(Math.floor(24 / maxColumns), md)} 
                    lg={Math.max(Math.floor(24 / maxColumns), lg)} 
                    xl={Math.max(Math.floor(24 / maxColumns), xl)} 
                    xxl={Math.max(Math.floor(24 / maxColumns), xxl)} 
                    style={{marginBottom: 10}}
                >
                    { renderItem(item) }
                </Col>
            )) }
        </Row>
    );
};


export default TileGrid;
