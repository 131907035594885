import 'assets/css/form.css';

import React from "react";
import { Button, ButtonToolbar, Checkbox, DatePicker, Form, Input, SelectPicker, TagPicker, Toggle } from "rsuite";
import { t } from "i18next";

import { FormGroup } from 'components/forms';
import { noop } from "utils/constants";
import { getOrganizationList } from 'features/organization';
import { getCollectionList } from 'features/collection';
import { HTMLInput, ImageURLInput } from 'components/inputs';
import { AuthContext } from 'features/auth';
import { formatDate } from 'utils/format';


const WebmapForm = ({ 
    formData,
    onSubmit = noop,
    ...props 
}) => {
    const { user } = React.useContext(AuthContext);

    const [ organizations, setOrganizations ] = React.useState([]);
    const [ collections, setCollections ] = React.useState([]);

    const [ organizationId, setOrganizationId ] = React.useState(formData?.organization?.id);
    const [ name, setName ] = React.useState(formData?.name || '');
    const [ description, setDescription ] = React.useState(formData?.description || '');
    const [ location, setLocation ] = React.useState(formData?.location || '');
    const [ startDate, setStartDate ] = React.useState(formData?.startDate || '');
    const [ endDate, setEndDate ] = React.useState(formData?.endDate || '');
    const [ maintenance, setMaintenance ] = React.useState(formData?.maintenance || false);
    const [ thumbnailUrl, setThumbnailUrl ] = React.useState(formData?.thumbnailUrl || '');
    const [ collectionIds, setCollectionIds ] = React.useState(formData?.collections?.map(c => c.id) || []);

    const submitData = {
        organizationId,
        name,
        description,
        location,
        startDate: startDate || '-',
        endDate: endDate || '-',
        maintenance,
        thumbnailUrl,
        collectionIds
    };

    React.useEffect(() => {
        getOrganizationList().then(({ data }) => {
            if (data?.length) {
                setOrganizations(data);
                if (!organizationId) {
                    setOrganizationId(data[0]?.id)
                }
            }
        });
        getCollectionList().then(({ data }) => {
            if (data?.length) {
                setCollections(data);
            }
        });
    }, [user])

    return (
        <Form {...props}>
            <FormGroup
                name='organization'
                label={ t('Organization') }
                accepter={SelectPicker}
                data={organizations.map(organization => ({
                    value: organization.id,
                    label: organization.name
                }))}
                value={organizationId}
                onChange={setOrganizationId}
                cleanable={ false }
            />
            <FormGroup
                name='name'
                label={ t('Name') }
                accepter={Input}
                value={name}
                onChange={setName}
            />
            <FormGroup 
                name='description'
                label={ t('Description') }
                accepter={HTMLInput}
                value={description}
                onChange={setDescription}
            />
            <FormGroup 
                name='location'
                label={ t('Map/Location') }
                accepter={Input}
                value={location}
                onChange={setLocation}
            />
            <FormGroup 
                name='startDate'
                label={ t('Map/StartDate') }
                accepter={DatePicker}
                value={startDate && new Date(startDate)}
                onChange={(value) => setStartDate(formatDate(value))}
            />
            <FormGroup 
                name='endDate'
                label={ t('Map/EndDate') }
                accepter={DatePicker}
                value={endDate && new Date(endDate)}
                onChange={(value) => setEndDate(formatDate(value))}
            />
            <FormGroup 
                name='maintenance'
                label={ t('Map/UnderMaintenance') }
                accepter={Toggle}
                value={maintenance}
                onChange={setMaintenance}
            />
            <FormGroup 
                name='thumbnailURL'
                label={ t('ThumbnailURL') }
                accepter={ImageURLInput}
                value={thumbnailUrl}
                onChange={setThumbnailUrl}
            />
            <FormGroup
                name='collection'
                label={ t('Collection') }
                accepter={TagPicker}
                data={collections.map(collection => ({
                    value: collection.id,
                    label: collection.name
                }))}
                value={collectionIds}
                onChange={setCollectionIds}
            />
            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" onClick={() => onSubmit(submitData)}>
                        { formData?.id ? t('Save') : t('CreateWebMap') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );
};


export default WebmapForm;
