import React from "react";
import { Col, Grid, Row } from "rsuite";

import WebMapPanel from "features/webmap/components/WebMapPanel";
import SidebarPage from "layouts/SidebarPage";
import PillInput from "components/inputs/PillInput";
import { t } from "i18next";


function WebMapListPage () {

    const [ sort, setSort ] = React.useState('name');

    const sortData = [{
        label: t('ByName'),
        value: 'name'
    }, {
        label: t('LastViewed'),
        value: 'lastViewed'
    }, {
        label: t('LastCreated'),
        value: 'lastCreated'
    }, {
        label: t('MostViewed'),
        value: 'mostViewed'
    }]

    return (
        <SidebarPage activeKey="webmaps">

            <Grid className="mainGrid">
                <Row>
                    <Col xs={24} >
                        <WebMapPanel 
                            canSetView
                            sort={sort}
                            hidePlaceholder
                            defaultView="grid"
                            maxColumns={4}
                        >
                            <PillInput 
                                size="sm"
                                data={sortData}
                                value={sort}
                                onChange={setSort}
                            />
                        </WebMapPanel>
                    </Col>
                </Row>
            </Grid>  

        </SidebarPage>
    )
};


export default WebMapListPage;
