import React from 'react'
import { Panel } from 'rsuite'
import { noop } from 'utils/constants'
import CardHeader from './CardHeader'
import { urlToCSSImage } from 'utils/convert';

import "assets/css/card.css";
import { t } from 'i18next';
import { AngleDown, AngleUp } from 'components/icons';
import { Layover } from 'components/layovers';


const IMAGE_PLACEHOLDER_URL = "https://shahpourpouyan.com/wp-content/uploads/2018/10/orionthemes-placeholder-image-1.png";


const CoverImage = ({ height, url, ...props}) => {
    return (
        <div className="coverImage" style={{ 
            height, 
            backgroundImage: `url(${urlToCSSImage(url || IMAGE_PLACEHOLDER_URL)})`,
            ...props.style
        }} /> 
    );
};

const SubtitleSeparator = () => <span className="separator"> | </span>


const Card = ({
    tile,

    icon,
    category,

    overlayIcon,
    overlayHint,
    
    title,
    subtitle,
    description,
    hint,

    inline,
    height,
    size="md",

    iconBackgroundColor,
    imageURL,
    imageHeight = 200,
    usePlaceholder,
    expandAvatar,

    onClick,
    actions = [],
    disabled,

    expandable,
    defaultExpand = false,
    onExpandChange = noop,

    children,

    ...props
}) => {
    const [ expanded, setExpanded ] = React.useState(defaultExpand)
    const categoryLabel = t(category?.capitalize());
    const [firstRun, setFirstRun] = React.useState(true);

    React.useEffect(() => {
        setFirstRun(false);
    }, []);

    React.useEffect(() => {
        if (!firstRun) {
            onExpandChange(expanded)
        }
    }, [expanded])

    const expandActions = !expandable ? [] : [{
        appearance: "subtle",
        title: expanded ? t("Collapse") : t("Expand"),
        icon: expanded ? <AngleUp /> : <AngleDown />,
        onClick: () => setExpanded(!expanded)
    }]


    const headerProps = {
        title,
        subtitle,
        overlayIcon: !tile && overlayIcon,
        overlayHint: !tile && overlayHint,
        description,
        hint,
        icon: !tile && icon,
        iconBackgroundColor,
        imageURL: !tile && imageURL,
        usePlaceholder: !tile && usePlaceholder,
        actions: [...actions, ...expandActions],
        size,
        disabled,
        expandAvatar
    };

    const coverHeight = tile ? imageHeight : undefined

    return (
        <Panel
            className={`card${tile ? ' coverCard' : ''}${children ? ' hasChildren' : ''} ${props?.className || ''}`}
            bordered
            shaded
            bodyFill
            style={isNaN(height + (coverHeight || 0)) ? {} : {height: height + coverHeight}}
            {...props}
        >
            <div className="content">
                { tile && (<>
                    <div className="coverContainer">
                        <div className={`category` + (category ? ` category-${category}` : "")}>
                            { icon && <>
                                <div className="icon">
                                    { icon }
                                </div>
                            </>}

                            { false && categoryLabel && <>
                                <div className="name">
                                    { categoryLabel }
                                </div>
                            </>}
                        </div>
                        <CoverImage height={coverHeight} url={imageURL}/> 
                        {overlayIcon && (
                            <Layover dark large title={overlayHint} style={{fontWeight: 'bold'}}>
                                { overlayIcon } 
                                { overlayHint && (
                                    <span style={{marginLeft: 4}}>{ overlayHint }</span>
                                )}
                            </Layover>
                        )}
                    </div> 
                </> )}
                <Panel
                    className={`card avatarCard ${size}${inline ? ' inline' : ''}`}
                    header={<CardHeader {...headerProps} subtitleSeparator={(!tile || description) ? <SubtitleSeparator /> : <br />} />}
                    disabled={disabled} 
                >
                    { (!expandable || expanded) && children }

                </Panel>
            </div>
            { onClick && !disabled ? <div className="clickable" onClick={onClick} /> : <></>}
        </Panel>
        
    )
}

export default Card