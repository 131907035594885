import 'assets/css/mapViewer.css';
import ResizablePanel from 'components/panels/ResizablePanel';

import React from 'react';


const MapSidebar = ({
    children
}) => {
    if (!children || children?.length === 0) {
        return null;
    }

    return (
        <ResizablePanel 
            side="left" 
            className="mapSidebar" 
            shaded
            bodyFill 
            defaultWidth={420} 
            minWidth={300} 
            maxWidth={800}
        >
            <div className="mapSidebarContent">
                {children}
            </div>
        </ResizablePanel>
    );
};


export default MapSidebar;
