import { faAreaChart, faArrowDown, faArrowUp, faBarChart, faBezierCurve, faChartLine, faClock,  faExchange,  faLevelDownAlt,  faLevelUp,  faLevelUpAlt,  faMapMarkerAlt, faPause, faProjectDiagram, faRoad, faSplotch, faTachometerAltFast } from '@fortawesome/free-solid-svg-icons';
import FaIcon, { ArrowDownIcon, ArrowRightIcon, ArrowUpIcon, ESRIIcon } from 'components/icons';


export const StartTimeIcon = ({ ...props }) => <FaIcon icon={faClock} {...props} />

export const StopIcon = ({ ...props }) => <FaIcon icon={faPause} {...props} />

export const StopPlaceIcon = ({ ...props }) => <FaIcon icon={faMapMarkerAlt} {...props} />

export const AreaIcon = ({ ...props }) => <FaIcon icon={faSplotch} {...props} />

export const ServicePatternIcon = ({ ...props }) => <FaIcon icon={faProjectDiagram} {...props} />

export const ServicePatternGroupIcon = ({ ...props }) => <FaIcon icon={faBezierCurve} {...props} />

export const LineTrafficIcon = ({ ...props }) => <FaIcon icon={faChartLine} {...props} />

export const TrafficDistributionIcon = ({ ...props }) => <FaIcon icon={faAreaChart} {...props} />;

export const BoardTrafficIcon = ({ ...props }) => <FaIcon icon={faBarChart} transform="rotate-90 flip-v" {...props} />;

export const NetworkPerformanceIcon = ({ ...props }) => <FaIcon icon={faTachometerAltFast} {...props} />

export const ArrivalIcon = ({ ...props }) => <FaIcon icon={faLevelDownAlt} {...props} />

export const DepartureIcon = ({ ...props }) => <FaIcon icon={faLevelUpAlt} {...props} />

export const AlightIcon = ({ ...props }) => <ArrowDownIcon {...props} />

export const BoardIcon = ({ ...props }) => <ArrowUpIcon {...props} />

export const OnwardIcon = ({ ...props }) => <ArrowRightIcon {...props} />

export const SectionIcon = ({ ...props }) => <FaIcon icon={faRoad} {...props} />

export const AlightBoardIcon = ({ ...props }) => <FaIcon icon={faExchange} transform={{ rotate: 90}} {...props} />

export const UpwardIcon = ({ ...props }) => <FaIcon icon={faArrowUp} {...props} />

export const DownwardIcon = ({ ...props }) => <FaIcon icon={faArrowDown} {...props} />

