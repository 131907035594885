import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Col, Divider, Grid, IconButton, Panel, Row } from "rsuite";

import Jumbotron from "components/pages/Jumbotron";
import SidebarPage from "layouts/SidebarPage";

import useBlogEntry from "../hooks/useBlogEntry";
import { PageTitle } from "components/pages";
import { t } from "i18next";
import { getModuleTypeName } from "../utils";
import { ArrowLeftIcon, TimeIcon } from "components/icons";


function BlogEntryPage () {

    const { blogEntryId } = useParams();
    const navigate = useNavigate();
    const blogEntry = useBlogEntry(blogEntryId);
    const announcement = blogEntry?.category === 'announcement';
    const subtitle =  announcement ? t('Blog/Announcement') : t('Blog/Entry');

    return (
        <SidebarPage activeKey={announcement ? "anncouncements" : "blog"}>

            <Grid className="mainGrid">
                
                <Jumbotron backgroundUrl={blogEntry?.coverUrl} >
                    <PageTitle 
                        item={blogEntry} 
                        subtitle={<>
                            <TimeIcon /> {blogEntry?.publicFrom}
                            { blogEntry?.tags?.map(tag => ` | ${getModuleTypeName(tag)}`) }
                        </>}
                    />
                </Jumbotron>
                
                <Row>
                    <Col xs={24}>
                        <Button appearance="subtle" onClick={() => navigate(-1)}>
                            <ArrowLeftIcon style={{marginRight: 4}} /> {t('Back')}
                        </Button>
                    </Col>
                    <Col xs={24} >
                        <Panel shaded bordered>
                            <div dangerouslySetInnerHTML={{ __html: blogEntry?.content }} />
                        </Panel>
                    </Col>
                </Row>
            </Grid>  

        </SidebarPage>
    )
};


export default BlogEntryPage;
