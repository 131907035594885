import React from "react";
import { useParams } from "react-router-dom";

import { CrossSectionRoadCountContext, CrossSectionRoadCountContextProvider } from "features/crossSectionRoadCount";
import { OnboardCountContext, OnboardCountContextProvider } from "features/onboardCount";
import { ModuleContent, ModuleHeader, ModuleSidenav } from "features/webmapModule";

import MapViewer from "../components/MapViewer";
import WebmapContext, { WebmapContextProvider } from "../context/WebmapContext";
import { t } from "i18next";
import { Button, Message } from "rsuite";
import { AuthContext } from "features/auth";
import SidebarPage from "layouts/SidebarPage";
import { getSubtitleFromMetadata } from "utils/elements";
import { SimpleModal } from "components/modals";
import { checkCertificate, openCertificate } from "utils/cert";
import WebMapHeader from "../components/WebMapHeader";


const CertCheckModal = ({
    certErr, 
    certChecked,
    checkCert,
    acceptCert
}) => {
    return (
        <SimpleModal
            open={certErr}
            title={t('CertificateError')} 
        >   
            { certChecked ? (
                <Message showIcon type="error">
                    { t('AcceptCertificateDescription') }
                </Message>
            ) : (
                <div>
                    { t('CheckCertificateDescription') }
                </div>
            )}

            <Button 
                appearance="primary" 
                onClick={certChecked ? acceptCert : checkCert} 
                style={{marginTop: 20}}
            >
                { certChecked? t('AcceptCertificate') : t('CheckCertificate') }
            </Button>
        </SimpleModal>
    )
}


function WebMapPageContent () {
    const [certErr, setCertErr] = React.useState(false);
    const [certChecked, setCertChecked] = React.useState(false);
    const { webmap, view } = React.useContext(WebmapContext); 
    const { isAdmin } = React.useContext(AuthContext);

    const maintenance = webmap?.maintenance;

    const onboardCount = React.useContext(OnboardCountContext);
    const crossSectionRoadCount = React.useContext(CrossSectionRoadCountContext);
    const module = onboardCount || crossSectionRoadCount;

    function checkCert() {
        if (webmap?.serverUrl) {
            checkCertificate(webmap?.serverUrl).then((res) => {
                setCertErr(!res)
                setCertChecked(true)
            })
        }
    }

    function acceptCert() {
        openCertificate(webmap?.serverUrl).then(() => setCertChecked(false))
    }

    React.useEffect(() => {
        checkCert();
    }, [webmap?.serverUrl]);

    return (
        <SidebarPage
            navbar
            style={{padding: 8}}
            simpleTitle={webmap?.name && `${webmap?.name}${webmap?.isDemo ? ' ' + t('Map/Demo') : ''}`}
            title={<WebMapHeader 
                webmap={webmap} 
                extraInfo={[
                    ...(onboardCount?.info || []), 
                    ...(crossSectionRoadCount?.info || [])
                ]}
            />}
            sidenavClass={module ? ModuleSidenav : null}
            sidenavCollapsedWidth={60}
            hideFooter
        >
            { webmap?.maintenance && !isAdmin ? <>
                <div style={{margin: 60}}>
                    <Message showIcon type="warning" header={ t('Map/UnderMaintenance') }>
                        { t('Map/UnderMaintenanceDescription') }
                    </Message>
                </div>
            </> : <>
                {!maintenance && !certErr && <ModuleHeader />}
                { !certErr && <>
                    <MapViewer view={view} />
                    <ModuleContent view={view} />
                </>}
                
            </> }
            <CertCheckModal 
                certErr={certErr}
                certChecked={certChecked}
                checkCert={checkCert}
                acceptCert={acceptCert}
            />
        </SidebarPage>  
    )
}

function WebMapPage () {
    
    const { webmapId } = useParams();

    return (
        <WebmapContextProvider id={webmapId}>
            <OnboardCountContextProvider>
                <CrossSectionRoadCountContextProvider>
                    <WebMapPageContent />
                </CrossSectionRoadCountContextProvider>
            </OnboardCountContextProvider>
        </WebmapContextProvider>
    );
};

export default WebMapPage;