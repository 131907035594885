import React from 'react';
import { AvatarCard } from 'components/cards';
import TransportLineBadge from '../badges/TransportLineBadge';
import StartTimeBadge from '../badges/StartTimeBadge';
import { secondsToString } from 'features/onboardCount/utils/time';
import { t } from 'i18next';


const JourneyCard = ({
    size="md",
    servicePattern,
    startTime
}) => {
    return (
        <AvatarCard
            size={size}
            icon={<>
                <TransportLineBadge size={size} {...servicePattern.transportLine} />
                <StartTimeBadge size={size} startTime={startTime} />
            </>}
            title={servicePattern.routeName}
            subtitle={servicePattern.name}
            hint={`${t('FT/Journey')}: ${secondsToString(startTime)} ${servicePattern.transportLine.name} ${servicePattern.routeName} (${servicePattern.name})`}
        />
    );
};


export default JourneyCard;
