import { secondsToString } from '../utils/time';
import ServicePattern, { compareServicePatterns } from './ServicePattern';
import { noop } from 'utils/constants';


function Journey({
    id,
    servicePatternId,
    lineId,
    lineType,
    lineName,
    routeName,
    servicePatternName,
    startTime,
    traffic,
    operator,
}) {
    const journey = {
        elementType: 'journey',
        elementId: Number(id),
        id: Number(id),
        servicePattern: ServicePattern({
            id: servicePatternId,
            lineId,
            lineType,
            lineName,
            routeName,
            name: servicePatternName,
            operator,
        }),
        startTime,
        startTimeStr: secondsToString(startTime),
        traffic,
        label: `${lineName} ${secondsToString(startTime)} ${servicePatternName}`,
        compare: noop,
    };

    journey.compare = other => compareJourneys(journey, other);

    return journey;
};


export function compareJourneys(a, b) {
    return a.startTime - b.startTime ||
        compareServicePatterns(a.servicePattern, b.servicePattern);
};


export default Journey;
