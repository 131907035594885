import React from 'react'
import { Button, CheckPicker } from 'rsuite'

import { ModuleFilter } from 'features/webmapModule'
import { t } from 'i18next';
import ServicePatternCard from '../cards/ServicePatternCard';
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';


const ServicePatternFilter = ({
}) => {

    const module = React.useContext(OnboardCountContext);

    const controlRef = React.useRef();
    const {
        transportLineIds = [],
        servicePatternIds = [],
    } = module?.filters || {};

    const {
        servicePatterns = {}
    } = module?.elements || {}

    const [ value, setValue ] = React.useState(servicePatternIds);

    React.useEffect(() => {
        setValue(servicePatternIds);
    }, [`${servicePatternIds}`])

    const data = Object.values(servicePatterns).filter(s => transportLineIds.length ? transportLineIds.includes(s?.transportLine?.id) : true).sortBy().map(servicePattern => ({
        label: `${servicePattern?.transportLine?.name} ${servicePattern.name}`,
        elementType: 'servicePattern',
        element: servicePattern,
        value: servicePattern.id,
    }))


    function submitValues(values) {
        module.applyFilters({servicePatternIds: values});
    }

    return ( 
        <ModuleFilter 
            ref={controlRef}
            name='servicePatterns'
            label={t('FT/ServicePatterns')}
            acceptor={CheckPicker}
            disabled={!data.length}
            data={data}
            renderMenuItem={(label, item) => <ServicePatternCard size="xs" {...(item?.element || {})} />}
            value={value}
            placeholder={t('FT/AllServicePatterns')}
            onChange={setValue}
            onExit={() => setValue(servicePatternIds)}
            onClean={() => submitValues([])}
            renderExtraFooter={() => <div style={{padding: 10}}>
                <Button 
                    appearance="primary"
                    block 
                    onClick={() => {
                        controlRef.current.close()
                        submitValues(value)
                    }
                }>
                    { t('ApplyFilter') }
                </Button>
            </div>}
        />
     )
}

export default ServicePatternFilter