import React from "react";
import { Col, Grid, Row } from "rsuite";

import Jumbotron from "components/pages/Jumbotron";
import PageTitle from "components/pages/PageTitle";

import { t } from "i18next";
import { ALL_MODULE_TYPES, getModuleTypeLabels } from "../utils";
import { AuthContext } from "features/auth";
import BlogEntryPanel from "../components/BlogEntryPanel";
import PillInput from "components/inputs/PillInput";
import SidebarPage from "layouts/SidebarPage";


function BlogPage ({
    announcements = false
}) {
    
    const { user } = React.useContext(AuthContext);
    const [moduleType, setModuleType] = React.useState(ALL_MODULE_TYPES);

    if ( !user?.blogActive ) {
        return
    }

    const moduleTypeData = Object.entries(getModuleTypeLabels())
        .filter(([name]) => name == ALL_MODULE_TYPES || user?.blogTags?.includes(name))
        .map(([name, label]) => ({label, value: name}))

    return (
        <SidebarPage activeKey={announcements ? "anncouncements" : "blog"}>

            <Grid className="mainGrid">
                <Row>
                    <Col xs={24} >
                        <BlogEntryPanel 
                            tag={moduleType === ALL_MODULE_TYPES ? undefined : moduleType}
                            featured={announcements}
                            renderContent
                            defaultPageSize={4}
                            pageSizeOptions={[4]}
                        >
                            <PillInput
                                size="sm"
                                data={moduleTypeData}
                                value={moduleType}
                                onChange={setModuleType} />
                        </BlogEntryPanel>
                    </Col>
                </Row>
            </Grid>  

        </SidebarPage>
    )
};


export default BlogPage;
