import { ModuleFilter } from 'features/webmapModule';
import { t } from 'i18next';
import React from 'react'
import OnboardCountFormFields from '../forms/OnboardCountFormFields';
import CountParametersCard from '../cards/CountParamtersCard';
import FormInput from 'components/inputs/FormInput';
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';
import OnboardCountStatus from '../OnboardCountStatus';
import OnboardCountDateSelectorFormFields from '../forms/OnboardCountDateSelectorFormFields';
import { dateToStr } from 'utils/date';
import { logEvent } from 'features/logging';


const COUNT_INTERVAL_TAB = 'countInterval';
const COUNT_DATE_TAB = 'countDate'

const CountIntervalFilter = () => {
    const module = React.useContext(OnboardCountContext);

    const [values, setValues] = React.useState({});
    const [activeTab, setActiveTab] = React.useState(values?.countDate ? COUNT_DATE_TAB : COUNT_INTERVAL_TAB);

    function applyValues () {
        if (!module?.applyFilters) return;
        logEvent('settingCountInterval', values)

        module.applyFilters(values);
    }

    const hasDayTypes = Object.values(module?.elements?.dayTypes || {}).length

    const validationErrors = []
    if (!values?.countInterval && !values?.countDate) {
        validationErrors.push(t("FT/NoAvailableCountIntervals"))
    }

    return (
        <ModuleFilter
            name="countInterval"
            label={t("FT/CountInterval")}
            acceptor={FormInput}
            disabled={!hasDayTypes}
            className={values?.countInterval || values?.countDate ? undefined : 'empty'}
            popoverWidth={400}
            value={values}
            renderValue={() => {
                const value = module?.filters
                const dayTypes = module?.elements?.dayTypes;

                return value?.countIntervalType && value?.startDate && value?.endDate ? (
                    <CountParametersCard
                        size="xs"
                        {...value}
                        dayTypes={dayTypes}
                    />
                ) : value?.countDate 
                    ? dateToStr(value.countDate) 
                    : t('FT/SelectCountIntervalFirst')
            }}
            validationErrors={validationErrors}
            onSubmit={applyValues}
            footer={<OnboardCountStatus />}
        >

            {/* <Nav activeKey={activeTab} onSelect={setActiveTab} appearance='tabs' >
                <Nav.Item eventKey={COUNT_INTERVAL_TAB}>
                    {t('FT/CountInterval')}
                </Nav.Item>
                <Nav.Item eventKey={COUNT_DATE_TAB}>
                    {t('FT/CountDateNew')}
                </Nav.Item>
            </Nav>

            <div style={{padding: 10}}> */}

                { activeTab === COUNT_DATE_TAB && (

                    <OnboardCountDateSelectorFormFields
                        countIntervals={module?.elements?.countIntervals}
                        values={module?.filters}
                        onChange={setValues}
                    />

                ) }
                { activeTab === COUNT_INTERVAL_TAB && (

                    <OnboardCountFormFields
                        dayTypes={module?.elements?.dayTypes}
                        countIntervals={module?.elements?.countIntervals}
                        values={module?.filters}
                        onChange={setValues}
                    />

                ) }

            {/* </div> */}
            
        </ModuleFilter>
    );
};


export default CountIntervalFilter;
