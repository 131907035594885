import 'assets/css/webmapModule.css';

import React from 'react';
import { Panel } from 'rsuite';
import { t } from 'i18next';

import { PanelHeader } from 'components/panels';
import ModuleChartToolbar from './ModuleChartToolbar';
import PanelMenu from 'components/panels/PanelMenu';
import { BarChartIcon } from 'components/icons';
import MediaQueryContext from 'context/MediaQueryContext';

const ModuleChartPanel = React.forwardRef(({
    module,
    title,
    children
}, ref) => {
    const { isMobile } = React.useContext(MediaQueryContext);
    const toolbarHeight = module?.toolbarRef?.current?.offsetHeight || 0;

    const navs = isMobile ? undefined : module?.chartMenu?.navs

    return <div ref={ref}>
        <Panel
            ref={ref}
            className='headerFill'
            bordered
            shaded
            header={<PanelHeader
                size='sm'
                icon={<BarChartIcon />}
                title={<>
                    {title || t('Charts')}
                    <PanelMenu navs={navs} module={module} level={1}/>
                </>}
                toolbar={<ModuleChartToolbar module={module} />}
            />}
            style={{height: `calc(100vh - ${toolbarHeight + 80}px)`}}
        >
            <PanelMenu navs={navs} module={module} level={2}/>
            {children}

        </Panel>
    </div>
});

export default ModuleChartPanel;