import 'assets/css/webmapModule.css';

import React from 'react'
import ModuleNavigation from './ModuleNavigation';
import { IconButton, useMediaQuery } from 'rsuite';
import { mediaQuerySizeMap } from 'rsuite/esm/useMediaQuery/useMediaQuery';
import { HelpIcon } from 'components/icons';
import { t } from 'i18next';


const ModuleToolbar = ({
    module,
    title,
    icon,
    children,
    footer,
}) => {
    const {
        toolbarRef,
    } = module;

    const [isMobile] = useMediaQuery('xs');

    return (
        <div ref={toolbarRef} className="moduleToolbar">
            <div className='content'>
                { title && <>
                    <div className="title" title={isMobile ? title : undefined}>
                        { icon }
                        { !isMobile && title }
                    </div>
                </>}

                <div className="filters">
                    { children }
                </div>

                { module?.tutorialSteps > 0 && (
                    
                    <div className="help">
                        <IconButton
                            appearance='subtle'
                            icon={<HelpIcon />}
                            title={t('Map/StartTutorial')}
                            onClick={() => module?.setTutorialStep(1)}
                        />
                    </div>
                )}


            </div>
            <div className='footer'>
                { footer }
            </div>
        </div>
    );
};


export default ModuleToolbar;
