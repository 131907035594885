import { AlertIcon, DeleteIcon, EditIcon, GridIcon, ListIcon, PlusIcon } from 'components/icons';
import { usePagination } from 'features/pagination';
import { t } from 'i18next';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Badge, Button, ButtonGroup, ButtonToolbar, IconButton, Nav, Panel, Toggle } from 'rsuite';
import { noop } from 'utils/constants';
import PanelHeader from './PanelHeader';
import PaginationNav from 'features/pagination/components/PaginationNav';
import Card from 'components/cards/Card';
import { TileGrid } from 'components/tiles';
import { PlaceholderList } from 'components/lists';
import ConfirmDeleteModal from 'components/modals/ConfirmDeleteModal';
import PageSizeForm from 'features/pagination/components/forms/PageSizeForm';
import PillInput from 'components/inputs/PillInput';


const CardPanelToolbar = ({
    alertCount,
    alertIcon = <AlertIcon />,
    alertLabel,
    alertAction,
    pagination,
    pageSizeOptions,
    hidePagination,
    showAllURL,
    view,
    setView,
}) => {
    const navigate = useNavigate()

    return <>
        <ButtonToolbar>
            
            { alertCount > 0 && <>
                <div style={{color: 'var(--rs-color-orange)', display: 'flex', alignItems: 'center'}}>
                    <span>{alertIcon}</span>
                    <span style={{marginLeft: 4}}>
                        {alertCount} {alertLabel?.toLowerCase()}
                    </span>
                </div>
                { alertAction }
            </>}

            { showAllURL && (
                <Button size="sm" appearance='default' onClick={() => navigate(showAllURL)}>
                    { t("ShowAll") }
                </Button>
            ) }

            { !hidePagination && pageSizeOptions?.length > 1 && (
                <PageSizeForm
                    size="sm"
                    pageSize={pagination.pageSize}
                    setPageSize={pagination.setPageSize}
                    options={pageSizeOptions}
                />
            )}

            { setView && (
                <PillInput
                    data={[{
                        label: <ListIcon />,
                        value: 'list'
                    },{
                        label: <GridIcon />,
                        value: 'grid'
                    }]}
                    value={view}
                    onChange={setView}
                />
            )}
        </ButtonToolbar>

    </>

};

const CardPanel = React.forwardRef(({   
    title,
    itemLabel,
    icon,

    getItems = noop,
    deleteItem,

    getAlertCount = noop,
    alertIcon,
    alertLabel,
    alertAction,

    modalClass,
    defaultModalValues,

    actions = [],

    defaultView = "list",
    canSetView,

    getTitle = item => item?.title || item?.name,
    getSubtitle = item => item?.subtitle,
    getDescription = item => item?.description,
    getChildren = noop,
    getIcon = noop,
    getIconBackgroundColor = noop,
    getOverlayIcon = noop,
    getOverlayHint = noop,
    getCategory = noop,
    getThumbnailURL = item => item?.thumbnailUrl,
    getCoverURL = item => item?.coverUrl,
    getActions = noop,
    getDisabled = noop,
    onItemClick = noop,
    onItemExpand = noop,
    cardProps = {},

    coverHeight = 160,
    tileHeight = 100,
    usePlaceholder = false,
    hideAvatar,
    expandable,
    maxColumns,

    defaultPage = 1,
    defaultPageSize = 8,
    pageSizeOptions = [],
    hidePagination,
    showAllURL,

    children,
    ...props

}, ref) => {
    const hidePlaceholder = !usePlaceholder;
    
    const [view, setView] = React.useState(defaultView);
    const [alertCount, setAlertCount] = React.useState();

    const pagination = usePagination( 
        getItems, 
        defaultPage, 
        defaultPageSize
    );

    const [selectedItem, setSelectedItem] = React.useState();
    const [modalVisible, setModalVisible] = React.useState();
    const [deleteModalVisible, setDeleteModalVisible] = React.useState();

    const Modal = modalClass;

    function updateAlert() {
        Promise.resolve(getAlertCount()).then(setAlertCount);
    }

    function refresh() {
        pagination.update()
    }
    
    const DeleteModal = ({
        item,
        ...props
    }) => (
        <ConfirmDeleteModal
            onDelete={() => deleteItem(item).then(refresh)}
            itemLabel={itemLabel}
            {...props} />  
    )

    const isTile = (view === "grid");
    const Container = (props) => isTile 
        ? <TileGrid maxColumns={maxColumns} {...props} /> 
        : <PlaceholderList 
            pageSize={pagination?.pageSize} 
            hidePlaceholder={hidePlaceholder}
            {...props} />

 
    const panelActions = []
    if (modalClass) {
        panelActions.push({
            onClick: () => {
                setSelectedItem(defaultModalValues);
                setModalVisible(true);
            },
            icon: <PlusIcon />,
            appearance: "primary" ,
            title: t('CreateOption')
        })
    }

    function getItemActions (item) {
        const itemActions = [];
            if (modalClass) {
                itemActions.push({
                    onClick: () => {
                        setSelectedItem(item);
                        setModalVisible(true);
                    },
                    icon: <EditIcon />,
                    appearance: "primary" ,
                    color: "yellow",
                    title: t('Edit')
                })
            }
            if (deleteItem) {
                itemActions.push({
                    onClick: () => {
                        setSelectedItem(item);
                        setDeleteModalVisible(true);
                    },
                    icon: <DeleteIcon />,
                    appearance: "primary" ,
                    color: "red",
                    title: t('Delete')
                })
            }
        return itemActions
    }   

    React.useImperativeHandle(ref, () => ({
        refresh: refresh
    }));

    React.useEffect(() => {
        if (pagination.numberOfPages < pagination.page) {
            pagination.setPage(pagination.numberOfPages)    
        }
    }, [pagination.numberOfPages])

    React.useEffect(() => {
        updateAlert()
    }, [pagination.data])

    return <>
        <Panel 
            className="paginatedPanel"
            header={ <PanelHeader
                title={title}
                icon={icon}
                actions={[...panelActions, ...actions]}
                toolbar={<CardPanelToolbar 
                    alertCount={alertCount}
                    alertIcon={alertIcon}
                    alertLabel={alertLabel}
                    alertAction={alertAction}
                    pagination={pagination}
                    pageSizeOptions={pageSizeOptions}
                    hidePagination={hidePagination}
                    showAllURL={showAllURL}
                    view={view}
                    setView={canSetView ? setView : undefined}
                />}
            />}
            {...props}
        >   
            { children && <div className="controls">
                {children}
            </div>}
            <Container 
                data={pagination?.data}
                renderItem={item => (
                    <Card 
                        tile={isTile}
                        icon={getIcon(item)}
                        iconBackgroundColor={getIconBackgroundColor(item)}
                        overlayIcon={getOverlayIcon(item)}
                        overlayHint={getOverlayHint(item)}
                        category={getCategory(item)}
                        title={getTitle(item)}
                        subtitle={getSubtitle(item)}
                        description={getDescription(item)}
                        height={isTile ? tileHeight : undefined}
                        imageURL={isTile ? getCoverURL(item) :  getThumbnailURL(item)}
                        imageHeight={isTile ? coverHeight : undefined}
                        actions={[...(getItemActions(item) || []), ...(getActions(item) || [])]}
                        disabled={getDisabled(item)}
                        onClick={() => onItemClick(item)}
                        usePlaceholder={!hideAvatar}
                        expandable={expandable}
                        onExpandChange={val => onItemExpand(item, val)}
                        {...cardProps}
                    >
                        {getChildren(item)}
                    </Card>
                )}
            />

            <div className="paginationContainer" >
                { !hidePagination && pagination.numberOfPages >= 2 && <>
                    <PaginationNav
                        page={pagination.page}
                        setPage={pagination.setPage}
                        pageSize={pagination.pageSize}
                        numberOfPages={pagination.numberOfPages} 
                    />
                </>}
            </div>

        </Panel>
        { modalClass && <Modal open={modalVisible} setOpen={setModalVisible} item={selectedItem} onClose={refresh}/>}
        { <DeleteModal open={deleteModalVisible} setOpen={setDeleteModalVisible} item={selectedItem} onClose={refresh}/>}
        
    </>;
});

export default CardPanel