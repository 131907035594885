import Point from "@arcgis/core/geometry/Point";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import * as geometryService from "@arcgis/core/rest/geometryService";
import esriConfig from "@arcgis/core/config";

// Set up the geometry service URL
esriConfig.geometryServiceUrl = "https://utility.arcgisonline.com/ArcGIS/rest/services/Geometry/GeometryServer";

class SpatialReferenceConverter {
  /**
   * Convert point between spatial references
   * @param {Object} point - The input point to convert
   * @param {number} inputWkid - Input spatial reference WKID
   * @param {number} outputWkid - Output spatial reference WKID
   * @returns {Promise} Promise resolving to converted point
   */
  static convertPoint(point, inputWkid, outputWkid) {
    return new Promise((resolve, reject) => {

      const inputPoint = new Point({
        x: point.x,
        y: point.y,
        spatialReference: new SpatialReference({ wkid: inputWkid })
      });

      const outputSpatialReference = new SpatialReference({ wkid: outputWkid });

      geometryService.project([inputPoint], outputSpatialReference)
        .then(result => {
          if (result && result.length > 0) {
            resolve({
              x: result[0].x,
              y: result[0].y,
              spatialReference: outputSpatialReference
            });
          } else {
            reject(new Error("No result returned from projection"));
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  /**
   * Convert multiple points between spatial references
   * @param {Array} points - Array of points to convert
   * @param {number} inputWkid - Input spatial reference WKID
   * @param {number} outputWkid - Output spatial reference WKID
   * @returns {Promise} Promise resolving to array of converted points
   */
  static convertPoints(points, inputWkid, outputWkid) {
    return new Promise((resolve, reject) => {

      const inputPoints = points.map(point => 
        new Point({
          x: point.x,
          y: point.y,
          spatialReference: new SpatialReference({ wkid: inputWkid })
        })
      );

      const outputSpatialReference = new SpatialReference({ wkid: outputWkid });

      geometryService.project(inputPoints, outputSpatialReference)
        .then(results => {
          const convertedPoints = results.map(result => ({
            x: result.x,
            y: result.y,
            spatialReference: outputSpatialReference
          }));
          resolve(convertedPoints);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}

export default SpatialReferenceConverter;
