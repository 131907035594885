import { noop } from 'utils/constants';
import { compareStrings } from 'utils/compare';
import TransportLine from './TransportLine';


function ServicePattern({
    id,
    lineId,
    lineType,
    lineName,
    routeName,
    name,
    traffic,
    operator,
}) {
    const servicePattern = {
        elementType: 'servicePattern',
        elementId: Number(id),
        id: Number(id),
        transportLine: TransportLine({
            id: Number(lineId),
            type: lineType,
            name: lineName,
            operator
        }),
        routeName,
        name,
        traffic,
        label: `${lineName} - ${routeName} - ${name}`,
        shortLabel: `${lineName} ${name}`,
        compare: noop,
    };

    servicePattern.compare = other => compareServicePatterns(servicePattern, other);

    return servicePattern
}


export function compareServicePatterns(a, b) {
    return a.transportLine.compare(b.transportLine) ||
        compareStrings(a.routeName, b.routeName) ||
        compareStrings(a.name, b.name) ||
        a.id - b.id
};


export default ServicePattern;
