import 'assets/css/layout.css'

import { AddressIcon, EmailIcon, HomeIcon, PhoneIcon } from 'components/icons'
import { t } from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Grid, Footer as RSFooter, Row } from 'rsuite'

const Footer = () => {
  return <RSFooter>
        <Grid fluid style={{padding: 20}}>
            <Row>
                <Col xs={24} lg={6}>
                    <img alt="footerImg" className="footerImg" src='/oneplanet_portal_logo.svg' /> 
                </Col>
                <Col xs={24} lg={6} style={{textAlign: 'center'}}>
                    <p style={{fontWeight: 'bold'}}>{t('Support')}:</p>
                    {/* <EmailIcon />  */}
                    <a href="mailto:support@oneplanet.hu">support@oneplanet.hu</a><br/>
                    {/* <AddressIcon /> 1094 Budapest, Bokréta utca 24-26.<br/> */}
                    {/* <PhoneIcon /> +36 30 347 6290 */}
                </Col>
                <Col xs={24} lg={6} style={{textAlign: 'center'}}>
                    {/* <a href="/docs/faq.html">{t('FAQ')}</a> <br/> */}
                    <a href="/docs/impress.html">{t('Impress')}</a><br/>
                </Col>
                <Col xs={24} lg={6} style={{textAlign: 'center'}}>
                    <a href="/docs/ula.html">{t('UserAgreement')}</a> <br/>
                    <a href="/docs/privacy_policy.html">{t('PrivacyPolicy')}</a> <br/>
                </Col>
            </Row>
        </Grid>
    </RSFooter>
}

export default Footer