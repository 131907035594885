import React from "react";
import { Col, Grid, Row } from "rsuite";

import CollectionPanel from "../components/CollectionPanel";
import SidebarPage from "layouts/SidebarPage";
import { t } from "i18next";
import PillInput from "components/inputs/PillInput";


function CollectionListPage () {

    const [ sort, setSort ] = React.useState('name');

    const sortData = [{
        label: t('ByName'),
        value: 'name'
    }, {
        label: t('LastViewed'),
        value: 'lastViewed'
    }, {
        label: t('LastCreated'),
        value: 'lastCreated'
    }, {
        label: t('MostViewed'),
        value: 'mostViewed'
    }]

    return (
        <SidebarPage activeKey="collections">
            <Grid className="mainGrid">
                <Row>
                    <Col xs={24} >
                        <CollectionPanel 
                            canSetView 
                            sort={sort}
                            hidePlaceholder
                            defaultView="grid"
                            maxColumns={4}
                        >
                            <PillInput 
                                size="sm"
                                data={sortData}
                                value={sort}
                                onChange={setSort}
                            />
                        </CollectionPanel>
                    </Col>
                </Row>
            </Grid>  
        </SidebarPage>
    )
};


export default CollectionListPage;
