import { AngleLeft, AngleRight } from 'components/icons';
import React from 'react'
import { IconButton } from 'rsuite';
import { noop } from 'utils/constants';


const SidenavToggle = ({
    expanded = false,
    setExpanded = noop,
    children,
    ...props
}) => {
    return (
        <div className='sideNavToggle rs-sidenav-toggle' {...props}>
            {expanded && <div className="label">{children}</div>}
            <IconButton 
                className="rs-sidenav-toggle-button"
                appearance='link'
                icon={expanded ? <AngleLeft /> : <AngleRight />}
                onClick={() => setExpanded(!expanded)} />
        </div>
    )

}

export default SidenavToggle;