import React from 'react'
import SimpleModal from './SimpleModal'
import { Button, ButtonToolbar } from 'rsuite'
import { t } from 'i18next';
import { noop } from 'utils/constants';

const ConfirmModal = ({
    open,
    setOpen = noop,
    title,
    size="sm",
    yesLabel = t('Yes'),
    noLabel = t('No'),
    cancelLabel = t('Cancel'),
    showCancel,
    onYes = noop,
    onNo = noop,
    onClose = noop,
    children,
}) => {
    function handleYes() {
        Promise.resolve(onYes()).finally(onClose)
        setOpen(false);
    }

    function handleNo() {
        Promise.resolve(onNo()).finally(onClose)
        setOpen(false);
    }

    function handleCancel() {
        onClose();
        setOpen(false);
    }

    return (
        <SimpleModal
            title={title}
            size={size}
            open={open}
            setOpen={setOpen}
            onClose={onClose}
        >
            <div style={{paddingBottom: 10}}>
                {children}
            </div>
            <ButtonToolbar style={{justifyContent: 'end'}}>
                <Button appearance='primary' onClick={handleYes}>
                    { yesLabel }
                </Button>
                <Button appearance='subtle' onClick={handleNo}>
                    {noLabel}
                </Button>
                { showCancel && (
                    <Button appearance='subtle' onClick={handleCancel}>
                        {cancelLabel}
                    </Button>
                )}
            </ButtonToolbar>

        </SimpleModal>
    )
}

export default ConfirmModal