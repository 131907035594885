import * as geometryService from "@arcgis/core/rest/geometryService";

export const EOV = {
    wkid: 23700
};
export const WEB_MERCATOR = {
    wkid: 102100,
    latestWkid: 3857
};

export async function projectGeometries (server =  "https://sampleserver6.arcgisonline.com", spatialReference, ...geometries) {
    if (!geometries.length) {return geometries}
    const url = `${server}/arcgis/rest/services/Utilities/Geometry/GeometryServer`;

    const inSpatialReference = geometries?.[0]?.spatialReference;

    if (spatialReference?.wkid === inSpatialReference?.wkid) {
        return Promise.resolve(geometries)
    }

    const projectProperties = {
        geometries,
        outSpatialReference: spatialReference,
        transformForward: true
    };

    console.log({
        url,
        projectProperties
    })

    return geometryService.project(url, projectProperties)
        .catch((err) => {
            console.error(err)
            return geometries
        });
}