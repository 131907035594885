import Area from './Area'
import { noop } from 'utils/constants'
import { compareStrings } from 'utils/compare';


function StopPlace({
    id,
    name,
    areaId,
    areaName,
    x,
    y,
    transportLineIds,
    servicePatternIds,
    nextStopPlaceIds,
    traffic,
    transportLines = {},
    servicePatterns = {},
    stopPlaces = {}
}) {
    const stopPlace = {
        elementType: 'stopPlace',
        elementId: Number(id),
        id: Number(id),
        name,
        area: Area({id: areaId, name: areaName}),
        areaId,
        geometry: {x, y},
        transportLineIds,
        servicePatternIds,
        nextStopPlaceIds,
        transportLines: Object.values(transportLines).filter(transportLine => transportLineIds.includes(transportLine.id)),
        servicePatterns: Object.values(servicePatterns).filter(servicePattern => servicePatternIds.includes(servicePattern.id)),
        nextStopPlaces: Object.values(stopPlaces).filter(stopPlace => nextStopPlaceIds.includes(stopPlace.id)),
        traffic,
        compare: noop,
    };

    stopPlace.compare = other => compareStopPlaces(stopPlace, other);

  return stopPlace;
};


export function compareStopPlaces(a, b) {
    return compareStrings(a.name, b.name) || 
        a.id - b.id;
};


export default StopPlace;
