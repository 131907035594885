import { InfoIcon } from 'components/icons'
import { t } from 'i18next'
import React from 'react'


const LegendCell = ({
    className,
    value,
}) => !value ? <></> : (
    <div className={`rs-table-cell ${className}`} >
        <div className="rs-table-cell-content">
            {value}
        </div>
    </div>
)


const DataTableLegend = ({
    rowHighlightLegend,
    rowInnerHighlightLegend,
    rowHighlightColor,
    colHighlightLegend,
    colInnerHighlightLegend,
    colHighlightColor,
}) => {

    return (rowHighlightLegend || rowInnerHighlightLegend || colHighlightLegend || colInnerHighlightLegend)  &&  <div className="tableLegend">
        <div className='title'>
            <InfoIcon style={{marginRight: 4}}/>
            {t('Map/Legend')}:
        </div>
        <LegendCell 
            className={`highlighted bg-${rowHighlightColor}`}
            value={rowHighlightLegend}
        />
        <LegendCell 
            className={`innerHighlighted text-${rowHighlightColor}`}
            value={rowInnerHighlightLegend}
        />
        <LegendCell 
            className={`highlighted bg-${colHighlightColor}`}
            value={colHighlightLegend}
        />
        <LegendCell 
            className={`innerHighlighted text-${colHighlightColor}`}
            value={colInnerHighlightLegend}
        />
    </div>
}

export default DataTableLegend