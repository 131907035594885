import React from 'react';


function usePagination( getItems, defaultPage=1, defaultPageSize=10 ) {
    const [data, setData] = React.useState();
    const [page, setPage] = React.useState(defaultPage);
    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [numberOfPages, setNumberOfPages] = React.useState();

    async function update() {
        return getItems({page, pageSize}).then(({data, numberOfPages}) => {
            setData(data);
            setNumberOfPages(numberOfPages);
        });
    }
    
    React.useEffect(() => {
        update();
    }, [getItems, page, pageSize]);

    return {
        data,
        page,
        setPage,
        pageSize,
        setPageSize,
        numberOfPages,
        update,
    };

};


export default usePagination;
