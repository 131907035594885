import React from "react";
import { useParams } from "react-router-dom";
import { Col, Grid, Row } from "rsuite";

import Jumbotron from "components/pages/Jumbotron";
import PageTitle from "components/pages/PageTitle";
import { CollectionPanel, CollectionTilesPanel } from "features/collection";
import SimplePage from "layouts/SimplePage";

import useOrganization from "../hooks/useOrganization";
import SidebarPage from "layouts/SidebarPage";

function OrganizationPage () {

    const { organizationId } = useParams();
    const organization = useOrganization(organizationId);

    return (
        <SidebarPage activeKey="organizations">
            

            <Grid className="mainGrid">
                <Jumbotron backgroundUrl={organization?.coverUrl} >
                    <PageTitle item={organization} />
                </Jumbotron>
                <Row>
                    <Col xs={24} >
                        { organization?.id && (
                            <CollectionPanel 
                                organizationId={organization?.id} 
                                defaultView="grid"
                                canSetView
                            />
                        ) }
                        
                    </Col>
                </Row>
            </Grid>  

        </SidebarPage>
    )
};


export default OrganizationPage;
