import React from 'react'
import { CheckPicker, Checkbox, Col, DateInput, DatePicker, DateRangePicker, Form, Row, SelectPicker } from 'rsuite'
import { t } from 'i18next'

import FormGroup from 'components/forms/FormGroup'
import { MONTH_INTERVAL, TIMETABLE_INTERVAL, WEEK_INTERVAL, COUNT_INTERVAL_TYPE_LABELS, COUNT_INTERVAL_TYPES, DATE_INTERVAL } from 'features/onboardCount/utils/constants'
import { isNextDay, secondsFromDate, secondsToDate } from 'features/onboardCount/utils/time'
import CountInterval, { getAvailableCountIntervalIds } from 'features/onboardCount/elements/CountInterval'
import { noop } from 'utils/constants'
import { pickerOptions } from 'utils/forms'
import { calendarLocale } from 'utils/rsuite_localization'
import { formatDate } from 'utils/format'


const OnboardCountFormFields = ({
    dayTypes = {},
    countIntervals = {},
    values = {},
    onChange = noop,
}) => {

    const disabledCountIntervalTypes = [];
    const weekIntervals = Object.values(countIntervals)
        .filter(interval => interval?.type === WEEK_INTERVAL);
    const monthIntervals = Object.values(countIntervals)
        .filter(interval => interval?.type === MONTH_INTERVAL);

    let timetableIntervalIds = [];
    Object.values(countIntervals)
        .filter(interval => interval?.type === TIMETABLE_INTERVAL)
        .map(interval => CountInterval({ ...interval, dayTypeId: undefined }))
        .forEach(interval => {
            if (!timetableIntervalIds.includes(interval.str)) {
                timetableIntervalIds.push(interval.str)
            }
        });
    const timetableIntervals = timetableIntervalIds.map(id => CountInterval(id));

    if (!weekIntervals.length) disabledCountIntervalTypes.push(WEEK_INTERVAL);
    if (!monthIntervals.length) disabledCountIntervalTypes.push(MONTH_INTERVAL);
    if (!timetableIntervals.length) disabledCountIntervalTypes.push(TIMETABLE_INTERVAL);

    const firstInterval = Object.values(countIntervals)[0];

    const firstYear = firstInterval?.year || firstInterval?.startDate?.getFullYear();
    const lastYear = new Date().getFullYear();

    const years = [];
    for (let year = firstYear; year <= lastYear; year++) {
        years.push(year);
    };

    const defaultInterval = weekIntervals.length ? weekIntervals[weekIntervals.length - 1] : (
        monthIntervals.length ? monthIntervals[monthIntervals.length - 1] :
            timetableIntervals.length ? timetableIntervals[timetableIntervals.length - 1] :
                undefined
    );

    const [countIntervalType, setCountIntervalType] = React.useState(values?.countIntervalType || DATE_INTERVAL);
    const [countIntervalPrompt, setCountIntervalPrompt] = React.useState();

    const [countDate, setCountDate] = React.useState(values?.countDate || defaultInterval?.startDate);
    const [startDate, setStartDate] = React.useState(values?.startDate || defaultInterval?.startDate);
    const [endDate, setEndDate] = React.useState(values?.endDate || defaultInterval?.endDate);

    const [timeSeries, setTimeSeries] = React.useState(values?.timeSeries || false);
    const [year, setYear] = React.useState();

    const [dayTypeIds, setDayTypeIds] = React.useState(values?.dayTypeIds || [defaultInterval?.dayTypeId]);

    const [startTime, setStartTime] = React.useState(values?.startTime);
    const [startTimeDate, setStartTimeDate] = React.useState(secondsToDate(values?.startTime));
    const [nextDayStart, setNextDayStart] = React.useState(isNextDay(values?.startTime));
    const [endTime, setEndTime] = React.useState(values?.endTime);
    const [endTimeDate, setEndTimeDate] = React.useState(secondsToDate(values?.endTime));
    const [nextDayEnd, setNextDayEnd] = React.useState(isNextDay(values?.endTime));

    const countIntervalValue = [startDate, endDate];

    let selectedCountInterval = CountInterval(values?.countInterval);
    const availableIntervals = getAvailableCountIntervalIds(countIntervals, {
        countIntervalType,
        startDate,
        endDate,
        timeSeries,
        dayTypeIds
    });
    if (!availableIntervals.includes(selectedCountInterval?.str)) {
        selectedCountInterval = CountInterval(availableIntervals[0])
    }

    function setYearCheckbox(value) {
        if (value) {
            setYear(lastYear);

        } else {
            setYear();
        }
    };

    const onCountIntervalOpen = () => {
        setCountIntervalPrompt(
            t(`FT/Select${timeSeries ? 'First' : ''}${countIntervalType === WEEK_INTERVAL ? 'Week' : 'Month'}Prompt`)
        );
    };

    const onCountIntervalChange = (value) => {
        const [startDate, endDate] = value;
        setStartDate(startDate);
        setEndDate(endDate);
        if (timeSeries) {
            setCountIntervalPrompt(
                t(`FT/SelectLast${countIntervalType === WEEK_INTERVAL ? 'Week' : 'Month'}Prompt`)
            );
        }
    };

    const onCountIntervalClose = () => {
        setCountIntervalPrompt();
    };

    const onCountIntervalSelect = (value) => {
        if (timeSeries) {
            if (startDate && !endDate) {
                setEndDate(value);
            } else {
                setStartDate(value);
                setEndDate(undefined);
            }
            switch (countIntervalPrompt) {
                case t('FT/SelectFirstMonthPrompt'):
                    setCountIntervalPrompt(t('FT/SelectLastMonthPrompt'));
                    break;

                case t('FT/SelectFirstWeekPrompt'):
                    setCountIntervalPrompt(t('FT/SelectLastWeekPrompt'));
                    break;

                case t('FT/SelectLastMonthPrompt'):
                    setCountIntervalPrompt(t('FT/SelectFirstMonthPrompt'));
                    break;

                case t('FT/SelectLastWeekPrompt'):
                    setCountIntervalPrompt(t('FT/SelectFirstWeekPrompt'));
                    break;
            }
        }
    }

    function disableDate(d) {
        let intervals;
        switch(countIntervalType) {
            case WEEK_INTERVAL:
                intervals = weekIntervals;
                break;
            case MONTH_INTERVAL:
                intervals = monthIntervals;
                break;
            case TIMETABLE_INTERVAL:
                intervals = timetableIntervals;
                break;
            default:
                intervals = Object.values(countIntervals)
        }

        return !intervals.find(i => i.startDate <= d && i.endDate >= d)
    }

    React.useEffect(() => {
        onChange(countIntervalType === DATE_INTERVAL ? {
            countDate,
            countIntervalType,
            startTime,
            endTime,
            countInterval:  undefined,
            startDate: undefined,
            endDate: undefined,
            timeSeries: undefined,
            dayTypeIds: undefined,
        }: {
            countInterval: selectedCountInterval?.str,
            countIntervalType,
            startDate,
            endDate,
            timeSeries,
            dayTypeIds,
            startTime,
            endTime,
            countDate: undefined,
        })
    }, [countDate, countIntervalType, startDate, endDate, timeSeries, year, dayTypeIds, startTime, endTime])

    React.useEffect(() => {
        if (!year) {
            setStartDate(defaultInterval?.startDate);
            setEndDate(defaultInterval?.endDate);
            setDayTypeIds([defaultInterval?.dayTypeId]);
        } else {
            setStartDate(new Date(year, 0, 1));
            setEndDate(new Date(year, 11, 31, 23, 59, 59));
            setDayTypeIds([defaultInterval?.dayTypeId]);
        }
    }, [year])

    React.useEffect(() => {
        const dayTypeList = Object.values(dayTypes).sortBy();
        if (dayTypeList?.length && !dayTypeIds?.length) {
            setDayTypeIds([dayTypeList[0].id]);
        }
    }, [dayTypes, dayTypeIds])

    React.useEffect(() => {
        setStartTime(secondsFromDate(startTimeDate, nextDayStart));
    }, [startTimeDate, nextDayStart])

    React.useEffect(() => {
        setEndTime(secondsFromDate(endTimeDate, nextDayEnd));
    }, [endTimeDate, nextDayEnd])

    React.useEffect(() => {
        if (countIntervalType) {
            let interval = undefined;
            let intervalList;
            switch (countIntervalType) {
                case WEEK_INTERVAL:
                    intervalList = weekIntervals;
                    break;
                case MONTH_INTERVAL:
                    intervalList = monthIntervals;
                    break;
                case TIMETABLE_INTERVAL:
                    intervalList = timetableIntervals;
                    break;
                case DATE_INTERVAL:
                    intervalList = [];
                    setTimeSeries(false);
                    break;
                default:
                    throw `Invalid interval type: ${countIntervalType}!`;
            };

            for (let i = 0; i < intervalList.length; i++) {
                interval = intervalList[i];
                if (
                    startDate &&
                    endDate &&
                    formatDate(interval.startDate) === formatDate(startDate) &&
                    formatDate(interval.endDate) === formatDate(endDate)
                ) {
                    return;
                }
            }


            if (interval) {
                setStartDate(interval.startDate);
                setEndDate(interval.endDate);
            }

        }
    }, [countIntervalType])

    React.useEffect(() => {
        setStartDate(values?.startDate || defaultInterval?.startDate);
    }, [values?.startDate])

    React.useEffect(() => {
        setEndDate(values?.endDate || defaultInterval?.endDate);
    }, [values?.endDate])

    React.useEffect(() => {
        setCountDate(values?.countDate || defaultInterval?.startDate);
    }, [values?.countDate])

    React.useEffect(() => {
        setDayTypeIds(values?.dayTypeIds?.length ? values?.dayTypeIds : [Object.values(dayTypes || []).sortBy()[0]?.id]);
    }, [`${values?.dayTypeIds}`])

    React.useEffect(() => {
        setStartTime(values?.startTime);
        setStartTimeDate(secondsToDate(values?.startTime))
        setNextDayStart(isNextDay(values?.startTime))
    }, [values?.startTime])

    React.useEffect(() => {
        setEndTime(values?.endTime);
        setEndTimeDate(secondsToDate(values?.endTime))
        setNextDayEnd(isNextDay(values?.endTime))
    }, [values?.endTime])

    return <>

        <FormGroup
            name='countIntervalType'
            label={t('FT/QueryType')}
            accepter={SelectPicker}
            searchable={false}
            cleanable={false}
            data={pickerOptions(COUNT_INTERVAL_TYPES)}
            disabledItemValues={disabledCountIntervalTypes}
            value={countIntervalType}
            onChange={setCountIntervalType}
        >
            {[WEEK_INTERVAL, MONTH_INTERVAL, TIMETABLE_INTERVAL].includes(countIntervalType) && (
                <Checkbox
                    defaultChecked={timeSeries}
                    value={timeSeries}
                    onChange={() => setTimeSeries(!timeSeries)}
                >
                    {t('FT/TimeSeriesQuery')}
                </Checkbox>
            )}

            {timeSeries && (
                <Checkbox
                    defaultChecked={Boolean(year)}
                    value={Boolean(year)}
                    onChange={(value, checked) => setYearCheckbox(checked)}
                >
                    {t('FT/QueryFullYear')}
                </Checkbox>

            )}
        </FormGroup>

        {([WEEK_INTERVAL, MONTH_INTERVAL].includes(countIntervalType) || timeSeries) && !year && (
            <FormGroup
                name="countInterval"
                label={timeSeries ? t("FT/TimeSeriesInterval") : COUNT_INTERVAL_TYPE_LABELS?.[countIntervalType]}
                help={timeSeries ?
                    t(`FT/CountIntervalTimeSeriesPrompt`) :
                    t('FT/CountIntervalValuePrompt')}
                accepter={DateRangePicker}
                // locale={calendarLocale}
                placeholder={countIntervalType === WEEK_INTERVAL ? t('FT/SelectWeek') : t('FT/SelectMonth')}
                cleanable={false}
                shouldDisableDate={disableDate}
                hoverRange={!timeSeries ? countIntervalType : undefined}
                oneTap={!timeSeries}
                showOneCalendar={!timeSeries}
                isoWeek
                showWeekNumbers={countIntervalType === 'week'}
                ranges={[]}
                character="-"
                format="yyyy.MM.dd"
                value={countIntervalValue}
                onChange={onCountIntervalChange}
                onSelect={onCountIntervalSelect}
                onEntered={onCountIntervalOpen}
                onClose={onCountIntervalClose}
            />
        )}

        {countIntervalType === TIMETABLE_INTERVAL && !timeSeries && (
            <FormGroup
                name='countInterval'
                label={t('FT/SelectCountInterval')}
                help={t('FT/CountIntervalPrompt')}
                accepter={SelectPicker}
                placeholder={t('FT/SelectInterval')}
                cleanable={false}
                data={timetableIntervals.map(interval => ({
                    label: interval.label,
                    value: interval.str
                }))}
                value={startDate && CountInterval({ startDate, endDate }).str}
                onChange={value => {
                    const interval = CountInterval(value);
                    setStartDate(interval?.startDate);
                    setEndDate(interval?.endDate);
                }}
            />
        )}

        {countIntervalType === DATE_INTERVAL && !year && (

            <FormGroup
                name='countDate'
                label={t('FT/CountDate')}
                help={t('FT/CountDatePrompt')}
                accepter={DatePicker}
                shouldDisableDate={disableDate}
                cleanable={false}
                oneTap={true}
                isoWeek
                showWeekNumbers
                ranges={[]}
                format="yyyy.MM.dd"
                value={countDate}
                onChange={setCountDate}
            />
        )}

        {year && (
            <FormGroup
                name='countYear'
                label={t('FT/SelectCalendarYear')}
                help={t(`FT/CountYearPrompt${countIntervalType === MONTH_INTERVAL ? 'Monthly' : 'Weekly'}`)}
                accepter={SelectPicker}
                cleanable={false}
                searchable={false}
                data={years.map(year => ({
                    label: `${year}`,
                    value: year
                }))}
                value={year}
                onChange={setYear}
            />
        )}

        { countIntervalType !== DATE_INTERVAL && <FormGroup
            name='dayType'
            label={t('FT/DayType')}
            help={t('FT/DayTypePrompt')}
            accepter={timeSeries ? CheckPicker : SelectPicker}
            searchable={false}
            cleanable={false}
            data={Object.values(dayTypes).sortBy().map(dayType => ({
                label: dayType.name,
                value: dayType.id,
            }))}
            value={timeSeries ? dayTypeIds : dayTypeIds?.[0]}
            onChange={timeSeries ? setDayTypeIds : value => setDayTypeIds([value])}
        /> }

        <FormGroup
            name="interval"
            label={t('FT/Interval')}
            help={t('FT/IntervalPrompt')}
        >
            <Row>
                <Col xs={12}>
                    <Form.Control
                        name="intervalStart"
                        placeholder={t('Start')}
                        accepter={DateInput}
                        format="HH:mm"
                        value={startTimeDate}
                        onChange={setStartTimeDate} />

                    <Checkbox
                        defaultChecked={nextDayStart}
                        value={nextDayStart}
                        onChange={() => setNextDayStart(!nextDayStart)}
                    >
                        {t('FT/NextDay')}
                    </Checkbox>
                </Col>
                <Col xs={12}>
                    <Form.Control
                        name="intervalEnd"
                        placeholder={t('End')}
                        accepter={DateInput}
                        format="HH:mm"
                        value={endTimeDate}
                        onChange={setEndTimeDate} />

                    <Checkbox
                        defaultChecked={nextDayEnd}
                        value={nextDayEnd}
                        onChange={() => setNextDayEnd(!nextDayEnd)}
                    >
                        {t('FT/NextDay')}
                    </Checkbox>
                </Col>
            </Row>
        </FormGroup>
    </>
};


export default OnboardCountFormFields;
