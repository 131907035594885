import React from 'react'
import FormModal from 'components/modals/FormModal'
import { t } from 'i18next'
import BlogEntryForm from '../forms/BlogEntryForm'
import { createBlogEntry, postBlogEntry } from '../blogAPI'
import { useMediaQuery } from 'rsuite'
import { noop } from 'utils/constants'

const BlogEntryModal = ({
    announcement,
    open,
    setOpen,
    onClose = noop,
    item,
}) => {
    
    const [isMobile] = useMediaQuery('xs');

    if (open) {
        console.log(item)
    }
   
    const title = announcement
        ? (
            item?.id ? t('EditAnnouncement') : t('CreateAnnouncement')
        ) : (
            item?.id ? t('EditBlogEntry') : t('CreateBlogEntry')
        )

    return (
        <FormModal
            title={title}
            size="lg"
            layout={isMobile ? "vertical" : "horizontal"}
            open={open}
            setOpen={setOpen}
            FormClass={BlogEntryForm}
            formData={{...item, announcement}}
            onSubmit={data => item?.id
                    ? postBlogEntry(item.id, data)
                    : createBlogEntry(data)
            }
            onClose={onClose}
        />
    )
}

export default BlogEntryModal