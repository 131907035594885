export const secondsToString = ( value ) => {
    if (value === undefined) {return}
    var measuredTime = new Date(null);
    measuredTime.setSeconds(value);
    return measuredTime.toISOString().substring(11, 16);
}

export const weekOfYear = (d) => {
    var date = new Date(d.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                          - 3 + (week1.getDay() + 6) % 7) / 7);
}

export const weekOfYearRange = (y, w) => {
    var date = new Date(y, 0, 4);
    date.setDate(date.getDate() + 7*(w-1));

    var dayOfWeek = date.getDay() || 7;

    date.setDate(date.getDate() - dayOfWeek + 1)
    var endDate = new Date(date.getTime())
    endDate.setDate(date.getDate() + 6)

    return [date, endDate]
}

export const monthOfYearRange = (y, m) => {
    var date = new Date(y, m-1, 1);
    var endDate = new Date(y, m, 1);
    endDate.setDate(endDate.getDate() - 1);

    return [date, endDate]
}


export const secondsToDate = ( value ) => {
    if (value === undefined || value === null ) {
        return
    }
    let t = new Date(1970, 0, 1); // Epoch
    t.setSeconds(value);
    return t;
}


export const dateToSeconds = ( value, nextDay ) => {
    if (value === undefined || value === null) {
        return
    }
    return value.getHours() % 24 * 3600 + value.getMinutes() * 60 + value.getSeconds() + (nextDay ? 86400 : 0);
}

export const urlToCSSImage = ( url ) => {
    return url?.startsWith('data') || url?.startsWith('http') ? url : encodeURIComponent(url).replaceAll('%2F', '/');
}
