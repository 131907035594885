import { formatDate } from "./format";

export const apiURL = '/api'

class APIError {
    constructor(status, message) {
        this.status = status
        this.message = message
    }
};

function toJSONobject( obj ) {
    switch (typeof obj) {
        case 'undefined':
            return undefined;

        case 'function':
            return toJSONobject( obj() );

        case 'symbol':
            throw Error('Unsupported type: symbol!');

        case 'object':
            if (obj === null) {
                return undefined;
            }

            if (Array.isArray(obj)) {
                return obj.map(toJSONobject);
            }

            if (obj instanceof Date) {
                return formatDate(obj, {
                    date: true,
                    time: obj.getHours() && obj.getMinutes() && obj.getSeconds() && obj.getMilliseconds()
                })
            }

            return Object.fromEntries(
                Object.entries(obj).map(
                    ([name, value]) => [name, toJSONobject(value)]
                )
            );

        default:
            return obj;
    }
}


function toQueryString ( args = {} ) {
    const params = Object.entries(toJSONobject(args)).map(([name, value]) => {
        let returnValue;
        switch (typeof value) {
            case 'object':
                if (Array.isArray(value)) {
                    returnValue = value.join(',')
                }
                else {
                    return undefined;
                }
                break;
            case 'boolean':
                returnValue =  value ? 'true' : '';
                break;
            case 'function':
            case 'symbol':
            case 'undefined':
                return undefined
            default:
                returnValue = value;
        }

        return `${name}=${returnValue}`;
    }).filter(p => p);

    if (params.length) {
        return '?' + params.join('&');
    } else {
        return ''
    }
};


async function processResponse (response) {
    if (response.status >= 200 && response.status < 300) {
        return response.json();
    } else {
        return response.json()
            .then(resp => {
                throw new APIError(resp.status, resp.message)
            }).catch(err => {
                throw new APIError(response.status, err?.message || err)
            });
    }
};

export async function getAPI (path, args = {}, signal) {
    var url = `${apiURL}/${path}${toQueryString(args)}`;

    const fetchOptions = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
        },
        signal: signal,
    };

    const r = await fetch(url, fetchOptions);

    return await processResponse(r);
}

export async function postAPI (path, data={}, signal) {
    const fetchOptions = {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(toJSONobject(data)),
        signal: signal,
    };

    const r = await fetch(`${apiURL}/${path}`, fetchOptions);

    return await processResponse(r);
}

export async function putAPI (path, data={}, signal) {
    const fetchOptions = {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(toJSONobject(data)),
        signal: signal,
    };

    const r = await fetch(`${apiURL}/${path}`, fetchOptions);

    return await processResponse(r);
}

export async function deleteAPI (path, signal) {
    const fetchOptions = {
        method: 'DELETE',
        signal: signal,
    };

    const r = await fetch(`${apiURL}/${path}`, fetchOptions);

    return await processResponse(r);
}
