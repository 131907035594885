import React from "react";
import { useParams } from "react-router-dom";
import { Col, Grid, Row } from "rsuite";

import Jumbotron from "components/pages/Jumbotron";
import PageTitle from "components/pages/PageTitle";

import useCollection from "../hooks/useCollection";
import WebMapPanel from "features/webmap/components/WebMapPanel";
import SidebarPage from "layouts/SidebarPage";
import { t } from "i18next";
import { getSubtitleFromMetadata } from "utils/elements";


function CollectionPage () {

    const { collectionId } = useParams();
    const collection = useCollection(collectionId);

    return (
        <SidebarPage activeKey="collections">

            <Grid className="mainGrid">
            
                <Jumbotron backgroundUrl={collection?.coverUrl} >
                    <PageTitle item={collection} subtitle={getSubtitleFromMetadata(collection)}/>
                </Jumbotron>
                <Row>
                    <Col xs={24} >
                        { collection?.id && <WebMapPanel canSetView defaultView="grid" collectionId={collection?.id} /> }
                    </Col>
                </Row>
            </Grid>  

        </SidebarPage>
    )
};


export default CollectionPage;
