import { t } from "i18next";
import { formatString } from "utils/format";

export function getChartLabels (module) {

    const {
        area,
        stopPlace,
        section,
        servicePatterns = [],
        transportLines = [],
        countInterval,
    } = module?.selectedElements || {};

    let selectionTitle = '';

    if (area) {
        selectionTitle = '\n' + formatString(t('FT/InArea'), area);
    }

    if (stopPlace) {
        selectionTitle = '\n' + formatString(t('FT/InStopPlace'), stopPlace);
    }

    if (section?.id) {
        selectionTitle = '\n' + formatString(t('FT/InSection'), {
            name: section?.name || section.id
        });
    }

    let servicePatternTitle = '';

    if (transportLines.length) {
        servicePatternTitle = `\n(${transportLines.map(transportLine => transportLine?.name).join(', ')})`;
    }
    if (servicePatterns.length) {
        servicePatternTitle = `\n(${servicePatterns.map(servicePattern => `${servicePattern?.transportLine?.name} - ${servicePattern?.name}`).join(', ')})`;
    }

    let countIntervalTitle = '';
    if (countInterval) {
        countIntervalTitle = `\n${countInterval?.intervalStr || countInterval?.label || 0}`
    }

    return {
        selection: selectionTitle,
        servicePatterns: servicePatternTitle,
        countInterval: countIntervalTitle
    }
}