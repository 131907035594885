import { deleteAPI, getAPI, postAPI } from "utils/api"


export const getWebMapData = (webMapId) => getAPI(`webmaps/${webMapId}`)

export const postWebMapData = (webMapId, data) => postAPI(`webmaps/${webMapId}`, data)

export const createWebMap = (data) => postAPI(`webmaps/create`, data)

export const deleteWebMap = (webMapId) => deleteAPI(`webmaps/${webMapId}`)

export const getWebMapList = ({ 
    name, 
    organizationId, 
    collectionId,
    page,
    pageSize,
    sort,
} = {}) => {
    return getAPI('webmaps', {
        name,
        organization: organizationId,
        collection: collectionId,
        page, 
        pageSize,
        sort,
    })
}

export const postUserConfig = (webMapId, data) => postAPI(`webmaps/${webMapId}/user_config`, data);

export const postElementData = (elementId, data) => postAPI(`elements/${elementId}`, data)

export const postNewElement = (data) => postAPI(`elements`, data)

export const deleteElement = (elementId) => deleteAPI(`elements/${elementId}`)

export const postSubLayerData = (subLayerId, data) => postAPI(`sublayers/${subLayerId}`, data)

export const getServices = () => getAPI('services')

export const postConfigData = (configId, data) => postAPI(`webmapconfigs/${configId}`, data)

export const postConfigItemData = (itemId, data) => postAPI(`webmapconfigitems/${itemId}`, data)

export const postNewConfig = (data) => postAPI(`webmapconfigs`, data)

export const deleteConfig = (configId) => deleteAPI(`webmapconfigs/${configId}`)

export const postNewConfigItem = (data) => postAPI(`webmapconfigitems`, data)

export const deleteConfigItem = (configId) => deleteAPI(`webmapconfigitems/${configId}`)

export const postOnboardCount = (webMapId, data) => postAPI(`webmaps/${webMapId}/onboardcount`, data);

export const deleteOnboardCount = (webMapId) => deleteAPI(`webmaps/${webMapId}/onboardcount`);

export const postCrossSectionRoadCount = (webMapId, data) => postAPI(`webmaps/${webMapId}/crosssectionroadcount`, data);

export const deleteCrossSectionRoadCount = (webMapId, data) => deleteAPI(`webmaps/${webMapId}/crosssectionroadcount`);
