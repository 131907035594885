import React, { 
    forwardRef, 
    useImperativeHandle, 
    useMemo, 
    useRef, 
    useCallback 
  } from 'react';
  import { Table } from 'rsuite';
  import { FixedSizeList as List } from 'react-window';
  import AutoSizer from 'react-virtualized-auto-sizer';
  
  const VirtualizedTable = forwardRef((props, ref) => {
    const {
      data = [],
      columns = [],
      columnWidth = 200,
      minColumnWidth = 60,
      columnResizable = true,
      children,
      ...restProps
    } = props;
  
    const tableRef = useRef(null);
    const listRef = useRef(null);
  
    // Extract and separate props
    const extractTableProps = (props) => {
      const {
        data,
        columns = [],
        height,
        width,
        rowHeight,
        headerHeight,
        rowKey,
        virtualized,
        showHeader,
        showRowSelection,
        onRowClick,
        onRowContextMenu,
        onSortColumn,
        sortColumn,
        sortType,
        bordered,
        cellBordered,
        hover,
        wordWrap,
        rowClassName,
        loading,
        loadAnimation,
        locale,
        className,
        style,
        children = [],
        ...otherProps
      } = props;
  
      return {
        tableProps: {
          data,
          height,
          width,
          rowHeight,
          headerHeight,
          rowKey,
          virtualized: true, // Always virtualized
          showHeader,
          showRowSelection,
          onRowClick,
          onRowContextMenu,
          onSortColumn,
          sortColumn,
          sortType,
          bordered,
          cellBordered,
          hover,
          wordWrap,
          rowClassName,
          loading,
          loadAnimation,
          locale,
          className,
          style,
          children: [...columns, ...children]
        },
        otherProps
      };
    };
  
    const { tableProps, otherProps } = extractTableProps(restProps); 
  
    // Memoize columns with additional virtualization properties
    const virtualizedColumns = useMemo(() => 
      columns.map((col, index) => ({
        ...col,
        width: col.width || columnWidth,
        flexGrow: col.flexGrow || 0,
        resizable: columnResizable,
        columnIndex: index
      })), 
      [columns, columnWidth, columnResizable]
    );
  
    // Imperative handle to expose Table methods
    useImperativeHandle(ref, () => ({
      ...tableRef.current,
      
      scrollToColumn: (columnIndex) => {
        if (listRef.current) {
          listRef.current.scrollToItem(columnIndex);
        }
      }
    }));
  
    // Virtualized Column Renderer
    const VirtualizedColumnRenderer = useCallback(({ index, style }) => {
      const column = virtualizedColumns[index];
      console.log(column)
      
      return (
        <Table.Column
          key={column.dataKey || `column-${index}`}
          {...column}
          width={style.width}
          style={{
            ...style,
            position: 'absolute',
            left: style.left
          }}
        >
          {column.headerRenderer ? (
            column.headerRenderer(column)
          ) : (
            <Table.HeaderCell>{column.title}</Table.HeaderCell>
          )}
          
          {column.cellRenderer ? (
            column.cellRenderer
          ) : (
            <Table.Cell dataKey={column.dataKey} />
          )}
        </Table.Column>
      );
    }, [virtualizedColumns]);
  
    // Compute total column width for horizontal scrolling
    const totalColumnWidth = useMemo(() => 
      virtualizedColumns.reduce((sum, col) => sum + (col.width || columnWidth), 0),
      [virtualizedColumns, columnWidth]
    );
  
    return (
      <div 
        {...otherProps} 
        style={{ 
          width: tableProps.width || '100%', 
          height: tableProps.height || 400,
          ...otherProps.style 
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <Table
              ref={tableRef}
              {...tableProps}
              height={height}
              width={width}
              data={data}
            >
              <List
                ref={listRef}
                height={height}
                itemCount={virtualizedColumns.length}
                itemSize={col => col.width || columnWidth}
                width={width}
                layout="horizontal"
              >
                {VirtualizedColumnRenderer}
              </List>
            </Table>
          )}
        </AutoSizer>
      </div>
    );
  });
  
  VirtualizedTable.displayName = 'VirtualizedTable';
  
  export default VirtualizedTable;