import { CrossSectionRoadCountIcon, IntervalIcon, LocationIcon, OnboardCountIcon } from "components/icons";
import { t } from "i18next";

export function getIntervalFromMetadata(item) {
    if (item?.startDate) {
        let startDate = item.startDate.replaceAll('-', '.')
        if (item?.endDate) {
            let endDate = item.endDate.replaceAll('-', '.')

            if (startDate.slice(5) === '01.01' && endDate.slice(5) === '12.31') {
                startDate = startDate.slice(0, 4);
                endDate = endDate.slice(0, 4);
            }
            
            if (startDate === endDate) {
                return startDate
            }

            if (startDate.slice(0, 4) === endDate.slice(0, 4)) {
                endDate = endDate.slice(5);
                if (startDate.slice(5, 7) === endDate.slice(0, 2)) {
                    endDate = endDate.slice(3);
                }
            }

            return `${startDate}-${endDate}`
        } else {
            return startDate
        }
    }
}


export function getSubtitleFromMetadata(item) {
    const values = [];

    item?.modules?.map(m => {
        switch (m) {
            case 'onboardCount':
                values.push(<>
                    <OnboardCountIcon style={{marginRight: 4}} />
                    {t('Map/OnboardCount')}
                </>)
                break;
            case 'crossSectionRoadCount':
                values.push(<>
                    <CrossSectionRoadCountIcon style={{marginRight: 4}} />
                    {t('Map/CrossSectionRoadCount')}
                </>)
                break;
        }
    })

    if (item?.location) {
        values.push(<>
            <LocationIcon style={{marginRight: 4}} />
            {item.location}
        </>)
    }

    const interval = getIntervalFromMetadata(item)
    if (interval) {
        values.push(<>
            <IntervalIcon style={{marginRight: 4}} />
            {interval}
        </>)
    }

    return values
}