import React from "react";
import { Col, Grid, Row } from "rsuite";

import Jumbotron from "components/pages/Jumbotron";
import PageTitle from "components/pages/PageTitle";

import { t } from "i18next";
import SubscriptionListPanel from "../components/SubscriptionListPanel";
import SidebarPage from "layouts/SidebarPage";


function SubscriptionPage () {

    return (
        <SidebarPage activeKey="subscriptions">
            
            <Jumbotron >
                <PageTitle item={{title: t('MySubscriptions')}} />
            </Jumbotron>

            <Grid className="mainGrid">
                <Row>
                    <Col xs={24} >
                        <SubscriptionListPanel valid={true} defaultPageSize={5}/>
                    </Col>
                    <Col xs={24} >
                        <SubscriptionListPanel valid={false} defaultPageSize={5}/>
                    </Col>
                </Row>
            </Grid>  

        </SidebarPage>
    )
};


export default SubscriptionPage;
