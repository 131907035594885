import React from 'react'
import AreaCard from './AreaCard'
import JourneyCard from './JourneyCard'
import JourneyStopCard from './JourneyStopCard'
import SectionCard from './SectionCard'
import ServicePatternCard from './ServicePatternCard'
import TransportLineCard from './TransportLineCard'
import StopPlaceCard from './StopPlaceCard'
import { Area, StopPlace } from 'features/onboardCount/elements'

const ElementCard = ({
    item = {},
    elements = {},
    ...props
}) => {
    switch( item?.elementType ) {
        case 'area':
            const area = Area({...elements?.areas?.[item.elementId], ...elements})
            return <AreaCard {...area} {...props} />
        case 'journey':
            return <JourneyCard {...item} {...props} />
        case 'journeyStop':
            return <JourneyStopCard {...item} {...props} />
        case 'section':
            return <SectionCard {...item} {...props} />
        case 'servicePattern':
            return <ServicePatternCard {...item} {...props} />
        case 'stopPlace':
            const stopPlace = StopPlace({...elements?.stopPlaces?.[item?.elementId], ...elements})
            return <StopPlaceCard {...stopPlace} {...props} />
        case 'transportLine':
            return <TransportLineCard {...item} {...props} />
    }
}

export default ElementCard