import React from "react";
import { urlToCSSImage } from "utils/convert";

const Jumbotron = ({
    title,
    subtitle,
    children,
    backgroundUrl = '/img/Hero.png',
    ...props
}) => {
    return (
        <div 
            className='jumbotron' 
            style={{
                backgroundImage: `url(${urlToCSSImage(backgroundUrl)})`,
            }}
            {...props}
        >
            <h1>{title}</h1>
            <h6>{subtitle}</h6> 
            
            <div className='jumbotronContent'>
                {children}
            </div>
        </div>
    )
}

export default Jumbotron;