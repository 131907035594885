import { CrossSectionRoadCountContext } from 'features/crossSectionRoadCount';
import { OnboardCountContext } from 'features/onboardCount';
import React from 'react'
import { Input, Message } from 'rsuite';
import ModuleTutorial from '../ModuleTutorial';


export const ModuleFilter = React.forwardRef(({
    icon,
    name,
    label,
    acceptor = Input,
    help,
    instructionText,
    children,
    className,
    ...props
}, ref) => {
    const Control = acceptor;

    const onboardCount = React.useContext(OnboardCountContext);
    const crossSectionRoadCount = React.useContext(CrossSectionRoadCountContext);

    const module = onboardCount?.id ? onboardCount : crossSectionRoadCount;

    let instructions = instructionText;
    if (module?.highlightedFilter === 'name') {
        instructions = module?.highlightInstructions;
    }    

    return (
        <div className={`moduleFilter ${className}${ instructionText ? ' highlight' : ''}`}>
            <div className="label">

                {icon ? <>
                    <div className="icon">
                        {icon}
                    </div>
                </> : <></>}

                {label ? <>
                    <div className="name" title={help}>
                        {label}
                    </div>
                </> : <></>}

            </div>
            <div className="input">
                <Control 
                    ref={ref}
                    {...props}
                    style={{
                        ...(props?.style || {}),
                        width: '100%'
                    }}
                >
                    {children}
                </Control>
            </div>
            { instructions && (
                <div className="instructions">
                    <ModuleTutorial step={module?.tutorialStep} total={module?.tutorialSteps} onChange={module?.setTutorialStep}>
                        {instructions}
                    </ModuleTutorial>

                </div>
            )}
        </div>
    );
});


export default ModuleFilter;
