import React from 'react';
import SelectFeatureForm from '../forms/SelectFeatureForm';
import { IconButton } from 'rsuite';
import { CloseIcon } from 'components/icons';


const ModuleSidebar = React.forwardRef(({
    module,
    children,
}, ref) => {
    const {
        map,
        getLabel,
        getElementType,
        renderItem,
    } = module;

    const selection = map?.selection?.[map?.selectionIndex];

    const elementType = (typeof getElementType === 'function') ? getElementType(selection?.attributes) : undefined;

    return (
        <div ref={ref} className="moduleSidebar">
            <div className="header">
                <SelectFeatureForm 
                    map={map} 
                    getLabel={getLabel} 
                    renderItem={renderItem} 
                    elementType={elementType}
                />

            </div>
            <div className="content">
                {children}
            </div>
        </div>
    );
});


export default ModuleSidebar;
