import 'assets/css/carousel.css';
import ActionButton from 'components/cards/ActionButton';
import ActionToolbar from 'components/cards/ActionToolbar';
import React from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { urlToCSSImage } from 'utils/convert'

const CarouselPage = ({
    title,
    subtitle,
    content,
    linkUrl,
    backgroundUrl = "/img/Hero.png",
    actions = [],
    ...props
}) => {
    const navigate = useNavigate();
    return (
        <div
            className="CarouselPage"
            {...props}
            style={{
                backgroundImage: `url(${urlToCSSImage(backgroundUrl)})`,
                height: '100%',
                cursor: linkUrl ? 'pointer' : 'default',
                ...(props?.style || {})
            }}
            onClick={() => navigate(linkUrl)}
        >
            <div className="body">
                <div className="content"> 
                    <div className="title">
                        {title}

                        <ActionToolbar>
                            {actions?.map(action => <ActionButton {...action} />)}
                        </ActionToolbar>
                    </div>
                    {subtitle && (
                        <div className="subtitle">
                            {subtitle}
                        </div>
                    )}
                    <div dangerouslySetInnerHTML={{ __html: content }} />

                </div>
            </div>
        </div>
    )
}

export default CarouselPage