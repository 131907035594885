import { BarChartIcon, MapIcon, TableIcon } from 'components/icons';
import SideNavMenu from 'components/pages/SideNavMenu';
import SidenavToggle from 'components/pages/SideNavToggle';
import MediaQueryContext from 'context/MediaQueryContext';
import { CrossSectionRoadCountContext } from 'features/crossSectionRoadCount';
import { OnboardCountContext } from 'features/onboardCount';
import { t } from 'i18next';
import React from 'react'
import { Nav, Sidenav } from 'rsuite';


const ModuleSidenav = ({
    width,
    expanded,
    setExpanded,
    ...props
}) => {
    const onboardCount = React.useContext(OnboardCountContext);
    const crossSectionRoadCount = React.useContext(CrossSectionRoadCountContext);
    const { isMobile} = React.useContext(MediaQueryContext);

    const module = onboardCount ? onboardCount : crossSectionRoadCount;
    if (!module) return;

    return (
        <Sidenav className={`sideNav${isMobile ? ' mobile' : ''}`} expanded={expanded} appearance="subtle" {...props} style={{width, ...(props?.style || {})}}>
            <Sidenav.Body>
                { !isMobile && (
                    <SidenavToggle
                        expanded={expanded}
                        setExpanded={setExpanded}
                        style={{border: 'none'}}
                    >
                        {t('Map/Navigation')}
                    </SidenavToggle>
                )}
                <Nav className="menu">
                    <SideNavMenu 
                        className={'mapMenu'}
                        title={t('Map')}
                        view={'map'}
                        icon={<MapIcon/>}
                        module={module}
                    />
                    <SideNavMenu 
                        className={'tableMenu'}
                        title={t('Tables')}
                        view={'table'}
                        icon={<TableIcon/>}
                        navs={module?.tableMenu?.navs}
                        module={module}
                    />
                    <SideNavMenu 
                        className={'chartMenu'}
                        title={t('Charts')}
                        view={'chart'}
                        icon={<BarChartIcon/>}
                        navs={module?.chartMenu?.navs}
                        module={module} 
                    />
                </Nav>
            </Sidenav.Body>
    
        </Sidenav>
    )
}

export default ModuleSidenav