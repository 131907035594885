import { t } from 'i18next'
import React from 'react'
import { getIntervalFromMetadata } from 'utils/elements';

const WebMapHeader = ({
    webmap,
    extraInfo = [],
}) => {
    if (!webmap?.id) return;

    const info = [...extraInfo];
    if (webmap?.startDate) {
        info.splice(0, 0, [t('Map/Interval'), getIntervalFromMetadata(webmap)]);
    }

    if (webmap?.location) {
        info.splice(0, 0, [t('Map/Location'), webmap.location]);
    }
    
    return (
        <div className="webmapHeader">
            <div className="info title">
                <div className="label">
                    { t('Map/MapTitle') }
                </div>
                <div className="value">
                    { `${webmap?.name}${webmap?.isDemo ? ' ' + t('Map/Demo') : ''}` }
                </div>
            </div>
            { info.map(([label, value]) => (
                <div className="info">
                    <div className="label">
                        { label }
                    </div>
                    <div className="value">
                        { value }
                    </div>
                </div>
            ))}
            
        </div>
    )
}

export default WebMapHeader