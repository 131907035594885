import 'assets/css/panel.css'
import ActionButton from 'components/cards/ActionButton';
import ActionToolbar from 'components/cards/ActionToolbar';

import React from "react";


const PanelHeader = ({
    size,
    icon,
    title,
    toolbar,
    actions,
}) => {
    return (
        <div className={`panelHeader ${size}`}>
            <div className="title">
                <span className="icon">{icon}</span>
                {title}
            </div>
            <div className="actions">
                <ActionToolbar>
                    { actions?.map(action => <ActionButton {...action} />)}
                </ActionToolbar>
            </div>
            <div className="toolbar">
                {toolbar}
            </div>
        </div>
    )
};


export default PanelHeader;
