import React from 'react';
import { t } from "i18next";

import { FutureValidIcon, PastValidIcon, SubscriptionIcon, ValidIcon } from 'components/icons';
import CardPanel from 'components/panels/CardPanel';
import { getSubscriptionList } from '../subscriptionAPI';
import { formatDate } from 'utils/format';

const SubscriptionsPanel = ({
    valid,
    past,
    future,
    defaultPageSize,
    pageSizeOptions,
    ...props
}) => {
    
    const panelRef = React.useRef();

    function getItems ({page, pageSize}) {
        return getSubscriptionList({
            valid,
            past,
            future,
            page,
            pageSize,
        });
    }

    function isPast(item) {
        const validUntil = new Date(item?.validUntil);
        const now = new Date();

        return now > validUntil
    }

    function isFuture(item) {
        const validFrom = new Date(item?.validFrom);
        const now = new Date();

        return now < validFrom
    }

    return (
        <CardPanel 
            ref={panelRef}
            title={t('MySubscriptions')}
            icon={ <SubscriptionIcon /> }

            getItems={getItems}
            getTitle={item => {
                const color = isPast(item) 
                    ? 'var(--rs-color-red)'
                    : isFuture(item)
                        ? 'var(--rs-text-secondary)'
                        : 'var(--rs-color-green)'
                return <span style={{color}}>
                    {item.name}{item?.organization ? ` (${item.organization})` : ''}
                </span>
            }}
            getSubtitle={item => <>
                {t('ValidUntil')}: {formatDate(new Date(item.validUntil), {dateSeparator: '.'})}
                
            </>}
            getIcon={item => isPast(item)
                    ? <PastValidIcon style={{color: 'var(--rs-color-red)'}}/>
                    : isFuture(item) 
                        ? <FutureValidIcon style={{color: 'var(--rs-text-secondary)'}}/>
                        : <ValidIcon style={{color: 'var(--rs-color-green)'}}/> }
            getCategory={() => "subscription"}
            hideAvatar
            
            defaultPageSize={defaultPageSize}
            pageSizeOptions={pageSizeOptions}

            {...props}
        />
    )
};


export default SubscriptionsPanel;
