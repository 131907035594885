import { secondsToString } from 'features/onboardCount/utils/time'
import React from 'react'

const TimeBadge = ({
    size='md',
    icon,
    start,
    end,
    ...props
}) => {
    return (
        <div className={`timeBadge ${size}`} {...props}>
            <div className="icon">
                {icon}
            </div>
            <div className="value">
                { start !== undefined && secondsToString(start) }
                { start !== undefined && end !== undefined && start !== end && '-'}
                { end !== undefined && secondsToString(end) }
            </div>
        </div>
    )
}

export default TimeBadge