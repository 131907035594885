import React from 'react';
import TimeBadge from './TimeBadge';
import { StopPlaceIcon } from '../icons';


const StopTimeBadge = ({
    size = 'md',
    arrivalTime,
    departureTime,
    ...props
}) => {
    return (
        <TimeBadge 
            size={size}
            icon={<StopPlaceIcon />}
            start={arrivalTime}
            end={arrivalTime !== departureTime ? departureTime : undefined}
            {...props}
        />
    );
};


export default StopTimeBadge;
