export function getTransportLineFilters(selectedRows = [], servicePatterns = {}) {
        const servicePatternIds = selectedRows.filter(key => key.startsWith('servicePattern')).map(key => Number(key.split('-')[1]));
        const transportLineIds = selectedRows.filter(key => key.startsWith('transportLine')).map(key => Number(key.split('-')[1]));

        const newFilters = {
            servicePatternIds: [],
            transportLineIds: [],
        };

        if (servicePatternIds.length && transportLineIds.length) {
            transportLineIds.map(transportLineId => {
                const selectedServicePatterns = Object.values(servicePatterns).filter(servicePattern => servicePattern.transportLine.id === transportLineId);
                servicePatternIds.push(...selectedServicePatterns.map(servicePattern => servicePattern.id));
            });
            transportLineIds.length = 0;
        }

        if (servicePatternIds.length) {
            newFilters.servicePatternIds = servicePatternIds
        } else if (transportLineIds.length) {
            newFilters.transportLineIds = transportLineIds
        }

        return newFilters
}


export function getSelectedRows(transportLineIds = [], servicePatternIds = [], servicePatterns = {}) {
    if (!servicePatternIds.length) {
        const transportLineServicePatterns = !transportLineIds.length ? [] : Object.values(servicePatterns).filter(s => transportLineIds.includes(s?.transportLine?.id))
        return transportLineServicePatterns.map(s => `servicePattern-${s.id}`);
        return transportLineIds.map(id => `transportLine-${id}`)   
    } else {
        return servicePatternIds.map(id => `servicePattern-${id}`)
        const transportLineServicePatterns = !transportLineIds.length ? [] : Object.values(servicePatterns).filter(s => transportLineIds.includes(s?.transportLine?.id))
        return [
            ...transportLineServicePatterns.map(s => s.id),
            ...servicePatternIds
        ].map(id => `servicePattern-${id}`)
    }
}


export function getStopPlaceFilters(selectedRows = []) {
    const newFilters = {
        stopPlaceId: undefined,
        areaId: undefined,
    }

    const key = selectedRows?.[0];
    if (key) {
        const [ type, idStr ] = key.split('-');
        newFilters[`${type}Id`] = Number(idStr); 
    }

    return newFilters
}