import React from 'react'
import { t } from 'i18next';

import ModuleTablePanel from 'features/webmapModule/components/table/ModuleTablePanel';
import { CrossSectionRoadCountContext } from 'features/crossSectionRoadCount/context';
import { MONTHLY_TRAFFIC, MONTHLY_TRAFFIC_BY_YEAR, TRAFFIC_BY_DAY_TYPE, TRAFFIC_BY_MONTH, YEARLY_TRAFFIC_BY_YEAR } from 'features/crossSectionRoadCount/utils/constants';
import { MonthlyTrafficTable, YearlyTrafficTable } from '.';
import { Message } from 'rsuite';
import MonthlyIntervalForm from '../forms/MonthlyIntervalForm';
import { getPanelContentHeight } from 'utils/style';

const CrossSectionRoadCountTablePanel = () => {

    const count = React.useContext(CrossSectionRoadCountContext);
    const [tableHeight, setTableHeight] = React.useState(0);
    const containerRef = React.useCallback(container => {
        if (container) {
            setTableHeight(getPanelContentHeight(container, 40))
        }
    });

    if (!count?.filters?.stationCode) return;

    const menu = count?.tableMenu;

    return (
        <ModuleTablePanel
            ref={containerRef}
            module={count}
            title={t('OKKF/StationTraffic')}
        >
            { [YEARLY_TRAFFIC_BY_YEAR, MONTHLY_TRAFFIC_BY_YEAR].includes(menu?.value) && <>
                <div style={{padding: 10}}>
                    <Message showIcon type="info" >
                            {t('OKKF/VehicleClasses2023Message')}
                    </Message>
                </div>
            </> }

            { menu?.value === YEARLY_TRAFFIC_BY_YEAR && <>
                <YearlyTrafficTable                 
                    ref={count?.table}
                    height={tableHeight}
                />
            </> }

            { menu?.value === MONTHLY_TRAFFIC && <>
                <MonthlyTrafficTable             
                    ref={count?.table}
                    height={tableHeight}
                />
            </> }

            { menu?.value === MONTHLY_TRAFFIC_BY_YEAR && <>
                <div style={{padding: 4}}>
                    <MonthlyIntervalForm layout="inline" />
                </div>
                { count?.filters?.stationMonth && count?.filters?.stationDayTypeId ? (
                    <MonthlyTrafficTable
                        month={count?.filters?.stationMonth}  
                        dayTypeId={count?.filters?.stationDayTypeId}  
                        ref={count?.table}
                        height={tableHeight}
                    />
                ) : (
                    <Message type="warning">
                        {t('OKKF/SelectMonthlyView')}
                    </Message>
                )}
            </> }

            { menu?.value === TRAFFIC_BY_MONTH && <>
                <MonthlyTrafficTable
                    month={count?.filters?.stationMonth}  
                    ref={count?.table}
                    height={tableHeight}
                />
            </> }

            { menu?.value === TRAFFIC_BY_DAY_TYPE && <>
                <MonthlyTrafficTable
                    dayTypeId={count?.filters?.stationDayTypeId}  
                    ref={count?.table}
                    height={tableHeight}
                />
            </> }


        </ModuleTablePanel>
    )
}

export default CrossSectionRoadCountTablePanel