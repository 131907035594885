import React from 'react';
import { t } from "i18next";

import { deleteWebMap, getWebMapList } from "../webmapAPI";
import WebMapModal from "./WebMapModal";
import { AuthContext } from 'features/auth';
import { MaintenanceIcon, MapIcon } from 'components/icons';
import CardPanel from 'components/panels/CardPanel';
import { useNavigate } from 'react-router-dom';
import { getSubtitleFromMetadata } from 'utils/elements';


const WebMapPanel = ({
    organizationId,
    collectionId,
    sort,
    showAll,
    tileHeight,
    imgHeight,
    ...props
}) => {

    const { adminVisible } = React.useContext(AuthContext);
    const navigate = useNavigate();

    function getItems ({page, pageSize}) {
        return getWebMapList({
            organizationId,
            collectionId,
            page,
            pageSize,
            sort,
        });
    }

    function deleteItem (item) {
        return deleteWebMap(item?.id)
    }

    return (
        <CardPanel
            title={t('WebMaps')}
            itemLabel={t('WebMap')}
            icon={<MapIcon />}
            showAllURL={showAll && "/webmaps"}

            getItems={getItems}
            deleteItem={adminVisible && deleteItem}

            getDisabled={item => item?.maintenance}
            getOverlayIcon={item => item?.maintenance ? <MaintenanceIcon /> : undefined}
            getOverlayHint={item => item?.maintenance ? t('Map/UnderMaintenance') : undefined}

            getSubtitle={getSubtitleFromMetadata}
            getCoverURL={item => item?.thumbnailUrl}
            onItemClick={item => navigate(`/webmaps/${item.id}`)}
            cardProps={{
                icon: <MapIcon />,
                category: 'webmap',
                size: 'lg',
                expandAvatar: true
            }}

            modalClass={adminVisible && WebMapModal}

            coverHeight={imgHeight}

            {...props}
        />
    )
};


export default WebMapPanel;
