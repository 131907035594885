import React from 'react'
import { t } from 'i18next';
import useMenu from 'features/webmapModule/hooks/useMenu';
import { MonthIcon, StationIcon, WeekIcon, YearIcon } from '../compononets/icons';
import { MONTHLY_TRAFFIC, MONTHLY_TRAFFIC_BY_YEAR, STATION_TRAFFIC, TRAFFIC_BY_DAY_TYPE, TRAFFIC_BY_MONTH, YEARLY_TRAFFIC_BY_YEAR } from '../utils/constants';
import { formatString } from 'utils/format';
import { noop } from 'utils/constants';

function useCrossSectionRoadCountMenu(onSelect = noop) {

    const tableMenuData = [{
        key: STATION_TRAFFIC, 
        icon: <StationIcon/>,
        label: t('OKKF/StationTraffic'),
        abbrev: t('OKKF/StationTrafficAbbrev'),
        disabled: module => !Boolean(module?.filters?.stationCode),
        children: [{
            key: YEARLY_TRAFFIC_BY_YEAR,
            icon: <YearIcon />,
            label: t('OKKF/AnnualAverageDailyTrafficTimeSeries'),
            disabled: module => !Boolean(module?.filters?.stationCode),
            title: module => !Boolean(module?.filters?.stationCode)
                ? t('OKKF/SelectStationForPivot')
                : undefined
        }, {
            key: MONTHLY_TRAFFIC,
            icon: <MonthIcon />,
            label: module => formatString(t('OKKF/MonthlyAverageDailyTrafficForYear'), {year: module?.filters?.stationYear}),
            abbrev: t('OKKF/MonthlyAverageDailyTraffic'),
            disabled: module => !Boolean(module?.filters?.stationCode),
            title: module => !Boolean(module?.filters?.stationCode)
                ? t('OKKF/SelectStationForPivot')
                : undefined
        },{
            key: MONTHLY_TRAFFIC_BY_YEAR,
            icon: <MonthIcon />,
            label: t('OKKF/MonthlyAverageDailyTrafficTimeSeries'),
            disabled: module => !Boolean(module?.filters?.stationMonth),
            title: module => !Boolean(module?.filters?.stationCode)
                ? t('OKKF/SelectStationForPivot')
                : !Boolean(module?.filters?.stationMonth)
                    ? t('OKKF/SelectMonthlyViewForPivot')
                    : undefined
        }],
    }];

    const chartMenuData = [{
        key: STATION_TRAFFIC, 
        icon: <StationIcon/>,
        label: t('OKKF/StationTraffic'),
        abbrev: t('OKKF/StationTrafficAbbrev'),
        disabled: module => !Boolean(module?.filters?.stationMonth),
        title: module => !Boolean(module?.filters?.stationCode)
            ? t('OKKF/SelectStationForPivot')
            : !Boolean(module?.filters?.stationMonth)
                ? t('OKKF/SelectMonthlyViewForPivot')
                : undefined,
        children: [{
            key: TRAFFIC_BY_MONTH,
            icon: <YearIcon />,
            label: t('OKKF/TrafficByMonth'),
            disabled: module => !Boolean(module?.filters?.stationMonth),
            title: module => !Boolean(module?.filters?.stationCode)
                ? t('OKKF/SelectStationForPivot')
                : !Boolean(module?.filters?.stationMonth)
                    ? t('OKKF/SelectMonthlyViewForPivot')
                    : undefined,
        }, {
            key: TRAFFIC_BY_DAY_TYPE,
            icon: <WeekIcon />,
            label: t('OKKF/TrafficByDayType'),
            disabled: module => !Boolean(module?.filters?.stationMonth),
            title: module => !Boolean(module?.filters?.stationCode)
                ? t('OKKF/SelectStationForPivot')
                : !Boolean(module?.filters?.stationMonth)
                    ? t('OKKF/SelectMonthlyViewForPivot')
                    : undefined,
        }],
    }];

    const tableMenu = useMenu({
        data: tableMenuData,
        defaultValues: YEARLY_TRAFFIC_BY_YEAR,
        onSelect,
    });

    const chartMenu = useMenu({
        data: chartMenuData,
        defaultValues: TRAFFIC_BY_MONTH,
        onSelect
    });

    return {
        tableMenu,
        chartMenu
    };
};

export default useCrossSectionRoadCountMenu