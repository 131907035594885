import React from 'react'
import CountIntervalCellContent, { countIntervalCellCSV } from './cells/CountIntervalCellContent'
import { t } from 'i18next'
import DataTable from 'features/dataTable/components/DataTable'
import JourneyCard from '../cards/JourneyCard'
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import JourneyStopCard from '../cards/JourneyStopCard'
import { secondsToString } from 'features/onboardCount/utils/time'
import { getMeasuredCountIntervalIds } from 'features/onboardCount/elements/CountInterval'


function getColumnTypes (countIntervals = [], dayTypes = {}) {
    return [{
        name: 'countInterval',
        label: t('FT/CountInterval'),
        selectable: true,
        values: countIntervals.map(key => ({
            key,
            label: <CountIntervalCellContent value={key} />,
            csvLabel: countIntervalCellCSV({value: key,  dayTypes}),
        }))
    }, {
        name: 'traffic',
        label: t('FT/TrafficData'),
        values: [{
            key: 'alight',
            label: t('FT/AlightCount'),
            abbrev: t('FT/Alight')
        }, {
            key: 'board',
            label: t('FT/BoardCount'),
            abbrev: t('FT/Board')
        }, {
            key: 'onward',
            label: 'Keresztmetszeti forgalom',
            abbrev: 'Forgalom'
        }]
    }, {
        name: 'stat',
        label: t('FT/StatType'),
        values: [{
            key: 'avg',
            label: t('FT/Average'),
            abbrev: t('FT/Avg'),
            sortable: true,
        }]
    }]
}


const JourneyTrafficTable = React.forwardRef(({
    ...props
}, ref) => {

    const count = React.useContext(OnboardCountContext);
    
    const {
        journeys = {},
        journeyStops = {},
        dayTypes = {},
    } = count?.elements || {};

    const {
        areaId,
        stopPlaceId,
        sectionId,
        journeyId,
    } = count?.filters || {};

    const selectedRows = journeyId ? [`journey-${journeyId}`] : [];

    const visibleColumns = {
        traffic: sectionId ? ['onward'] : areaId || stopPlaceId ? ['alight', 'board'] : ['board'],
    };

    const data = !areaId && !stopPlaceId ? Object.values(journeys) : Object.values(journeyStops).filter(journeyStop => {
        if (areaId && areaId !== journeyStop?.stopPlace?.areaId) {
            return false;
        }

        if (stopPlaceId && stopPlaceId !== journeyStop?.stopPlace?.id) {
            return false;
        }

        return true;
    });
    const countIntervals = getMeasuredCountIntervalIds(data, true);
    const columnTypes = getColumnTypes(countIntervals, dayTypes);

    function handleSelectRow( values ) {
        const newFilters = {
            journeyId: undefined
        }

        if (values?.[0]) {     
            const [type, idStr] = values[0].split('-');
            newFilters[`${type}Id`] = Number(idStr);
        }

        count?.applyFilters(newFilters);
    }

    return (
        <DataTable
            ref={ref}
            title={t('FT/JourneyTraffic')}

            hover
            bordered
            cellBordered
            columnWidth={120}

            data={data}
            columnTypes={columnTypes}
            dataAttribute='traffic'
            visibleColumns={visibleColumns}

            isTree
            getRowKey={row => `journey-${row?.journeyId || row?.elementId}`}

            onSelectRow={handleSelectRow}
            selectedRows={selectedRows}

            renderRowHeader={(rowData, csv) => {
                if (csv) {
                    return rowData?.id
                }
                switch (rowData?.elementType) {
                    case 'journey':
                        return <JourneyCard size='xs' className={'inline'} {...rowData} />
                    case 'journeyStop':
                        return <JourneyStopCard size='xs' className={'inline'} {...rowData} />
                    }
                }
            }
            csvExtraColumns={[{
                label: t('FT/TransportLine'),
                getValue: row => row?.servicePattern?.transportLine?.name
            }, {
                label: t('FT/Route'),
                getValue: row => row?.servicePattern?.routeName,
            }, {
                label: t('FT/ServicePattern'),
                getValue: row => row?.servicePattern?.name,
            }, {
                label: t('FT/StartTime'),
                getValue: row => secondsToString(row?.startTime),
            }]}

            {...props}
        />
    )
});

export default JourneyTrafficTable