import CarouselPanel from 'components/carousel/CarouselPanel'
import React from 'react'
import BlogEntryModal from './BlogEntryModal'
import { AuthContext } from 'features/auth';
import { deleteBlogEntry, getBlogEntryList } from '../blogAPI';
import ConfirmDeleteModal from 'components/modals/ConfirmDeleteModal';
import { t } from 'i18next';

const AnnouncementCarousel = props => {
    const { adminVisible } = React.useContext(AuthContext);

    async function getItems () {
        return getBlogEntryList({
            unread: true,
            featured: true,
        }).then(({data}) => data)
    }
    
    function deleteItem (item) { 
        return deleteBlogEntry(item?.id)
    }

    const AnnouncementModal = props => <BlogEntryModal announcement {...props} />

    return (
        <CarouselPanel
            welcome
            itemLabel={t('Announcement')}
            getItems={getItems}
            deleteItem={adminVisible && deleteItem}
            modalClass={adminVisible && AnnouncementModal}
            defaultModalValues={{
                category: 'announcement'
            }}
            endpoint="announcements"
            {...props}
            />
    )
}

export default AnnouncementCarousel