import { AdminIcon, AnnouncementIcon, BlogIcon, CollectionIcon, HomeIcon, LogoutIcon, MapIcon, ProfileIcon, RegisterUserIcon, UserIcon } from 'components/icons';
import MediaQueryContext from 'context/MediaQueryContext';
import { AuthContext } from 'features/auth';
import { t } from 'i18next';
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider, Nav, Sidenav, Toggle } from 'rsuite'
import { useMediaQuery } from 'rsuite/esm/useMediaQuery/useMediaQuery';
import { openInNewTab } from 'utils/browser';

const SideNavBar = ({
    activeKey,
    width,
    ...props
}) => {
    const navigate = useNavigate();
    const { user, adminVisible, isAdmin, logout, showAdminTools } = React.useContext(AuthContext);
    const {isMobile} = React.useContext(MediaQueryContext);

    return <Sidenav className={`sideNavBar${isMobile ? ' mobile' : ''}`} expanded appearance="subtle" {...props} style={{ width, ...(props?.style || {}) }}>
        <Nav className="menu" activeKey={activeKey}>
            <Nav.Item eventKey={"home"} icon={<HomeIcon />} onClick={() => navigate('/')}>
                {t('Home')}
            </Nav.Item>
            <Nav.Item eventKey={"collections"} icon={<CollectionIcon />} onClick={() => navigate('/collections')}>
                {t('MyCollections')}
            </Nav.Item>
            <Nav.Item eventKey={"webmaps"} icon={<MapIcon />} onClick={() => navigate('/webmaps')}>
                {t('MyWebMaps')}
            </Nav.Item>
            {!user?.blogActive ? <></> : <>
                <Nav.Item eventKey={"blog"} icon={<BlogIcon />} onClick={() => navigate('/blog')}>
                    {t('Blog')}
                </Nav.Item>
                <Nav.Item eventKey={"announcements"} icon={<AnnouncementIcon />} onClick={() => navigate('/announcements')}>
                    {t('Blog/Announcements')}
                </Nav.Item>
            </>}

            <div style={{ flexGrow: 1 }} />

            <Nav.Item panel>
                <div className='header'>
                    <ProfileIcon style={{marginRight: 4}} />
                    <div className='title'>
                        {user?.username}
                    </div>
                    { isAdmin && (
                        <Toggle 
                            title={adminVisible ? t('Navigation/HideAdminTools') : t('Navigation/ShowAdminTools')}
                            checkedChildren={<AdminIcon />}
                            unCheckedChildren={<AdminIcon />}
                            checked={adminVisible} 
                            onChange={showAdminTools} />
                    )}
                </div>
            </Nav.Item>

            <Nav.Item
                eventKey='profile'
                icon={<UserIcon />}
                onClick={() => navigate('/profile')}
            >
                {t('Profile/UserAccount')}
            </Nav.Item>

            {isAdmin && adminVisible && <>
                <Nav.Item
                    eventKey='addUser'
                    icon={<RegisterUserIcon />}
                    onClick={() => navigate('/register_other')}
                >
                    {t('Security/AddUser')}
                </Nav.Item>
                <Nav.Item icon={<AdminIcon />} onClick={() => openInNewTab('/admin')}>
                    {t('Navigation/Admin')}
                </Nav.Item>
            </>}

            <Nav.Item
                eventKey='logout'
                icon={<LogoutIcon />}
                onClick={() => logout().then(() => navigate('/'))}
            >
                {t('Navigation/Logout')}
            </Nav.Item>
        </Nav>
    </Sidenav>
}

export default SideNavBar