import { AvatarCard } from "components/cards";
import Card from "components/cards/Card";


const PageTitle = ({
    item,
    title,
    subtitle,
}) => {
    return <Card 
        className="pageTitle"
        size='lg'
        imageURL={item?.thumbnailUrl}
        title={title ||item?.title || item?.name}
        subtitle={subtitle || item ?.subtitle}
    />
};


export default PageTitle;
