import React from 'react'
import { AlightBoardIcon, BoardIcon, BoardTrafficIcon, LineTrafficIcon, OnwardIcon, SectionIcon, ServicePatternIcon, StopPlaceIcon, TrafficDistributionIcon } from '../components/icons';
import { t } from 'i18next';
import useMenu from 'features/webmapModule/hooks/useMenu';
import { ALIGHT_BOARD_COUNT, BOARD_COUNT, CROSS_SECTION_COUNT, DAILY_TRAFFIC_DISTRIBUTION, LINE_TRAFFIC_DISTRIBUTION, SECTION_TRAFFIC, STOP_PLACE_TRAFFIC, TRANSPORT_LINE_TRAFFIC } from '../utils/constants';
import { StopPlaceTrafficPanelContent } from '../components/tables';
import { noop } from 'utils/constants';


function useOnboardCountMenu(onSelect = noop) {

    const tableMenuData = [{
        key: TRANSPORT_LINE_TRAFFIC, 
        icon:<ServicePatternIcon/>,
        label: t('FT/TransportLineTraffic'),
        disabled: module => !module?.filtersSet,
        title: module => module?.filtersSet ? undefined : t('FT/SelectCountIntervalFirst'),
        children: [{
            key: BOARD_COUNT,
            disabled: module => !module?.filtersSet,
            title: module => module?.filtersSet ? undefined : t('FT/SelectCountIntervalFirst'),
            icon: <BoardIcon />,
            label: t('FT/DailyAverageTraffic')
        }, {
            key: ALIGHT_BOARD_COUNT,
            disabled: module => module?.filters?.servicePatternIds?.length !== 1,
            title: (module) => module?.filters?.servicePatternIds?.length === 1 ? undefined : t('FT/SelectServicePatternForPivot'),
            icon: <AlightBoardIcon />,
            label: t('FT/AlightBoardTrafficByStops')
        }, {
            key: CROSS_SECTION_COUNT,
            disabled: module => module?.filters?.servicePatternIds?.length !== 1,
            title: (module) => module?.filters?.servicePatternIds?.length === 1 ? undefined : t('FT/SelectServicePatternForPivot'),
            icon: <OnwardIcon />,
            label: t('FT/CrossSectionTraffic')
        }],
    }, {
        key: STOP_PLACE_TRAFFIC, 
        disabled: (module) => !module?.filtersSet || Boolean(module?.filters?.sectionId),
        onDisabledClick: (module) => () => !module?.filtersSet 
            ? module?.setHighlightedFilter('countInterval') || module?.setHighlightInstructions(t('FT/SelectCountIntervalFirst'))
            : module?.filters?.sectionId 
                ? module?.setHighlightedFilter('selection') || module?.setHighlightInstructions(t('FT/SelectionTypeMustBeAreaOrStopPlace') )
                : undefined,
        title: (module) => !module?.filtersSet 
            ? t('FT/SelectCountIntervalFirst')
            : module?.filters?.sectionId 
                ? t('FT/SelectionTypeMustBeAreaOrStopPlace') 
                : undefined,
        icon:<StopPlaceIcon/>,
        label: t('FT/StopPlaceTraffic')
    }, {
        key: SECTION_TRAFFIC,
        disabled: (module) => !Boolean(module?.filters?.sectionId),
        title: (module) => module?.filters?.sectionId ? undefined : t('FT/SelectionTypeMustBeSection'),
        icon:<SectionIcon/>,
        label: t('FT/SectionTraffic')
    }];

    const tableMenu = useMenu({
        data: tableMenuData,
        defaultValue: BOARD_COUNT,
        onSelect
    })

    const chartMenuData = [{
        key: TRANSPORT_LINE_TRAFFIC, 
        icon:<ServicePatternIcon/>,
        label: t('FT/TransportLineTraffic'),
        disabled: module => !module?.filtersSet,
        children: [{
            key: TRANSPORT_LINE_TRAFFIC + BOARD_COUNT,
            icon: <BoardTrafficIcon />,
            label: t('FT/DailyAverageTraffic'),
            disabled: module => !module?.filtersSet,
        }, {
            key: TRANSPORT_LINE_TRAFFIC + DAILY_TRAFFIC_DISTRIBUTION,
            icon: <TrafficDistributionIcon />,
            label: t('FT/DailyTrafficDistribution'),
            disabled: module => !module?.filtersSet,
        }, {
            key: TRANSPORT_LINE_TRAFFIC + LINE_TRAFFIC_DISTRIBUTION,
            disabled: module => module?.filters?.servicePatternIds?.length !== 1,
            title: (module) => module?.filters?.servicePatternIds?.length === 1 ? undefined : t('FT/SelectServicePatternForChart'),
            icon: <LineTrafficIcon />,
            label: t('FT/LineTrafficDistribution'),
        }],
    }, {
        key: STOP_PLACE_TRAFFIC, 
        disabled: module => !module?.filtersSet,
        icon:<StopPlaceIcon/>,
        label: t('FT/StopPlaceTraffic'),
        children: [{
            key: STOP_PLACE_TRAFFIC + BOARD_COUNT,
            icon: <BoardTrafficIcon />,
            label: t('FT/DailyAverageTraffic'),
            disabled: module => !module?.filtersSet,
        }],
    // }, {
    //     key: SECTION_TRAFFIC,
    //     disabled: (module) => Boolean(!module?.filters?.sectionId),
    //     title: (module) => module?.filters?.sectionId ? undefined : t('FT/SelectionTypeMustBeSection'),
    //     icon:<SectionIcon/>,
    //     label: t('FT/SectionTraffic'),
    //     children: [{
    //         key: SECTION_TRAFFIC + BOARD_COUNT,
    //         icon: <BoardTrafficIcon />,
    //         label: t('FT/DailyAverageTraffic'),
    //         disabled: (module) => Boolean(!module?.filters?.sectionId),
    //     }, {
    //         key: SECTION_TRAFFIC + DAILY_TRAFFIC_DISTRIBUTION,
    //         icon: <TrafficDistributionIcon />,
    //         label: t('FT/DailyTrafficDistribution'),
    //         disabled: (module) => Boolean(!module?.filters?.sectionId),
    //     }],
    }];

    const chartMenu = useMenu({
        data: chartMenuData,
        defaultValues: TRANSPORT_LINE_TRAFFIC + BOARD_COUNT,
        onSelect
    });

    React.useEffect(() => {
        if (!chartMenu.value) { return };

        console.log({
            chartMenu,
            tableMenu
        })

        switch (chartMenu.value) {
            case TRANSPORT_LINE_TRAFFIC + BOARD_COUNT:
            case TRANSPORT_LINE_TRAFFIC + DAILY_TRAFFIC_DISTRIBUTION:
                tableMenu.setValue(BOARD_COUNT);
                break;
            case TRANSPORT_LINE_TRAFFIC + LINE_TRAFFIC_DISTRIBUTION:
                if (![ALIGHT_BOARD_COUNT, CROSS_SECTION_COUNT].includes(tableMenu.value)) {
                    tableMenu.setValue(ALIGHT_BOARD_COUNT);
                }
                break;
        }

    }, [chartMenu.value])

    React.useEffect(() => {
        if (!tableMenu.value) { return };

        switch (tableMenu.value) {
            case BOARD_COUNT:
                if (!chartMenu?.value?.startsWith(TRANSPORT_LINE_TRAFFIC)) {
                    chartMenu.setValue(TRANSPORT_LINE_TRAFFIC + BOARD_COUNT);
                }
                break;
            case ALIGHT_BOARD_COUNT:
            case CROSS_SECTION_COUNT:
                chartMenu.setValue(TRANSPORT_LINE_TRAFFIC + LINE_TRAFFIC_DISTRIBUTION);
                break;
        }

    }, [tableMenu.value])

    return {
        tableMenu,
        chartMenu
    };
};

export default useOnboardCountMenu