import React from "react";
import { Col, Grid, Message, Nav, Panel, Row, toaster } from "rsuite";
import { t } from "i18next";

import Jumbotron from "components/pages/Jumbotron";
import PageTitle from "components/pages/PageTitle";
import ChangePasswordForm from "../components/forms/ChangePasswordForm";
import SubscriptionListPanel from "features/subscription/components/SubscriptionListPanel";
import { postProfileData } from "../profileAPI";
import SidebarPage from "layouts/SidebarPage";
import SubscriptionsPanel from "features/subscription/components/SubscriptionsPanel";
import PillInput from "components/inputs/PillInput";
import { PasswordIcon, SubscriptionIcon } from "components/icons";
import { PanelHeader } from "components/panels";
import OrganizationPanel from "features/organization/components/OrganizationPanel";


function ProfilePage () {
    const [ loading, setLoading ] = React.useState();
    const [ activeTab, setActiveTab ] = React.useState('subscriptions');
    const [ subscriptionFilter, setSubscriptionFilter ] = React.useState('valid')

    const subscriptionFilterData = [{
        label: t('ValidSubscriptions'),
        value: 'valid'
    }, {
        label: t('PastSubscriptions'),
        value: 'past'
    }, {
        label: t('FutureSubscriptions'),
        value: 'future'
    }]

    function changePassword({currentPassword, password}) {
        setLoading(true);
        postProfileData({password, currentPassword})
        .then(() => {
            toaster.push(<Message showIcon>
                {t(`Security/ChangePasswordSuccesful`)}
            </Message>)
        })
        .catch( err => {
            toaster.push(<Message showIcon type="error" closable>
                {t(`API/${err.message}`)}
            </Message>)
        })
        .finally(() => {
            setLoading(false);
        })
    }

    return (
        <SidebarPage activeKey="profile">

            <Grid className="mainGrid">
                <Row>
                    <Col xs={24}>
                        <SubscriptionsPanel {...{[subscriptionFilter]: true}} defaultPageSize={4}>
                            <PillInput
                                size="sm"
                                data={subscriptionFilterData}
                                value={subscriptionFilter}
                                onChange={setSubscriptionFilter} />
                        </SubscriptionsPanel>
                    </Col>
                    <Col xs={24}>
                        <OrganizationPanel defaultPageSize={4} />
                    </Col>        
                    <Col xs={24} >
                        <Panel bodyFill header={(
                            <PanelHeader 
                                title={t('Security/ChangePassword')}
                                icon={<PasswordIcon />}
                            />
                        )}>
                            <Panel shaded bordered>
                                <ChangePasswordForm layout="horizontal" loading={loading} onSubmit={changePassword}/>
                            </Panel>
                        </Panel>
                    </Col>
                </Row>
            </Grid>  

        </SidebarPage>
    )
};


export default ProfilePage;
