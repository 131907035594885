import DataChart, { Dataset } from 'features/chart/components/DataChart';
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';
import { onboardCountColors } from 'features/onboardCount/utils/colors';
import { getChartLabels } from 'features/onboardCount/utils/labels';
import { secondsToString } from 'features/onboardCount/utils/time';
import { getEmptyTraffic, getTraffic } from 'features/onboardCount/utils/traffic';
import { t } from 'i18next';
import React from 'react'
import { formatString } from 'utils/format';

const LineTrafficDistributionChart = React.forwardRef(({
    ...props
}, ref) => {
    
    const count = React.useContext(OnboardCountContext);

    const {
        journeyId = undefined,
    } = count?.filters || {};

    const {
        journeyStops = {},
    } = count?.elements || {};

    const {
        countInterval = undefined,
        feature = undefined,
    } = count?.selectedElements || {};

    const stops = {};
    Object.values(journeyStops)
        .filter(journeyStop => !journeyId || journeyStop?.journey?.id === journeyId)
        .forEach(journeyStop => {
            const stop = stops?.[journeyStop?.orderNum] || {
                orderNum: journeyStop.orderNum,
                name: journeyStop.stopPlace.name,
                traffic: getEmptyTraffic(),
            };

            ['alight', 'board', 'onward'].forEach(attr => {
                ['avg', 'min', 'max'].forEach(stat => {
                    stop.traffic[attr][stat] += journeyStop?.traffic?.[countInterval.str]?.[attr]?.[stat];
                })
            })

            stops[journeyStop.orderNum] = stop;
        });

    const datasets = [
        Dataset({
            name: 'alight',
            label: t('FT/AlightCount'),
            getValue: stop => 0 - stop?.traffic?.alight?.avg,
            color: onboardCountColors.alight,
        }),
        Dataset({
            name: 'alight',
            label: t('FT/AlightMin'),
            getValue: stop => 0 - stop?.traffic?.alight?.min,
            color: onboardCountColors.alight,
            disableLegend: true,
            type: 'line',
            showLine: false,
            pointStyle: 'line',
            pointRadius: 6,
        }),
        Dataset({
            name: 'alight',
            label: t('FT/AlightMax'),
            getValue: stop => 0 - stop?.traffic?.alight?.max,
            color: onboardCountColors.alight,
            disableLegend: true,
            type: 'line',
            showLine: false,
            pointStyle: 'line',
            pointRadius: 6,
        }),
        Dataset({
            name: 'board',
            label: t('FT/BoardCount'),
            getValue: stop => stop?.traffic?.board?.avg,
            color: onboardCountColors.board,
        }),
        Dataset({
            name: 'board',
            label: t('FT/BoardMin'),
            getValue: stop => stop?.traffic?.board?.min,
            color: onboardCountColors.board,
            disableLegend: true,
            type: 'line',
            showLine: false,
            pointStyle: 'line',
            pointRadius: 6,
        }),
        Dataset({
            name: 'board',
            label: t('FT/BoardMax'),
            getValue: stop => stop?.traffic?.board?.max,
            color: onboardCountColors.board,
            disableLegend: true,
            type: 'line',
            showLine: false,
            pointStyle: 'line',
            pointRadius: 6,
        }),
        Dataset({
            name: 'onward',
            label: t('FT/OnwardCount'),
            getValue: stop => stop?.traffic?.onward?.avg,
            color: onboardCountColors.onward,
            opacity: 0.25,
            type: 'line',
            cubicInterpolationMode: 'monotone',
            showLine: false,
            pointRadius: 3,
            fill: 'origin',
        }),
        Dataset({
            name: 'onward',
            label: t('FT/OnwardMin'),
            getValue: stop => stop?.traffic?.onward?.min,
            color: onboardCountColors.onward,
            disableLegend: true,
            type: 'line',
            showLine: false,
            pointStyle: 'line',
            pointRadius: 6,
        }),
        Dataset({
            name: 'onward',
            label: t('FT/OnwardMax'),
            getValue: stop => stop?.traffic?.onward?.max,
            color: onboardCountColors.onward,
            disableLegend: true,
            type: 'line',
            showLine: false,
            pointStyle: 'line',
            pointRadius: 6,
        }),
    ];

    const data = Object.values(stops).sortBy('orderNum')

    return (
        <DataChart 
            ref={ref}
            data={data}
            sets={datasets}
            title={formatString(t('FT/LineTrafficDistributionChartTitle'), getChartLabels(count))}
            barPercentage={1}
            categoryPercentage={1}
            animation={false}
            plugins={{
                legend: {
                    labels: {
                    filter: (item, chart) => datasets.filter(ds => !ds.disableLegend && item.text.includes(ds.label)).length

                    }
                },
                tooltip: {
                    callbacks: {
                        label: ctx => {
                            const i = ctx.parsed.x;
                            const di = ctx.datasetIndex - ctx.datasetIndex % 3
                            const stopList = Object.values(stops).sortBy('orderNum');
                            return [
                                ` ${datasets[di].props.label}: ${Math.abs(datasets[di].getValue(stopList[i]))}`,
                                ` ${t('FT/Min')}: ${Math.abs(datasets[di+1].getValue(stopList[i]))},` + 
                                ` ${t('FT/Max')}: ${Math.abs(datasets[di+2].getValue(stopList[i]))}`
                            ];
                        },
                    },
                }
            }}
            scales={{
                x: {
                    stacked: true,
                    ticks: {
                        align: 'center',
                        minRotation: 90,
                        maxRotation: 90,
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: t('FT/AlightBoardCount'),
                    },
                    ticks: {
                        callback: (value) => {
                            return `${Math.abs(value)}`;
                        },
                    },

                }
            }}
            {...props}
        />
    )
});

export default LineTrafficDistributionChart