import React from 'react';
import { t } from "i18next";

import { deleteCollection, getCollectionList } from "../collectionAPI";
import { AuthContext } from 'features/auth';
import { CollectionIcon } from 'components/icons';
import { useNavigate } from 'react-router-dom';
import CardPanel from 'components/panels/CardPanel';
import CollectionModal from './CollectionModal';
import { getSubtitleFromMetadata } from 'utils/elements';


const CollectionPanel = ({
    organizationId,
    sort,
    showAll,
    tileHeight,
    imgHeight,
    ...props
}) => {
    
    const { adminVisible } = React.useContext(AuthContext);
    const navigate = useNavigate();
    
    
    function getItems ({page, pageSize}) {
        return getCollectionList({
            organizationId,
            page,
            pageSize,
            sort
        });
    }

    function deleteItem (item) {
        return deleteCollection(item?.id)
    }

   
    return (
        <CardPanel
            title={t('MapCollections')}
            itemLabel={t('Collection')}
            icon={<CollectionIcon />}

            getItems={getItems}
            deleteItem={adminVisible && deleteItem}
            showAllURL={showAll && '/collections'}

            getSubtitle={getSubtitleFromMetadata}
            onItemClick={item => navigate(`/collections/${item.id}`)}
            cardProps={{
                icon: <CollectionIcon   />,
                category: 'collection',
                size: 'lg',
                expandAvatar: true,
            }}

            modalClass={adminVisible && CollectionModal}
            defaultModalValues={{
                organizations: organizationId ? [{id: organizationId}] : []
            }}

            coverHeight={imgHeight}
            {...props}
        />
    )
};


export default CollectionPanel;
