import React from 'react'
import { Panel } from 'rsuite'
import { t } from 'i18next'

import { PanelHeader, PanelMenu } from 'components/panels'
import ModuleTableToolbar from './ModuleTableToolbar'
import { PageSection } from 'components/pages'
import { TableIcon } from 'components/icons'
import MediaQueryContext from 'context/MediaQueryContext'


const ModuleTablePanel = React.forwardRef(({
    module,
    title,
    children
}, ref) => {
    const { isMobile } = React.useContext(MediaQueryContext);

    const toolbarHeight = module?.toolbarRef?.current?.offsetHeight || 0;
    const navs = isMobile ? undefined : module?.tableMenu?.navs;
    
    return <div ref={ref}>
        <Panel
            ref={ref}
            className='headerFill'
            bordered
            shaded
            header={<PanelHeader
                size='sm'
                icon={<TableIcon />}
                title={<>
                    {title || t('Tables')} 
                    <PanelMenu navs={navs} module={module} level={1} />
                </>}
                toolbar={<ModuleTableToolbar module={module} />}
            />}
            style={{height: `calc(100vh - ${toolbarHeight + 80}px)`}}
        >
            <PanelMenu navs={navs} module={module} level={2} />
            <div style={{marginBottom: 10}} />
            {children}

        </Panel>
    </div>
});

export default ModuleTablePanel;