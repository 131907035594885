import React from 'react'
import { Nav } from 'rsuite';

import { noop } from 'utils/constants'


const PanelMenuNav = ({
    appearance,
    size,
    data = [],
    module,
    value,
    setValue = noop,
}) => {

    return (
        <Nav
            appearance={appearance}
            size={size  }
            activeKey={value}
            onSelect={setValue}
        >
            {data.map((item) => {
                const props = {...item};
                delete props.setValue;
                delete props.value;
                Object.entries(props).forEach(([name, value]) => {
                    if (typeof value === 'function') {
                        props[name] = value(module);
                    }
                }) 
                const label = props?.label
                const key = props?.key
                delete props.key
                delete props.label


                return (
                    <Nav.Item key={key} eventKey={key} {...props} >
                        {label}
                    </Nav.Item>
                )
            })}

        </Nav>
    )
}


const PanelMenu = ({
    navs = {},
    module,
    level
}) => {

    const navList = [];

    function getNavs (nav = {}) {
        navList.push({
            data: nav?.children?.map(item => ({...item, children: undefined, parentKeys: undefined})) || [],
            value: nav?.value,
            setValue: nav?.setValue
        });
        const child = nav?.children?.find(n => n?.active && n?.children?.length);
        if (child) {
            getNavs(child);
        }
    }

    getNavs(navs);

    return <>
        { (level ? [navList?.[level-1]] : navList).map(({data, value, setValue} = {}, i) => (
            data?.length > 1
                ? <PanelMenuNav 
                    key={i}
                    appearance={i || level > 1 ? 'pills' : 'tabs'}
                    size='sm'
                    data={ data }
                    module={module}
                    value={ value }
                    setValue={setValue}
                /> : <></>
            
        )) }
    </>
}

export default PanelMenu