import React from 'react'
import { AvatarCard } from 'components/cards';
import TransportLineBadge from '../badges/TransportLineBadge';
import { StopPlaceIcon } from '../icons';
import { t } from 'i18next';
import { formatString } from 'utils/format';

const StopPlaceCard = ({
    size="md",
    name,
    transportLines = [],
    servicePatterns = [],
    nextStopPlaces,
}) => {
    let subtitle = undefined
    if (servicePatterns.length) {
        subtitle = [...new Set(servicePatterns.map(item => item.name))].join(',')
    } else if (nextStopPlaces?.length) {
        subtitle = formatString(t('FT/Towards'), {
            name: [...new Set(nextStopPlaces.map(s => s.name))].join(" / ")
        })
    } else if (nextStopPlaces) {
        subtitle = t('FT/TerminalStation')
    }
        
    return (
        <AvatarCard size={size}
            icon={<StopPlaceIcon />}
            title={<>
                {name}
                <span style={{marginRight: 4}} />
                {transportLines.sortBy().map(transportLine => (
                    <TransportLineBadge size={size} {...transportLine} operator={undefined} />
                ))}
            </>}
            subtitle={subtitle}
            hint={`${t('FT/StopPlace')}: ${name} (${transportLines.map(transportLine => transportLine.name).join(", ")}${subtitle ? ' - ' + subtitle : ''})`}
        />
    )
};

export default StopPlaceCard