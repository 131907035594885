import { Form, SelectPicker } from "rsuite";

import { FormGroup } from "components/forms";
import { noop } from "utils/constants";
import { t } from "i18next";


function defaultRenderItem (item) {
    if (item?.label) {
        return item.label;
    }

    if (item?.name) {
        return item.name;
    }

    if (item?.elementType) {
        return `${item.elementType} ${item?.elementId}`;
    }

    return item;
};


const SelectFeatureForm = ({
    map,
    getLabel = defaultRenderItem,
    elementType,
    ...props
}) => {
    const {
        selection = [],
        selectionIndex,
        setSelectionIndex = noop,
    } = map;

    const data = selection.map((item, value) => ({label: getLabel(item?.attributes), value}));

    return (
        <Form className="selectFeatureForm" {...props}>
            <FormGroup
                name="selectedFeature"
                label={t('Map/SelectedFeature')}
                accepter={SelectPicker}
                block
                renderValue={(value, item) => (
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
                        <div style={{flexShrink: 1}}>
                            {item?.label}
                        </div>
                        <div style={{flexGrow: 1, display: 'flex', justifyContent: 'flex-end', fontSize: '12px'}}>
                            {elementType}
                        </div>
                    </div>
                )}
                searchable={false}
                cleanable={true}
                data={data}
                value={selectionIndex}
                onChange={setSelectionIndex}
            />
        </Form>
    )
};


export default SelectFeatureForm;
