import React from 'react'
import { Panel, Progress } from 'rsuite'
import WebmapContext from '../context/WebmapContext';
import { t } from 'i18next';


const MapProgressBar = () => {

    const MODULE_NAMES = {
        webmap: t('WebMap'),
        onboardCount: t('Map/OnboardCount'),
        crossSectionRoadCount: t('Map/CrossSectionRoadCount')
    }
    
    const TASK_NAMES = {
        initialize: t('Map/Initializing'),
        query: t('Map/Query'),
        render: t('Map/Render'),
        fetch: t('Map/Fetch'),
        drawing: t('Map/Drawing'),
    }

    const {
        arcGISMap
    } = React.useContext(WebmapContext);

    const tasks = arcGISMap?.tasks || {};
    
    const progress = [];
    { Object.entries(tasks).forEach(([moduleName, moduleTasks]) => {
        Object.entries(moduleTasks || {}).forEach(([taskName, [d, t]]) => {
            progress.push({
                moduleName,
                taskName,
                percent: Math.round( d / t * 100)
            });
        });
    })};

    if (!progress.length) {
        return
    }

    return (
        <Panel bordered shaded bodyFill className="mapProgressContainer">
            { progress.map((p, i) => <div key={i} className="mapProgress">
                <div className="label">
                    {MODULE_NAMES?.[p.moduleName]} - {TASK_NAMES?.[p.taskName]}:
                </div>
                <div className="bar">
                    <Progress percent={p.percent} status={p.percent < 100 ? 'active' : 'success'} />
                </div>
            </div>) }
        </Panel>
    )
}

export default MapProgressBar