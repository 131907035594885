import React from 'react';
import { t } from "i18next";

import { OrganizationIcon } from 'components/icons';
import { useNavigate } from 'react-router-dom';
import CardPanel from 'components/panels/CardPanel';
import { getOrganizationList } from '../organizationAPI';


const OrganizationPanel = ({
    tileHeight,
    imgHeight,
    ...props
}) => {
    
    const navigate = useNavigate();
    
    
    function getItems ({page, pageSize}) {
        return getOrganizationList({
            page,
            pageSize,
        });
    }
   
    return (
        <CardPanel
            title={t('MyOrganizations')}
            itemLabel={t('Organization')}
            icon={<OrganizationIcon />}

            getItems={getItems}

            onItemClick={item => navigate(`/organizations/${item.id}`)}
            cardProps={{
                icon: <OrganizationIcon />,
                category: "organization",
                expandAvatar: true,
                size: 'lg',
            }}

            coverHeight={imgHeight}
            {...props}
        />
    )
};


export default OrganizationPanel;
