export async function checkCertificate(url) {
    return fetch(url, { mode: "no-cors" })
        .then(() => true)
        .catch(() => false);
}


export async function openCertificate(url) {
    return Promise.resolve(window.open(url, "_blank"));
}


export function acceptCertificate(url) {
    // Open the server URL in a new window
    const serverWindow = window.open(url, "_blank");

    if (!serverWindow) {
        alert("Popup blocked. Please allow popups for this website.");
        return;
    }

    // Poll to check if the server window has loaded the target page
    const checkInterval = setInterval(() => {
        try {
            // Check if the serverWindow has loaded the expected URL
            if (serverWindow.location.href.includes(url)) {
                clearInterval(checkInterval); // Stop checking
                serverWindow.close(); // Close the window
                alert("Server certificate accepted. You can proceed.");
            }
        } catch (e) {
            // Cross-origin restrictions will block access initially
            console.log("Waiting for user to accept the certificate...");
        }
    }, 1000); // Check every second
}