import FormInput from 'components/inputs/FormInput';
import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext'
import { ModuleFilter } from 'features/webmapModule';
import React from 'react'
import { t } from 'i18next';
import StationFormFields from '../forms/StationFormFields';
import { StationCard } from '../cards';

const StationFilter = () => {
    const module = React.useContext(CrossSectionRoadCountContext);

    const [values, setValues] = React.useState({});

    function applyValues () {
        if (!module?.applyFilters) return;
        const filterValues = {}
        if (values?.selectedSection) {
            filterValues.selectedSection = values.selectedSection
        } else {
            filterValues.stationCode = values?.stationCode
        }

        module.applyFilters(filterValues)
    }

    const selectedStation = module?.selectedElements?.station;

    return (
        <ModuleFilter 
            name='station'
            label={t('OKKF/SelectedStation')}
            acceptor={FormInput}
            disabled={!module.initialized}
            cleanable
            popoverWidth={320}
            instructionText={module?.tutorialStep === 2 && t('OKKF/StationFilterInstructions')}
            placement={'bottomEnd'}
            submitLabel={t('Search')}
            clearLabel={t('ClearSearch')}
            renderValue={() => selectedStation && <StationCard {...(selectedStation || {})} size='xs' />}
            rend
            onSubmit={applyValues}
            onClear={() => module.applyFilters({stationCode: undefined})}
        >
            <StationFormFields 
                onChange={setValues} />

        </ModuleFilter>
    )
}

export default StationFilter