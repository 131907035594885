import React from "react";
import localforage from "localforage";
import { t } from "i18next";

import FeatureLayer from "@arcgis/core/layers/FeatureLayer";
import Graphic from "@arcgis/core/Graphic";
import MapImageLayer from "@arcgis/core/layers/MapImageLayer";
import Point from "@arcgis/core/geometry/Point";
import PopupTemplate from "@arcgis/core/PopupTemplate";
import TileLayer from "@arcgis/core/layers/TileLayer";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import * as watchUtils from '@arcgis/core/core/watchUtils';

import { noop } from "utils/constants";
import { getElementURL } from "../utils/element";
import { logEvent } from "features/logging";
import { useToaster } from "rsuite";
import { showErrorMessage } from "components/messages/ErrorMessage";


const TILE_LAYER = 'tile_layer';
const MAP_IMAGE_LAYER = 'map_image_layer';
const FEATURE_LAYER = 'feature_layer';
const CLIENT_SIDE_LAYER = 'client_side_layer';

const MAP_LAYER_TYPES = [MAP_IMAGE_LAYER, TILE_LAYER];

const CONVEX_HULL_PADDING = 50;
const FEATURES_PER_FETCH = 1000;
const FEATURES_PER_EDIT = 10;
const TIMEOUT_PER_EDIT = 3000;


function createPolylineGraphic(geometry, spatialReference) {
    return Graphic.fromJSON({
        geometry: {
            type: 'polyline',
            paths: geometry,
            spatialReference
        }
    });
}

function createConvexHullGraphic(geometry = [], spatialReference) {
    const points = geometry.map(({x, y}) => 
        new Point({x, y, spatialReference})
    );

    if (!points.length) { return }

    const buffers = geometryEngine.buffer(points, points.map(() => CONVEX_HULL_PADDING));
    const hull = geometryEngine.convexHull(buffers, true);

    return new Graphic({geometry: hull[0]});
};

function createDefaultGraphic(geometry, spatialReference) {
    return Graphic.fromJSON({
        geometry: {
            ...geometry,
            spatialReference
        }
    });
};

function createGraphic(geometryType, geometry, spatialReference) {
    let graphic;
    switch( geometryType ) {
        case 'polygon':
            graphic = createConvexHullGraphic(geometry, spatialReference);
            break;
        case 'polyline':
            graphic = createPolylineGraphic(geometry, spatialReference);
            break;
        default:
            graphic = createDefaultGraphic(geometry, spatialReference);
            
    }

    return graphic
}

function useArcGISLayer({
    id,
    arcGISMap,
    element,
    join = {},
    onRenderStarted = noop,
    onRendered = noop,
}) {
    const arcGISMapSet = arcGISMap?.map !== undefined;

    const layerRef = React.useRef();
    const abortControllerRef = React.useRef(new AbortController());
    const toaster = useToaster()

    const isMapLayer = MAP_LAYER_TYPES.includes(element.type)

    function checkConfig(data) {
        return Object.values(arcGISMap?.configurations || {})
            .every(v => data?.configurationItemIds?.includes(v))
    };

    const layerData = {
        id,
        url: element.url,
        title: isMapLayer ? t('Map/Legend') : element.name,
        visible: arcGISMap?.configurations?.length ? checkConfig(element) : element?.visible,
        labelItems: element.labelItems,
        legendEnabled: element.legendEnabled,
        ...(join?.layerProps || {}),
    };
    
    const joinFields = [...(join?.fields || [])] ;
    const geometryType = join?.layerProps?.geometryType;
    const baseField = join?.baseField || (join?.targetField ? 'FID' : undefined);
    const targetField = join?.targetField || (join?.baseField ? 'FID' : undefined);
    const objectIdField = join?.objectIdField || baseField || 'id';
    const generateGeometry = (typeof join?.getGeometry === 'function');
    const discardCache = join?.discardCache || generateGeometry;

    async function setupSublayer (sublayerElement, isTileLayer) {
        const sublayer = layerRef.current.findSublayerById(sublayerElement?.layerId)

        if (sublayer) {
            if (!isTileLayer) {
                sublayer.visible = Object.values(arcGISMap?.configurations)?.length ? checkConfig(sublayerElement) : sublayerElement?.visible
                sublayer.labelsVisible = sublayerElement?.labelItems
                sublayer.legendEnabled = sublayerElement?.legendEnabled
            }
            if (sublayerElement?.popupTemplate) {
                sublayer.popupTemplate = new PopupTemplate(sublayerElement?.popupTemplate)
            }
        }

        sublayerElement?.subLayers.forEach(data => setupSublayer(data, isTileLayer))
    };

    async function setupSublayers () {
        if (element?.subLayers?.length) {
            element.subLayers.map(data => 
                setupSublayer(data, element?.type === TILE_LAYER)
            );
        }
    }

    async function setupLayer(layer, source = []) {

        layer.layerIndex = join.index

        if (join?.name && arcGISMap) {
            arcGISMap.joinLayers[join.name] = layer;
        }

        if (arcGISMap?.map?.layers) {
            arcGISMap.map.layers.remove(arcGISMap.map.findLayerById(id));
        }

        arcGISMap?.view.on("layerview-create-error", function(event) {
            if (event.layer.id === id) {
                console.log("LayerView failed to create for layer with the id: ", event.layer.id);
                onRendered()
                // showErrorMessage(toaster, `${t('Map/LayerViewCreateError', {name: element?.name})}`)
            }
        });

        if (arcGISMap?.map) {
            try{
                arcGISMap.map.add(layer, join?.index)
            } catch (e) {
                console.log('Error adding layer to map');
                console.error(e)
                onRendered();
            }
        }
        
        if (element.type === CLIENT_SIDE_LAYER && !layer.source?.length && !source?.length) {
            console.log(`Source is empty for '${id} (${element?.name})'`);
            onRendered();
            logEvent('renderedLayer', {
                layerId: id,
                name: element?.name,
            })
            if (typeof(join?.onRendered) === 'function') {
                join?.onRendered()
            };
            return;
        }

        layer.on('layerview-create', ({layerView}) => {
            console.log(`Layer view created for '${id} (${element?.name})'`)
            
            onRendered();
            logEvent('renderedLayer', {
                layerId: id,
                name: element?.name,
            })
            if (typeof(join?.onRendered) === 'function') {
                join?.onRendered()
            };
            if (source?.length) {
                console.log('Updating client side features.')
                uploadFeatures(source)
                // layerRef.current.source = source
            }
        });

        watchUtils.when(layer, "loaded", setupSublayers);
        
        layerRef.current = layer;
    };

    function getSourceAndFields({
        features = [],
        fields = [],
        spatialReference,
    } = {}, signal) {

        const records = join?.records || [];
        // const spatialReference = data?.spatialReference || layerRef.current?.spatialReference;

        if ( generateGeometry ) {
            // const geometries = [];
            const source = records.map( record => {
                if (signal?.aborted) throw Error('Aborted');

                const geometry = join?.getGeometry(record);
                const graphic = createGraphic(geometryType, geometry, spatialReference || layerRef.current?.spatialReference);
                // geometries.push(graphic.geometry);
                            
                Object.entries(record).forEach(([name, value]) => {
                    graphic.setAttribute(name, value);
                });

                return graphic;
            });

            // (await projectGeometries(server, EOV, ...geometries)).forEach((g, i) => source[i].geometry = g);

            return {
                source, 
                fields: [...joinFields]
            }

        } else {
            const source = features.map(f => {
                if (signal?.aborted) throw 'Aborted';
                
                const joinData = join.getJoinData(f) || {};
                const joinAttributes = Object.fromEntries(Object.entries(joinData).map(([name, value]) => [
                    name,
                    ( typeof( value ) === 'function' ? value(f?.attributes) : value )
                ]))
                return Graphic.fromJSON({
                    ...f,
                    attributes: {
                        ...f.attributes,
                        ...joinAttributes,
                    },
                    geometry: {
                        ...f.geometry,
                        spatialReference: spatialReference || layerRef.current?.spatialReference
                    }
                });
            });
        
            return {
                source, 
                fields: [...joinFields, ...(fields || [])].map(field => ({
                    name: field.name.replace('.', '_'),
                    alias: field.alias || field.name.replace('.', '_'),
                    type: field.type
                        .replace('esriFieldType', '')
                        .toLowerCase()
                        .replace('smallinteger', 'small-integer')
                        .replace('globalid', 'global-id')
                }))
            }
        }
    }


    async function processClientData( data, signal )  {
        if (signal?.aborted) {
            console.log(`processClientData aborted for ${id} (${element?.name})`);
            return;
        };

        const { source, fields } = getSourceAndFields(data, signal);
    
        console.log(`Processing ${data?.features?.length} features for element ${element?.name || element?.id}.`)
        const layerData = {
            id,
            objectIdField,
            title: element?.title,
            visible: element?.visible,
            legendEnabled: element?.legendEnabled,
            popupTemplate: element?.popupTemplate,
            source: [],
            fields,
            outFields: '*',
            ...(join?.layerProps || {}),
            orderBy: join?.orderBy,
            spatialReference: data?.spatialReference,
        }
        try {
             return setupLayer(new FeatureLayer(layerData), source)
        } catch (e) {
            console.error(e)
        }
    };

    async function getClientData (url, features = [], signal) {

        if (!signal) {
            abortControllerRef.current.abort();
            abortControllerRef.current = new AbortController();
        }

        const controller = abortControllerRef.current;

        if (!discardCache) {
            localforage.getItem(`Element-${element.id}-${url}`)
                .then(value => {
                    logEvent('usingCachedClientData', {
                        layerId: id,
                        name: element?.name,
                    })
                    console.log(`Using cached client data for layer '${id}' (${element?.name})...`);
                    const data = JSON.parse(value);
                    if (!data) {
                        throw 'Missing data'
                    }
                    processClientData(data, signal || controller.signal)
                })
                .catch(err => {
                    console.error(err);
                    console.log(`No cached client data found for layer '${id}' (${element?.name}). Fetching...`);
                    fetchClientData(url, features, signal || controller.signal)
                })
        } else {
            console.log(`Fetching client data for layer '${id}' (${element?.name})...`);
            fetchClientData(url, features, signal || controller.signal);
        }
    };

    async function fetchClientCount(url) {
        const countUrl = `${url}&where=1%3D1&returnCountOnly=true`;
        return fetch(countUrl)
            .then(r => r.json())
            .then(({count}) => count );
    }

    async function fetchClientData(url, features = [], signal) {
        const loaded = features?.length || 0 ;
        logEvent('fetchingLayerData', {
            layerId: id,
            name: element?.name,
            loaded,
        })
        const where =  join?.where ? `${join?.where}` : '1%3D1'

        let pagination = `&resultRecordCount=${FEATURES_PER_FETCH}&resultOffset=${features?.length}`

        if (!features.length) {
            const count = await fetchClientCount(url)
            arcGISMap.addTaskProgress(`fetch`, 0, count);
        }

        const fetchUrl = `${url}&where=${where}${pagination}`;
        const fetchUrlLegacy = `${url}&where=${where}+AND+FID>%3D${features?.length}`
        

        async function processFetchResult (data) {
            const errorMessage = data?.error?.message;
            if (errorMessage) {
                throw errorMessage
            }

            const count = data?.features?.length || 0;
            console.log(`Loaded ${count} features for layer ${id} (${element?.name}).`)
            logEvent('loadedLayerData', {
                layerId: id,
                name: element?.name,
                count,
                loaded: loaded + count,
            })
            arcGISMap.addTaskProgress(`fetch`, data?.features?.length || 0, 0);

            data.features = [...features, ...(data?.features || [])];
            if (data?.exceededTransferLimit) {
                return getClientData(url, data.features, signal);
            }

            localforage.setItem(`Element-${element.id}-${url}`, JSON.stringify(data))
            processClientData(data, signal);
        }

        fetch(fetchUrl, { signal })
            .then(r => r.json())
            .then(processFetchResult)
            .catch()
            .catch(async err => {
                console.log(err)
                if (err == 'Pagination is not supported.') {
                    return fetch(fetchUrlLegacy, {signal})
                        .then(r => r.json())
                        .then(processFetchResult)
                }
                else {
                    throw err
                }
            })
            .catch(async err => {
                console.error(err);

                const count = await fetchClientCount(url)
                arcGISMap.addTaskProgress(`fetch`, 0, 0 - count);
            })
    }

    async function createLayer() {
        if (!arcGISMap) return;

        onRenderStarted();
        logEvent('renderingLayer', {
            layerId: id,
            name: element?.name,
        })
        if (typeof(join?.onRenderStarted) === 'function') {
            join?.onRenderStarted()
        };
        console.log(`Start rendering layer '${id} (${element?.name})...'`)

        switch (element?.type) {
            case TILE_LAYER:
                setupLayer(new TileLayer(layerData));
                break;
            case MAP_IMAGE_LAYER:
                setupLayer(new MapImageLayer(layerData));
                break;
            case FEATURE_LAYER:
                setupLayer(new FeatureLayer({
                    ...layerData,
                    labelsVisible: element?.labelItems
                }));
                break;
            case CLIENT_SIDE_LAYER:
                getClientData(getElementURL(element))
                break;
            default:
                onRendered();
                logEvent('renderedLayer', {
                    layerId: id,
                    name: element?.name,
                })
                if (typeof(join?.onRendered) === 'function') {
                    join?.onRendered()
                };
                throw Error('Invalid map element type.')
        };
    }

    function getFeatureId(feature, field = baseField) {
        return `${feature?.attributes?.[field || objectIdField]}`
    }

    async function updateLayer() {
        if (!arcGISMap) return;

        onRenderStarted();
        logEvent('renderingLayer', {
            layerId: id,
            name: element?.name,
        });
        if (typeof(join?.onRenderStarted) === 'function') {
            join?.onRenderStarted()
        };
        console.log(`Updating features for layer '${id}' ${element?.name}`);

        const definitionExpression = layerRef.current.definitionExpression;
        layerRef.current.definitionExpression = undefined

        layerRef.current.queryFeatures().then(async results => {
            const newSource = getSourceAndFields(results).source || [];

            const addFeatures = [];
            const updateFeatures = [];

            const currentSource = results.features;

            const currentSourceIds = new Set(currentSource?.map(f => getFeatureId(f)) || []);
            const currentObjectIds = Object.fromEntries(currentSource?.map(feature => [getFeatureId(feature), feature?.attributes?.[objectIdField]]) || []);
            const newSourceIds = new Set(newSource?.map(feature => getFeatureId(feature, targetField)) || []);
            const newSourceMap = Object.fromEntries(newSource?.map(f => [getFeatureId(f), f]) || [])

            newSource?.forEach(feature => {
                if (!currentSourceIds.has(getFeatureId(feature, targetField))) {
                    addFeatures.push(feature);
                }
            });

            currentSource?.forEach(current => {
                const baseFieldValue = current?.attributes?.[baseField];
                if (!newSourceIds.has(getFeatureId(current))) {
                    joinFields.forEach(field => {
                        current.attributes[field.name] = undefined
                    })
                    current.attributes[baseField] = baseFieldValue
                } else {
                    joinFields.forEach(field => {
                        current.attributes[field.name] = newSourceMap?.[baseFieldValue]?.attributes?.[field.name];
                    })
                }
                updateFeatures.push(current)
            });

            layerRef.current.definitionExpression = definitionExpression;
            
            const USE_BATCHES = true;

            if (USE_BATCHES) {
                    
                await uploadFeatures(updateFeatures, {command: 'update'});
                await uploadFeatures(addFeatures);

            } else {
                await layerRef.current.applyEdits({ addFeatures, updateFeatures }).then((results) => {
                    layerRef.current.refresh();
                });
            }

            console.log(`Updating features ended for layer '${id}' ${element.name}`);
            onRendered();
            logEvent('renderedLayer', {
                layerId: id,
                name: element?.name,
            })
            if (typeof(join?.onRendered) === 'function') {
                join?.onRendered()
            };

            Object.entries(layerData).map(([attr, value]) => {
                layerRef.current[attr] = value;
            });


            layerRef.current.layerIndex = join.index
            if (join?.name && arcGISMap) {
                arcGISMap.joinLayers[join.name] = layerRef.current;
            }
        })
        
    }

    async function uploadFeatures(newFeatures = [], {
        command = 'add',
        batchSize = 50,
        batchTime = 0.5,
    } = {}) {
        const layer = layerRef.current;
        const iterator = newFeatures.values();
        let result = iterator.next();

        arcGISMap.addTaskProgress(`drawing`, 0, newFeatures.length);

        while (!result.done) {
          const start = performance.now();
          const features = [];

          // consume for batchTime milliseconds.
          // while (features.length < batchSize && !result.done) {
          while (performance.now() - start < batchTime && !result.done) {
            features.push(result.value);
            result = iterator.next();
          }

          if (features.length) {
            // console.log(`Uploading ${features.length} features for layer ${id} (${element?.name})`);
            const results = await layer.applyEdits({
              [`${command}Features`]: features
            });
            
            arcGISMap.addTaskProgress(`drawing`, features.length, 0);

            // console.log(results)
          }
        }

        layer.refresh()
      }

    React.useEffect(() => {
            try {
                if (element?.type === CLIENT_SIDE_LAYER) {
                    if (join?.layerProps?.visible === false && !layerRef.current) {
                        return;
                    }
                    if (!Object.values(join || {})?.length) {
                        console.log(`Empty join, removing layer ${id} (${element?.name})`);
                        if (layerRef.current) {
                            layerRef.current.visible = false;
                        }
                        if (arcGISMap?.map?.layers) {
                            arcGISMap.map?.layers.remove(arcGISMap.map.findLayerById(id));
                        }
                    }
                    else if (layerRef.current) {
                        updateLayer();
                    } else {
                        createLayer();
                    }
                } else {
                    createLayer();
                }
            } catch (e) {
                console.log(`Error creating layer ${id} (${element?.name})`);
                console.error(e)
                onRendered();
            }
    }, [element?.type, element?.url, arcGISMapSet, JSON.stringify(join)]);

    React.useEffect(() => {
        if (layerRef.current) {
            layerRef.current.popupTemplate = new PopupTemplate(element?.popupTemplate)
        }
    }, [element?.popupTemplate])

    React.useEffect(() => {
        setupSublayers();
    }, [arcGISMap?.configurations])

    return layerRef.current;

};


export default useArcGISLayer;
