import { faArrowDown, faArrowUp, faCalendar, faCalendarAlt, faCalendarDay, faCalendarWeek, faCar, faEye, faLayerGroup, faMapMarker, faMapMarkerAlt, faRoad, faSearch } from '@fortawesome/free-solid-svg-icons';
import FaIcon, { ESRIIcon } from 'components/icons';


export const LayersIcon = ({ ...props }) => <FaIcon icon={faLayerGroup} {...props} />

export const ViewIcon = ({ ...props }) => <FaIcon icon={faEye} {...props} />

export const SearchIcon = ({ ...props }) => <FaIcon icon={faSearch} {...props} />

export const OnwardIcon = ({ ...props }) => <FaIcon icon={faArrowUp} {...props} />

export const BackwardIcon = ({ ...props }) => <FaIcon icon={faArrowDown} {...props} />

export const MotorwayIcon = ({ ...props }) => <FaIcon icon={faCar} {...props} />

export const HighwayIcon = ({ ...props }) => <FaIcon icon={faRoad} {...props} />

export const YearIcon = ({ ...props }) => <FaIcon icon={faCalendar} {...props} />

export const MonthIcon = ({ ...props }) => <FaIcon icon={faCalendarAlt} {...props} />

export const WeekIcon = ({ ...props }) => <FaIcon icon={faCalendarWeek} {...props} />

export const DayIcon = ({ ...props }) => <FaIcon icon={faCalendarDay} {...props} />

export const StationIcon = ({ ...props }) => <FaIcon icon={faMapMarkerAlt} {...props} />
