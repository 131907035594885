import React from 'react';

import { CrossSectionRoadCountChartPanel, CrossSectionRoadCountTablePanel } from 'features/crossSectionRoadCount';
import { OnboardCountChartPanel, OnboardCountTablePanel } from 'features/onboardCount';

const ModuleContent = ({
    view = "map",
}) => {

    return <>

        { view === 'table' && <>
            <OnboardCountTablePanel />
            <CrossSectionRoadCountTablePanel />
        </> }
        { view === 'chart' && <>
            <OnboardCountChartPanel />
            <CrossSectionRoadCountChartPanel />
        </> }
        
    </>;
};

export default ModuleContent;