import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import { ModuleSidebar } from 'features/webmapModule'
import React from 'react'
import AreaTrafficTable from '../tables/AreaTrafficTable';
import TransportLineTrafficTable from '../tables/TransportLineTrafficTable';
import JourneyTrafficTable from '../tables/JourneyTrafficTable';
import StatCard from 'components/cards/StatCard';
import { Col, Row } from 'rsuite';
import { t } from 'i18next';
import { AlightIcon, BoardIcon, OnwardIcon } from '../icons';
import { onboardCountColors } from 'features/onboardCount/utils/colors';
import { JOURNEY_TRAFFIC, STOP_PLACE_TRAFFIC, TRANSPORT_LINE_TRAFFIC } from 'features/onboardCount/utils/constants';

const OnboardCountSidebar = () => {
    const module = React.useContext(OnboardCountContext);

    const sidebarRef = React.useRef();
    const journeyTableRef = React.useRef();
    const stopPlaceTableRef = React.useRef();
    const transportLineTableRef = React.useRef();

    const [tableKey, setTableKey] = React.useState(TRANSPORT_LINE_TRAFFIC)

    const {
        countInterval,
        element,
        area,
        section,
        feature,
    } = module?.selectedElements || {};

    const sidebarHeight = document.getElementsByClassName('mapSidebar')?.[0]?.clientHeight;
    const stopPlaceTableTop = document.querySelector(".mapSideBarContent .rs-table")?.offsetTop || 0;
    const tableHeight = sidebarHeight - stopPlaceTableTop - 254 + (!area ? 50 : 0);

    React.useEffect(() => {
        if (!element) {
            setTableKey(TRANSPORT_LINE_TRAFFIC);
        }
    }, [element])

    if (!module?.countId || !feature) return null;
    const traffic = element?.traffic?.[countInterval.str] || {};

    return (
        <ModuleSidebar ref={sidebarRef} module={module}>
            <Row>
                { element?.elementType === 'section' ? <>
                    <Col xs={12} sm={8} md={6} lg={4}>
                        <StatCard 
                            icon={<OnwardIcon />}
                            name={t('FT/OnwardLong')}
                            title={t('FT/DailyAverageCrossSectionCount')}
                            unit={t('FT/travellerPerDay')}
                            value={element?.direction === 1 ? traffic?.forward?.avg : traffic?.backward?.avg}
                            color={onboardCountColors.onward}
                        />
                    </Col>
                </> : <>
                    <Col xs={12} sm={8} md={6} lg={4}>
                        <StatCard 
                            icon={<AlightIcon />}
                            name={t('FT/Alight')}
                            title={t('FT/DailyAverageAlightCount')}
                            unit={t('FT/travellerPerDay')}
                            value={traffic?.alight?.avg}
                            color={onboardCountColors.alight}
                        />
                    </Col>
                    <Col xs={12} sm={8} md={6} lg={4}>
                        <StatCard 
                            icon={<BoardIcon />}
                            name={t('FT/Board')}
                            title={t('FT/DailyAverageBoardCount')}
                            unit={t('FT/travellerPerDay')}
                            value={traffic?.board?.avg}
                            color={onboardCountColors.board}
                        />
                    </Col>
                
                </> }
            
            </Row>

            { area && (
                <AreaTrafficTable 
                    ref={stopPlaceTableRef} 
                    stopPlacesOnly 
                    collapsible
                    collapsed={tableKey !== STOP_PLACE_TRAFFIC}
                    onCollapse={(value => setTableKey(!value ? STOP_PLACE_TRAFFIC : undefined))}
                    height={tableHeight}
                    columnWidth={section ? 80 : 120}
                />
            )}

            <TransportLineTrafficTable 
                ref={transportLineTableRef}
                selectedIntervalOnly
                servicePatternsOnly
                collapsible
                collapsed={tableKey !== TRANSPORT_LINE_TRAFFIC}
                onCollapse={(value => setTableKey(!value ? TRANSPORT_LINE_TRAFFIC : undefined))}
                height={tableHeight}
                columnWidth={section ? 80 : 120}
            />

            <JourneyTrafficTable 
                ref={journeyTableRef} 
                collapsible
                collapsed={tableKey !== JOURNEY_TRAFFIC}
                onCollapse={(value => setTableKey(!value ? JOURNEY_TRAFFIC : undefined))}
                height={tableHeight} 
                columnWidth={section ? 80 : 120}
            />

        </ModuleSidebar>
    )
}

export default OnboardCountSidebar