import React from 'react'
import { Button, CheckPicker } from 'rsuite'

import { ModuleFilter } from 'features/webmapModule'
import { t } from 'i18next';
import TransportLineCard from '../cards/TransportLineCard';
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';


const TransportLinesFilter = ({
}) => {

    const module = React.useContext(OnboardCountContext);

    const controlRef = React.useRef();

    const {
        transportLineIds = []
    } = module?.filters || {};

    const {
        transportLines = {}
    } = module?.elements || {};
    

    const [ value, setValue ] = React.useState(transportLineIds);

    React.useEffect(() => {
        setValue(transportLineIds);
    }, [`${transportLineIds}`])

    const data = Object.values(transportLines).sortBy().map(transportLine => ({
        label: transportLine.name,
        elementType: 'transportLine',
        element: transportLine,
        value: transportLine.id
    }))


    function submitValues(values) {
        module.applyFilters({transportLineIds: values});
    }

    return ( 
        <ModuleFilter 
            ref={controlRef}
            name='transportLines'
            label={t('FT/TransportLines')}
            acceptor={CheckPicker}
            disabled={!data.length}
            data={data}      
            renderMenuItem={(label, item) => <TransportLineCard size="xs" {...(item?.element || {})} />}
            value={value}
            placeholder={t('FT/AllTransportLines')}
            onChange={setValue}
            onExit={() => setValue(transportLineIds)}
            onClean={() => submitValues([])}
            renderExtraFooter={() => <div style={{padding: 10}}>
                <Button 
                    appearance="primary"
                    block 
                    onClick={() => {
                        submitValues(value)
                        controlRef.current.close()
                    }
                }>
                    { t('ApplyFilter') }
                </Button>
            </div>}
        />
     )
}

export default TransportLinesFilter