import React, { useState, useRef } from 'react';
import 'assets/css/panel.css'; // Ensure you include appropriate styles
import { Panel } from 'rsuite';


const ResizablePanel = ({
    side = 'right',
    defaultWidth = 300,
    minWidth = 100,
    maxWidth = 500,
    children,
    ...props
}) => {

    const [width, setWidth] = useState(defaultWidth); // Initial width in pixels
    const resizableRef = useRef(null);
    const isResizing = useRef(false);

    const handleMouseDown = (e) => {
        e.preventDefault();
        isResizing.current = true;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        resizableRef.current.classList.add('resizing');
    };

    const handleMouseMove = (e) => {
        if (isResizing.current && resizableRef.current) {
            const boundingRect = resizableRef.current.getBoundingClientRect();
            const newWidth = (side === 'left' ? -1 : 1) * (e.clientX - boundingRect?.[side === 'right' ? 'left' : 'right']);
            if (newWidth >= minWidth && newWidth <= maxWidth) {
                setWidth(newWidth);
            }
        }
    };

    const handleMouseUp = () => {
        isResizing.current = false;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
        resizableRef.current.classList.remove('resizing');
    };

    const Resizer = () => (
        <div
            className="resizer"
            onMouseDown={handleMouseDown}
        />
    )
    return (
        <Panel bodyFill {...props}>
            <div
                ref={resizableRef}
                className='resizable-container'
                style={{ width: `${width}px`}}
            >
                {side === 'left' && <Resizer />}
                <div style={{ flex: 1, overflow: 'auto' }}>
                    {children}
                </div>
                {side === 'right' && <Resizer />}
            </div>
        </Panel>
    );
};

export default ResizablePanel;
