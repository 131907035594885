import { TRANSPORT_LINE_COLORS, TRANSPORT_LINE_NAMES, TRANSPORT_LINE_TYPE_ORDER } from '../utils/constants';
import { TRANSPORT_LINE_TYPES } from '../utils/constants';
import { noop } from 'utils/constants';
import { compareNumStrings } from 'utils/compare';


function TransportLine({
    id,
    type,
    name,
    servicePatterns = {},
    operator = {},
    traffic
}) {
    const transportLine = {
        elementType: 'transportLine',
        elementId: Number(id),
        id: Number(id),
        type,
        typeStr: TRANSPORT_LINE_TYPES?.[type],
        typeName: TRANSPORT_LINE_NAMES?.[type],
        color: TRANSPORT_LINE_COLORS?.[type],
        servicePatterns: Object.values(servicePatterns).filter(servicePattern => servicePattern?.transportLine?.id === id),
        operator,
        name,
        traffic,
        compare: noop,
    };

    transportLine.compare = other => compareTransportLines(transportLine, other);

    return transportLine
}


export function compareTransportLines(a, b) {
    return TRANSPORT_LINE_TYPE_ORDER.indexOf(a.type) - TRANSPORT_LINE_TYPE_ORDER.indexOf(b.type) ||
        compareNumStrings(a.name, b.name);
};


export default TransportLine;
