import { compareStrings } from 'utils/compare';
import { noop } from 'utils/constants';
import StopPlace from './StopPlace';


function Area({
    id,
    name,
    x = [],
    y = [],
    stopPlaceIds = [],
    transportLineIds = [],
    traffic,
    stopPlaces = {},
    transportLines = {},
}) {
    const area = {
        elementType: 'area',
        elementId: Number(id),
        id: Number(id),
        name,
        geometry: x.map((x, i) => ({ x, y: y[i] })),
        stopPlaceIds,
        stopPlaces: Object.values(stopPlaces).filter(stopPlace => stopPlaceIds.includes(stopPlace.id)).map(stopPlace => StopPlace({...stopPlace, transportLines, stopPlaces})),
        transportLineIds,
        transportLines: Object.values(transportLines).filter(transportLine => transportLineIds.includes(transportLine.id)),
        traffic,
        compare: noop,
    };

    area.compare = other => compareAreas(area, other);

    return area;
}


export function compareAreas (a, b) {
    return compareStrings(a.name, b.name) || 
        a.id - b.id;
};


export default Area;
