import React from 'react'
import { Button, ButtonGroup, Message } from 'rsuite';
import { t } from 'i18next';
import { InfoIcon } from 'components/icons';

const ModuleTutorial = ({
    step,
    total,
    onChange,
    children,
    ...props
}) => {

    if (!step) return;

    const title = `${t('Map/Tutorial')} ${step}/${total}`;

    return (
        <Message className="tutorialMessage" {...props}>
            <div className="title">
                <h6> <InfoIcon /> {title} </h6>
            </div>
            <div style={{marginBottom: 20}}>
                { children }
            </div>
            <ButtonGroup>
                <Button appearance="ghost" onClick={() => onChange(step - 1)} disabled={ step === 1 }>
                    {t('Previous')}
                </Button>
                <Button appearance="primary" onClick={() => onChange(step === total ? 0 : step + 1)}>
                    { t(step === total ? t('Map/FinishTutorial') : t('Next')) }
                </Button>
            </ButtonGroup>
            { step !== total && (
                <Button appearance="link" onClick={() => onChange(0)}>
                    {t('Map/SkipTutorial')}
                </Button>
            )}
        </Message>
    )
}

export default ModuleTutorial