import React from 'react'
import { Message } from 'rsuite'

const ErrorMessage = ({
    children,
    ...props
}) => {
    return (
        <Message showIcon closable type="error" {...props}>
            { children }
        </Message>
    )
}
 
export function showErrorMessage(toaster, message) {
    toaster.push(<ErrorMessage>{message}</ErrorMessage>)
}

export default ErrorMessage