export function randomColorArray() {
    return [
        Math.floor(Math.random() * (235-52+1) + 52),
        Math.floor(Math.random() * (235-52+1) + 52),
        Math.floor(Math.random() * (235-52+1) + 52)
    ]
}

export function randomColorRGBA(alpha=1) {
    return colorArrayAsRGBA(randomColorArray(), alpha)
}

export function colorArrayAsRGBA(color, alpha=1) {
    return `rgba(${color.join(', ')}, ${alpha=1})`
}

export function getPanelContentHeight(panelContainerElement, padding = 10) {
    const panel = panelContainerElement?.firstChild;
    const panelHeight = panel?.clientHeight || 640
    const panelHeaderHeight = panel?.firstChild?.clientHeight || 50;
    const panelBody = panel?.lastChild;
    const panelMenuHeight = Array.from(panelBody?.children || []).slice(0, -1).reduce((p, c) => p + c.clientHeight, 0)
    const contentHeight = panelHeight - panelHeaderHeight - panelMenuHeight - padding;

    return contentHeight;
}