import React from 'react'
import { SelectPicker } from 'rsuite'

import { ModuleFilter } from 'features/webmapModule'
import { t } from 'i18next';
import AreaCard from '../cards/AreaCard';
import StopPlaceCard from '../cards/StopPlaceCard';
import SectionCard from '../cards/SectionCard';
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';


const SelectionFilter = ({}) => {
    const module = React.useContext(OnboardCountContext);

    const {
        areas = {},
        stopPlaces = {},
        sections = {},
        transportLines = {},
    } = module?.elements || {};

    const {
        areaId,
        stopPlaceId,
        sectionId,
        sectionDirection,
    } = module?.filters || {};

    const value = areaId ? `area-${areaId}` :
        stopPlaceId ? `stopPlace-${stopPlaceId}` :
            sectionId ? `section-${sectionId}` : '-';
         

    const data = [
        ...Object.values(areas), 
        ...Object.values(stopPlaces), 
        ...(sections?.[sectionId] ? [sections[sectionId]] : [])
    ].sortBy('name').map(selection => ({
        label: selection.name,
        elementType: selection.elementType,
        element: selection,
        value: `${selection.elementType}-${selection.id}`
    }))

    function renderItem(item) {
        return module?.getLabel(item?.element)
    }

    function handleChange(value) {
        const newFilters = {
            areaId: undefined,
            stopPlaceId: undefined,
            sectionId: undefined,
            sectionDirection: undefined,
        }

        if (value) {
            const [type, idStr] = value.split('-');
            newFilters[`${type}Id`] = Number(idStr);
        }


        module.applyFilters(newFilters);
    }

    return ( 
        <ModuleFilter
            name='selection'
            label={t('FT/MapSelection')}
            disabled={!module?.filters?.startDate && !module?.filters?.countDate}
            acceptor={SelectPicker}
            data={data}
            value={value}
            renderValue={(value, item) => renderItem(item)}
            renderMenuItem={(label, item) => renderItem(item)}
            onChange={handleChange}
        />
     )
}

export default SelectionFilter