import React from 'react'
import { t } from 'i18next';

import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import ModuleTablePanel from 'features/webmapModule/components/table/ModuleTablePanel';
import TransportLineTrafficTable from './TransportLineTrafficTable';
import StopPlaceTrafficPanelContent from './StopPlaceTrafficPanelContent';
import { ALIGHT_BOARD_COUNT, BOARD_COUNT, CROSS_SECTION_COUNT, SECTION_TRAFFIC, STOP_PLACE_TRAFFIC, TRANSPORT_LINE_TRAFFIC } from 'features/onboardCount/utils/constants';
import SectionTrafficPanelContent from './SectionTrafficPanelContent';
import JourneyStopTrafficTable from './JourneyStopTrafficTable';
import { getPanelContentHeight } from 'utils/style';
import { sum } from 'utils/math';


const OnboardCountTablePanel = () => {

    const count = React.useContext(OnboardCountContext);
    const [tableHeight, setTableHeight] = React.useState(0);
    const containerRef = React.useCallback(container => {
        if (container) {
            setTableHeight(getPanelContentHeight(container, 40))
        }
    });

    if (!count?.elements?.transportLines) return;

    const menu = count?.tableMenu;

    return (
        <ModuleTablePanel
            ref={containerRef}
            module={count}
            title={t('Tables')}
    >
            { menu?.value === BOARD_COUNT && <>
                <TransportLineTrafficTable 
                    ref={count?.table}
                    defaultVisibleColumns={{
                        traffic: ['board']
                    }}
                    totalTop
                    totalBottom
                    headerWidth={320}
                    height={tableHeight}/>
            </> }

            { menu?.value === ALIGHT_BOARD_COUNT && <>
                <JourneyStopTrafficTable 
                    ref={count?.table}
                    renderBeginningCount
                    renderEndingCount
                    defaultVisibleColumns={{
                        traffic: ['alight', 'board'],
                        stat: ['avg']
                    }}
                    headerWidth={320}
                    height={tableHeight}
                    totalTop
                    totalBottom
                    // getRowHighlightValue={row => sum(Object.values(row.traffic).map(traffic => (traffic?.alight?.avg || 0) + (traffic?.board?.avg || 0)))}
                    // getColHighlightValue={col => sum(Object.values(col).filter((value) => typeof(value) === 'number'))}
                />
            </> }

            { menu?.value === CROSS_SECTION_COUNT && <>
                <JourneyStopTrafficTable 
                    ref={count?.table}
                    defaultVisibleColumns={{
                        traffic: ['onward'],
                        stat: ['avg']
                    }}
                    headerWidth={320}
                    columnWidth={72}
                    minColumnWidth={72}
                    height={tableHeight}
                    getRowHighlightValue={row => sum(Object.values(row.traffic).map(traffic => traffic?.onward?.avg))}
                    rowHighlightLegend={t('FT/DailyPeakSection')}
                    colInnerHighlightLegend={t('FT/JourneyPeakSection')}
                    getColHighlightKeys={col => {
                        const maxColValue = Math.max(...Object.values(col).filter(value => typeof(value) === 'number'));
                        return Object.entries(col)
                            .filter(([_, value]) => value === maxColValue)
                            .map(([rowKey, _]) => rowKey)
                    }}
                    colHighlightColor='-'
                    getColHighlightValue={col => Math.max(Object.values(col).filter((value) => typeof(value) === 'number'))}
                />
            </> }

            { menu?.value === STOP_PLACE_TRAFFIC && <>
                
                <StopPlaceTrafficPanelContent 
                    ref={count?.table}
                    defaultVisibleColumns={{
                        traffic: count?.filters?.stopPlaceId || count?.filters?.areaId ? ['alight', 'board'] : ['board']
                    }}
                    totalTop
                    height={tableHeight}
                />

            </> }

            { menu?.value === SECTION_TRAFFIC && <>
                
                <SectionTrafficPanelContent 
                    ref={count?.table}
                    totalTop
                    height={tableHeight}
                />

            </> }
            

        </ModuleTablePanel>
    )
}

export default OnboardCountTablePanel