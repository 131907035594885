import React from 'react'
import { logEvent } from 'features/logging';
import { noop } from 'utils/constants';

export default function useFilters(onFilter = noop) {

    const [filters, setFilters] = React.useState({});

    function applyFilters(values = {}) {
        console.log(values);
        logEvent('applyFilters', values);
        setFilters(prev => ({
            ...prev,
            ...values,
        }));
        onFilter();
    };

    return {
        filters,
        applyFilters
    };
};
