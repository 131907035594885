import React from 'react'
import WebmapForm from './forms/WebmapForm'
import { createWebMap, postWebMapData } from '../webmapAPI'
import FormModal from 'components/modals/FormModal'
import { t } from 'i18next'
import { useMediaQuery } from 'rsuite'
import { noop } from 'utils/constants'

const WebMapModal = ({
    open,
    setOpen = noop,
    onClose = noop,
    item,
}) => {
    const [isMobile] = useMediaQuery('xs');

    return (
        <FormModal
            title={item?.id ? t('EditWebMap') : t('CreateWebMap')}
            size="lg"
            className="wide"
            layout={isMobile ? "vertical" : "horizontal"}
            open={open}
            setOpen={setOpen}
            FormClass={WebmapForm}
            formData={item}
            onSubmit={data => item
                ? postWebMapData(item?.id, data)
                : createWebMap(data)
            }
            onClose={onClose}
        />
    )
}

export default WebMapModal