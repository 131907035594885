import React from 'react'
import { t } from 'i18next';

import ModuleChartPanel from 'features/webmapModule/components/chart/ModuleChartPanel';
import CrossSectionRoadCountContext from 'features/crossSectionRoadCount/context/CrossSectionRoadCountContext';
import MonthlyStationTrafficChart from './MonthlyStationTrafficChart';
import { TRAFFIC_BY_DAY_TYPE, TRAFFIC_BY_MONTH } from 'features/crossSectionRoadCount/utils/constants';
import DayTypesStationTrafficChart from './DayTypesStationTrafficChart';
import { getPanelContentHeight } from 'utils/style';


const CrossSectionRoadCountChartPanel = () => {

    const count = React.useContext(CrossSectionRoadCountContext);
    const [chartHeight, setChartHeight] = React.useState(0);
    const containerRef = React.useCallback(container => {
        if (container) {
            setChartHeight(getPanelContentHeight(container))
        }
    });
    const chartSize = {
        height: chartHeight,
        marginLeft: 'auto',
        marginRight: 'auto',
    };

    if (!count?.filters?.stationCode || !count?.filters?.stationMonth) return;
    
    const menu = count?.chartMenu;
    
    return (
        <ModuleChartPanel
            ref={containerRef}
            module={count}
            title={t('FT/TrafficCharts')}
        >
            { menu?.value === TRAFFIC_BY_MONTH && <>
                <MonthlyStationTrafficChart 
                    ref={count?.chart}
                    size={chartSize} />
            </> }

            { menu?.value === TRAFFIC_BY_DAY_TYPE && <>
                <DayTypesStationTrafficChart 
                    ref={count?.chart}
                    size={chartSize} />
            </> }

        </ModuleChartPanel>
    )
}

export default CrossSectionRoadCountChartPanel