import React from 'react';
import { Checkbox, IconButton } from 'rsuite';
import { t } from 'i18next';

import { noop } from 'utils/constants';

import TypeFormattedCell from './TypeFormattedCell';
import { AngleDown, AngleUp } from 'components/icons';


const RowHeaderCell = ({ 
    rowData, 
    renderRowHeader = noop, 
    highlightColor = 'orange',
    selectable,
    selectedRows = [],
    onSelect = noop,
    onDeselect = noop,
    ...props
}) => {
    const value = rowData?.rowKey;
    const checked = rowData?.selected;
    const indeterminate = rowData?.childSelected;

    function handleChange (rowKey, checked) {
        if (!checked) {
            onDeselect(rowData)
        } else if (checked) {
            onSelect(rowData)
        }
    };

    const classNames = ['rowHeaderCell'];

    if (checked) {
        classNames.push('selected');
    }
    
    if (rowData?.disabled) {
        classNames.push('disabled');
    }

    if (rowData?.highlighted) {
        classNames.push('highlighted', `bg-${highlightColor}`)
    }

    return (
        <TypeFormattedCell rowData={rowData} className={classNames.join(' ')} {...props}>
            { selectable && (
                <div style={{display: 'inline-block'}}>
                    <Checkbox 
                        value={value} 
                        indeterminate={indeterminate}
                        disabled={rowData?.disabled}
                        checked={checked} 
                        onChange={handleChange} />
                </div>
            )}
            <div style={{display: 'inline-block'}}>
                { rowData?.isTotal ? t('Total') : renderRowHeader(rowData) }
            </div>
        </TypeFormattedCell>
    );
};

export default RowHeaderCell;
