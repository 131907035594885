import 'assets/css/form.css';

import React from "react";
import { Button, ButtonToolbar, Cascader, Form, InputNumber, SelectPicker, TagInput, TagPicker, TreePicker } from "rsuite";
import { t } from "i18next";

import { noop } from "utils/constants";
import { FormGroup } from 'components/forms';
import { getOrganizations } from 'features/onboardCount/onboardCountAPI';


const OnboardCountModuleForm = ({ 
    webMap,
    onSubmit = noop,
    onCancel = noop,
    ...props 
}) => {

    const [ organizations, setOrganizations ] = React.useState();

    const [ stopPlaceElementId, setStopPlaceElementId ] = React.useState(webMap?.modules?.onboardCount?.stopPlaceElementId);
    const [ sectionElementId, setSectionElementId ] = React.useState(webMap?.modules?.onboardCount?.sectionElementId);
    const [ configurations, setConfigurations ] = React.useState(webMap?.modules?.onboardCount?.configurations || []);
    const [ minTrafficSize, setMinTrafficSize ] = React.useState(webMap?.modules?.onboardCount?.minTrafficSize);
    const [ maxTrafficSize, setMaxTrafficSize ] = React.useState(webMap?.modules?.onboardCount?.maxTrafficSize);

    const elementOptions = webMap?.elements.map(element => ({
        label: element.name,
        value: element.id
    }))

    const submitData = {
        stopPlaceElementId,
        sectionElementId,
        configurations,
        minTrafficSize,
        maxTrafficSize,
    }

    const organizationOptions = (organizations || []).sortBy('abbrev').map(organizer => ({
        label: organizer.abbrev,
        value: organizer.id,
        children:(organizations || []).sortBy('abbrev').map(operator => ({
            label: operator.abbrev,
            value: `${organizer.id}-${operator.id}`,
        }))
    }))

    function getConfigurationFromValue (value) {
        const [organizerIdStr, operatorIdStr] = value.split("-");
        return {
            organizerId: parseInt(organizerIdStr),
            operatorId: parseInt(operatorIdStr)
        }
    }

    function addConfiguration (value) {
        setConfigurations([ ...configurations, getConfigurationFromValue(value)]);
    }

    React.useEffect(() => {
        getOrganizations()
            .then(({data}) => {
                setOrganizations(data)
            });
    }, [])

    return (
        <Form {...props}>


            <FormGroup 
                name={`configurations`}
                label={`${t('FT/Configurations')}`}
                accepter={TagPicker}
                data={configurations.map(({organizerId, operatorId}) => ({
                    label: `${organizations?.find(o => o?.id === organizerId)?.abbrev} / ${organizations?.find(o => o?.id === operatorId)?.abbrev}`,
                    value: `${organizerId}-${operatorId}`,
                }))}
                value={configurations.map(({organizerId, operatorId}) => `${organizerId}-${operatorId}`)}
                onChange={values => setConfigurations(values?.map(getConfigurationFromValue))}
                disabled={configurations?.length < 2}
                searchable={false}
            />

            <FormGroup 
                name={`addConfiguration`}
                label={t('FT/AddConfiguration')}
                placeholder={t('FT/AddConfigurationPrompt')}
                accepter={Cascader}
                data={ organizationOptions }
                cleanable={ false }
                value={null}
                onChange={ addConfiguration }
                searchable={false}
            />

            <FormGroup 
                name={`stopPlaceElementId`}
                label={`${t('FT/StopPlacesElement')}`}
                accepter={SelectPicker}
                data={elementOptions}
                value={stopPlaceElementId}
                cleanable={ false }
                onChange={ setStopPlaceElementId }
            />

            <FormGroup 
                name={`sectionElementId`}
                label={`${t('FT/SegmentsElement')}`}
                accepter={SelectPicker}
                data={elementOptions}
                value={sectionElementId}
                cleanable={ false }
                onChange={ setSectionElementId }
            />

            <FormGroup 
                name="minTrafficSize"
                label={t('FT/MinTrafficSize')}
                accepter={InputNumber}
                value={minTrafficSize}
                onChange={setMinTrafficSize}
            />
            
            <FormGroup 
                name="maxTrafficSize"
                label={t('FT/MaxTrafficSize')}
                accepter={InputNumber}
                value={maxTrafficSize}
                onChange={setMaxTrafficSize}
            />

            <Form.Group>
                <ButtonToolbar>
                    <Button appearance="primary" onClick={() => onSubmit && onSubmit(submitData)}>
                        { t('Save') }
                    </Button>
                    <Button appearance="primary" color="red" onClick={() => onSubmit && onSubmit()}>
                        { t('Delete') }
                    </Button>
                    <Button appearance="subtle" onClick={onCancel}>
                        { t('Cancel') }
                    </Button>
                </ButtonToolbar>
            </Form.Group>
        </Form>
    );
};


export default OnboardCountModuleForm;
