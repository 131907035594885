import React from 'react'
import { noop } from 'utils/constants';

function useMenu({
    data = [],
    defaultValue,
    onSelect = noop,
}) {
    const [ value, setValue ] = React.useState(defaultValue);
    let selectedNode = undefined;
    let isDisabled = false;

    const rootNav = {
        value: undefined,
        setValue,
        children: []
    }

    function getParent(parentKeys = []) {
        let parent = rootNav;
        parentKeys.forEach(key => {
            parent = parent?.children.find(n => n?.key === key);
        });
        return parent
    }

    function processNodes(nodes = [], parentKeys = []) {
        const parent = getParent(parentKeys)

        nodes.forEach((node, i) => {
            const nav = {
                ...node,
                active: false,
                setValue,
                parentKeys,
                children: []                
            };
            parent.children.push(nav)

            if (nav?.key === value) {
                nav.active = true;
                selectedNode = nav;
                let n = nav;
                while (n?.parentKeys?.length) {
                    const parent = getParent(n.parentKeys)
                    parent.value = n?.key;
                    parent.active = true;
                    n = parent;
                }     
            }

            processNodes(node?.children, [...parentKeys, node?.key]);
        });
    };

    processNodes(data)

    React.useEffect(() => {
        if (selectedNode?.children?.length) {
            setValue(selectedNode.children[0].key)
        } else {
            onSelect()
        }
    }, [value])

    return {
        navs: rootNav,
        value,
        setValue,
    }
}

export default useMenu