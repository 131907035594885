import 'assets/css/card.css';

import React from "react";
import { Avatar } from "rsuite";

import ActionToolbar from "./ActionToolbar";
import ActionButton from "./ActionButton";
import { Layover } from 'components/layovers';


const IMAGE_PLACEHOLDER_URL = "https://shahpourpouyan.com/wp-content/uploads/2018/10/orionthemes-placeholder-image-1.png";


const CardHeader = ({
    title,
    hint,
    subtitle,
    subtitleSeparator = <br />,
    description,
    actions,
    imageURL,
    expandAvatar,
    usePlaceholder,
    icon,
    iconBackgroundColor,
    overlayIcon,
    overlayHint,
    size,
    disabled = false,
}) => <div className={`cardHeader${disabled ? ' disabled' : ''}`}>
        <div 
            className={`icon${expandAvatar ? ' expandAvatar' : ''}`} 
            style={{
                paddingRight: (icon && !(imageURL || usePlaceholder)) ? 4 : undefined,
                ...(!(icon ||imageURL || usePlaceholder) ? {padding: 0, width: 0} : {})
            }}
        >    
            { icon && !(imageURL) 
                ? (
                    usePlaceholder
                        ? <div style={{position: 'relative', lineHeight: 0, borderRadius: 6, backgroundColor: `var(--rs-${iconBackgroundColor}-500)`}}>
                            <Avatar src={IMAGE_PLACEHOLDER_URL} size={size} style={{opacity: 0}} />
                            <Layover>
                                { icon }
                            </Layover>
                        </div>
                        : icon
                ) : <Avatar 
                    src={imageURL || usePlaceholder} 
                    alt={typeof(title) === 'string' ? title : typeof(hint) === 'string' ? hint : ''}  
                    size={size} />
            }
            { overlayIcon && (
                <Layover dark title={overlayHint} style={{fontSize: 'x-large'}}>
                    { overlayIcon }
                </Layover>
            )}
        </div>
        <div className={`content rs-panel-header${disabled ? ' disabled' : ''}`}  title={hint}>
            <div className="title" title={ !hint && typeof(title) === 'string' ? title : undefined }>
                {title}
            </div>
            { (subtitle || description) && (
                <div className="subtitle" title={ !hint && typeof(title) === 'string' ? subtitle : undefined }>
                    {!Array.isArray(subtitle) 
                        ? subtitle 
                        : subtitle.map((s, i) => <>{i > 0 && subtitleSeparator} {s}</>)}
                    { description && <div className="description" dangerouslySetInnerHTML={{__html: description}}></div>}
                </div>
            )}
        </div>

        {actions && (
            <ActionToolbar>
                {actions.map((action, i) => <ActionButton key={i} {...action} />)}
            </ActionToolbar>
        )}

    </div>


export default CardHeader;
