import React from 'react'
import CountIntervalCellContent, { countIntervalCellCSV } from './cells/CountIntervalCellContent'
import { t } from 'i18next'
import DataTable from 'features/dataTable/components/DataTable'
import AreaCard from '../cards/AreaCard'
import StopPlaceCard from '../cards/StopPlaceCard'
import Area from 'features/onboardCount/elements/Area'
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import StopPlace from 'features/onboardCount/elements/StopPlace'
import { getMeasuredCountIntervalIds } from 'features/onboardCount/elements/CountInterval'


function getColumnTypes (countIntervals = [], dayTypes = {}) {
    return [{
        name: 'countInterval',
        label: t('FT/CountInterval'),
        selectable: true,
        values: countIntervals.map(key => ({
            key,
            label: <CountIntervalCellContent value={key} />,
            csvLabel: countIntervalCellCSV({value: key,  dayTypes}),
        }))
    }, {
        name: 'traffic',
        label: t('FT/TrafficData'),
        values: [{
            key: 'alight',
            label: 'Leszállók száma',
            abbrev: 'Le'
        }, {
            key: 'board',
            label: 'Felszállók száma',
            abbrev: 'Fel'
        }]
    }, {
        name: 'stat',
        label: t('FT/StatType'),
        values: [{
            key: 'avg',
            label: 'Átlag',
            abbrev: 'Átl.',
            sortable: true,
        }]
    }]
}


const AreaTrafficTable = React.forwardRef(({
    stopPlacesOnly,
    ...props
}, ref) => {

    const count = React.useContext(OnboardCountContext);
    
    const {
        areas = {},
        stopPlaces = {},
        dayTypes = {},
    } = count?.elements || {};

    const transportLines = count?.cachedElements?.transportLines;

    const {
        areaId,
        stopPlaceId,
    } = count?.filters || {};

    const selectedRows = areaId ? [`area-${areaId}`] : 
        stopPlaceId ? [`stopPlace-${stopPlaceId}`] : 
        [];

    const data = !stopPlacesOnly ? Object.values(areas).map(area => Area({...area, stopPlaces, transportLines})) : (
        areaId ? 
            Object.values(stopPlaces).filter(stopPlace => stopPlace.areaId === areaId).map(stopPlace => StopPlace({...stopPlace, transportLines, stopPlaces})) :
            []
    );
    const countIntervals = getMeasuredCountIntervalIds(data, true);
    const columnTypes = getColumnTypes(countIntervals, dayTypes);

    function handleSelectRow( values ) {
        const newFilters = {
            areaId: undefined,
            stopPlaceId: undefined,
            sectionId: undefined,
            sectionDirectionid: undefined
        }

        if (values?.[0]) {     
            const [type, idStr] = values[0].split('-');
            newFilters[`${type}Id`] = Number(idStr);
        }

        count?.applyFilters(newFilters);
    }

    return (
        <DataTable
            ref={ref}
            title={t('FT/StopPlaceTraffic')}

            hover
            bordered
            cellBordered
            columnWidth={120}

            data={data}
            columnTypes={columnTypes}
            dataAttribute='traffic'

            isTree
            getChildren={row => row?.stopPlaces}
            getRowKey={row => `${row?.elementType}-${row?.elementId}`}

            onSelectRow={handleSelectRow}
            selectedRows={selectedRows}
            rowHeight={46}

            renderRowHeader={(rowData, csv) => {
                if (csv) {
                    return rowData?.name;
                }
                switch (rowData?.elementType) {
                    case 'area':
                        return <AreaCard size='xs' className={'inline'} {...rowData} />;
                    case 'stopPlace' :
                        return <StopPlaceCard size='xs' className={'inline'} {...rowData} />;
                    }
                }
            }
            csvExtraColumns={[{
                label: t('Type'),
                getValue: row => row?.elementType === 'area' ? t('FT/Area') : t('FT/StopPlace'),
            }]}

            {...props}
        />
    )
});

export default AreaTrafficTable