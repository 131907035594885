import React from 'react'
import { t } from 'i18next';

import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import { BOARD_COUNT, DAILY_TRAFFIC_DISTRIBUTION, LINE_TRAFFIC_DISTRIBUTION, STOP_PLACE_TRAFFIC, TRANSPORT_LINE_TRAFFIC } from 'features/onboardCount/utils/constants';
import ModuleChartPanel from 'features/webmapModule/components/chart/ModuleChartPanel';
import TransportLineBarChart from './TransportLineBarChart';
import DailyTrafficDistributionChart from './DailyTrafficDistributionChart';
import LineTrafficDistributionChart from './LineTrafficDistributionChart';
import TimeSeriesBarChart from './TimeSeriesBarChart';
import { getPanelContentHeight } from 'utils/style';
import AreaBarChart from './AreaBarChart';


const OnboardCountChartPanel = () => {

    const count = React.useContext(OnboardCountContext);
    const [chartHeight, setChartHeight] = React.useState(0);
    const containerRef = React.useCallback(container => {
        if (container) {
            setChartHeight(getPanelContentHeight(container, 30))
        }
    });
    const chartSize = {
        height: chartHeight,
    };

    if (!count?.elements?.transportLines) return;

    const menu = count?.chartMenu;


    const {
        section,
        area,
        stopPlace,
        timeSeriesIntervalIds,
    } = count?.selectedElements;
    
    return (
        <ModuleChartPanel
            ref={containerRef}
            module={count}
            title={t('Charts')}
        >
            { menu?.value?.startsWith(TRANSPORT_LINE_TRAFFIC) && <>
                { menu?.value?.endsWith(BOARD_COUNT) && <>
                    { timeSeriesIntervalIds.length > 1 ? <>
                        <TimeSeriesBarChart 
                            ref={count?.chart}
                            boardOnly={!(section || area || stopPlace)}
                            size={chartSize} />
                    </> : <>    
                        <TransportLineBarChart 
                            ref={count?.chart} 
                            boardOnly={!(section || area || stopPlace)}
                            colorByLineType={!(section || area || stopPlace)}
                            size={chartSize} />
                    </> }
                </> }

                { menu?.value?.endsWith(DAILY_TRAFFIC_DISTRIBUTION) && <>
                    <DailyTrafficDistributionChart 
                        ref={count?.chart} 
                        rollingHourCount 
                        ticksPerHour={4}
                        // segmentBy="lineType"
                        size={chartSize} />
                </> }

                { menu?.value?.endsWith(LINE_TRAFFIC_DISTRIBUTION) && <>
                    <LineTrafficDistributionChart 
                        ref={count?.chart}
                        size={chartSize} />
                    
                </> }
            </>}

            { menu?.value?.startsWith(STOP_PLACE_TRAFFIC) && <>
                { menu?.value?.endsWith(BOARD_COUNT) && <>
                    { timeSeriesIntervalIds.length > 1 ? <>
                        <TimeSeriesBarChart 
                            ref={count?.chart}
                            boardOnly={!(section || area || stopPlace)}
                            size={chartSize} />
                    </> : <>    
                        <AreaBarChart 
                            ref={count?.chart} 
                            size={chartSize} />
                    </> }
                </> }

                { menu?.value?.endsWith(DAILY_TRAFFIC_DISTRIBUTION) && <>
                    <DailyTrafficDistributionChart 
                        ref={count?.chart} 
                        rollingHourCount 
                        ticksPerHour={4}
                        size={chartSize} />
                </> }

                { menu?.value?.endsWith(LINE_TRAFFIC_DISTRIBUTION) && <>
                    <LineTrafficDistributionChart 
                        ref={count?.chart}
                        size={chartSize} />
                    
                </> }
            </>}

        </ModuleChartPanel>
    )
}

export default OnboardCountChartPanel