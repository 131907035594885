import 'assets/css/mapViewer.css';

import React from 'react';
import { t } from 'i18next';

import LoadingLayover from 'components/layovers/LoadingLayover';


const MapCanvas = ({
    arcGISMap,
    containerId,
    rendering,
    children,
    ...props
}) => {
    arcGISMap?.setContainer(containerId);
    return (
        <div className='mapCanvas'>
            <LoadingLayover position="top-right" loading={rendering} >
                {t('Map/AssemblingMapData')}
            </LoadingLayover>
            <div id={containerId} className="ArcGIS" {...props}>
                { arcGISMap?.initialized && children}
            </div>
        </div>
    );
};

export default MapCanvas;