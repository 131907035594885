import React from 'react';
import { t } from "i18next";

import { deleteBlogEntry, getBlogEntryList, postBlogEntry, postBlogEntryList } from "../blogAPI";
import BlogEntryModal from "./BlogEntryModal";
import { AuthContext } from "features/auth";
import { AnnouncementIcon, BlogEntryIcon, MaintenanceIcon, ReadIcon, TimeIcon, UnreadIcon, UpdateIcon } from 'components/icons';
import { IconButton } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import CardPanel from 'components/panels/CardPanel';


const BlogEntryContent = ({
    item
}) => (
    <div className="blogEntryContent">
        <div className="content" dangerouslySetInnerHTML={{ __html: item?.content }} />
        <img className="image" src={item?.coverUrl} />
    </div>
) 


const BlogEntryPanel = ({
    tag,
    category,
    featured,
    unread,
    renderContent,
    showAll,
    defaultPageSize,
    pageSizeOptions,
    tileHeight,
    imgHeight,
    ...props
}) => {
    
    const { adminVisible } = React.useContext(AuthContext);
    const navigate = useNavigate();
    const panelRef = React.useRef();

    function getItems ({page, pageSize}) {
        return getBlogEntryList({
            tag,
            category,
            featured,
            unread,
            page,
            pageSize,
        });
    }

    function deleteItem (item) {
        return deleteBlogEntry(item?.id)
    }
    
    async function getUnreadCount () {
        return getBlogEntryList({
            tag,
            category,
            featured,
            unread: true,
            page: 1,
            pageSize: 1
        }).then(({total}) => total)
    }

    async function markAsRead (item) {
        return postBlogEntry(item?.id, {markAsRead: true})
    }

    async function markAsUnread (item) {
        return postBlogEntry(item?.id, {markAsRead: false})
    }

    async function markAllAsRead () {
        return postBlogEntryList({
            tag,
            category,
            featured,
            unread,
            markAllAsRead: true
        }).then(() => panelRef?.current?.refresh());
    }

    const Modal = props => <BlogEntryModal announcement={featured} {...props} />

    return (
        <CardPanel 
            ref={panelRef}
            title={ featured ? t('Blog/Announcements') : t('Blog/Entries') }
            itemLabel={ featured ? t('Blog/Announcement') : t('BlogEntry') }
            icon={ featured ? <AnnouncementIcon /> : <BlogEntryIcon /> }

            getItems={getItems}
            deleteItem={adminVisible && deleteItem}

            getAlertCount={getUnreadCount}
            alertLabel={t('Unread')}
            alertIcon={<UnreadIcon />}
            alertAction={<IconButton
                size='sm' 
                icon={<ReadIcon />}
                title={t('Blog/MarkAllAsRead')}
                onClick={markAllAsRead}
            >
            </IconButton>}
            showAllURL={showAll && '/blog'}

            modalClass={adminVisible && Modal}
            defaultModalValues={{
                tags: tag ? [tag] : []
            }}

            getTitle={item => <>
                <TimeIcon style={{color: 'var(--rs-text-tertiary)', marginRight: 4}} />
                <span style={{color: 'var(--rs-text-tertiary)', fontWeight: 'normal'}}> {item.publicFrom}</span>
            </>}
            getSubtitle={item => <span style={{fontWeight: 'bold'}}>{item.title}</span>}
            getThumbnailURL={item => undefined}
            getIcon={item => {
                switch (item?.category) {
                    case 'announcement':
                        return <AnnouncementIcon style={{color: 'white'}}/>
                    case 'update':
                        return <UpdateIcon style={{color: 'white'}} />
                    case 'maintenance':
                        return <MaintenanceIcon style={{color: 'white'}} />
                    default:
                        return <BlogEntryIcon style={{color: 'white'}} />
                }
            }}
            getIconBackgroundColor={item => {
                switch (item?.category) {
                    case 'announcement':
                        return 'yellow'
                    case 'update':
                        return 'green'
                    case 'maintenance':
                        return 'orange'
                    default:
                        return
                }
            }}
            getCategory={item => item?.category === 'announcement' ? "announcement" : "blogEntry"}
            getChildren={item => <BlogEntryContent item={item} />}
            getActions={item => [{
                onClick: () => item?.isRead ? markAsUnread(item).then(panelRef?.current?.refresh()) : markAsRead(item).then(panelRef?.current?.refresh()),
                icon: item?.isRead 
                    ? <ReadIcon /> 
                    : <UnreadIcon />,
                appearance: item?.isRead 
                    ? "default" 
                    : "primary",
                color: "orange",
                title: item?.isRead 
                    ? t('Blog/MarkAsUnread') 
                    : t('Blog/MarkAsRead')
            }]}
            onItemClick={item => navigate(`/${item?.category === 'announcement' ? "announcements" : "blog"}/${item.id}`)}
            expandable={!renderContent}
            onItemExpand={(item, val) => {
                if (val) {
                    markAsRead(item);
                } else {
                    panelRef?.current?.refresh()
                }
            }}
            coverHeight={imgHeight}
            defaultPageSize={defaultPageSize}
            pageSizeOptions={pageSizeOptions}

            {...props}
        />
    )
};


export default BlogEntryPanel;
