import React from 'react'
import { SelectPicker } from 'rsuite'

import { ModuleFilter } from 'features/webmapModule'
import { t } from 'i18next';
import JourneyCard from '../cards/JourneyCard';
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';


const JourneyFilter = () => {
    const module = React.useContext(OnboardCountContext);
    const journeys = module?.elements?.journeys || {};

    return ( 
        <ModuleFilter 
            name='journey'
            label={t('FT/Journey')}
            acceptor={SelectPicker}
            disabled={!module?.filtersSet}
            placeholder={t('FT/AllJourneys')}
            data={Object.values(journeys).sortBy()}
            valueKey='id'
            // renderValue={id => journeys[id] ? <JourneyCard size="xs" {...journeys[id]} /> : t('FT/AllJourneys')}
            renderMenuItem={(_, journey) => <JourneyCard size="xs" {...journey} />}
            onChange={journeyId => module.applyFilters({journeyId})}
            value={module?.filters?.journeyId || '-'}
            virtualized
        />
     )
}

export default JourneyFilter