import React from 'react';
import { IconButton, Input } from 'rsuite';

import 'assets/css/form.css';
import { t } from 'i18next';
import { CloseIcon } from 'components/icons';
import { noop } from 'utils/constants';

const ImagePreview = ({
    value,
    setValue
}) => {
    return (
        <div className="imageInputPreview">
            <img src={value}/>
            <IconButton 
                appearance='primary'
                color='red'
                icon={<CloseIcon />} 
                size="xs" 
                onClick={() => setValue(undefined)} 
                title={t('RemoveImage')}
            />
        </div>
    )
}


const ImageURLInput = ({ ...props }) => {

    const ref = React.useRef();
    const [value, setValue] = React.useState('');

    const isPasted = value?.startsWith('data:image/');
    const onChange = props?.onChange || noop

    React.useEffect(() => {
        if (props?.value) {
            setValue(props?.value);
        }
    }, [props?.value])

    async function getClipboardImage () {
        const clipboardItems = await navigator.clipboard.read();
        for (const clipboardItem of clipboardItems) {
            const imageType = clipboardItem.types.find(type => type.startsWith('image/'))

            return clipboardItem.getType(imageType);
        }
    }

    function handlePaste () {
        getClipboardImage().then(file => {
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                onChange(reader.result);
            };
        })
    };

    return <>
        <Input 
            ref={ref} 
            { ...props } 
            disabled={ isPasted || props?.disabled}
            value={isPasted ? t('UploadImage') : (value || '') } 
            onPaste={handlePaste}
            onChange={onChange}
        />
        { value && <ImagePreview value={value} setValue={setValue} />}
    </>
}

export default ImageURLInput;