import { FilterIcon, ResetIcon } from 'components/icons'
import { ModuleToolbar } from 'features/webmapModule'
import { t } from 'i18next'
import React from 'react'
import CountIntervalFilter from './CountIntervalFilter'
import TransportLinesFilter from './TransportLinesFilter'
import JourneyFilter from './JourneyFilter'
import SelectionFilter from './SelectionFilter'
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext'
import { IconButton } from 'rsuite'
import CountIntervalSlider from './CountIntervalSlider'
import ServicePatternFilter from './ServicePatternFilter'


const OnboardCountToolbar = ({}) => {
    const module = React.useContext(OnboardCountContext);

    if (!module?.countId) return;

    return <>
        <ModuleToolbar
            module={module}
            title={t('Filters')}
            icon={<FilterIcon />}
            applyInstantly
            footer={<CountIntervalSlider />}
        >
            <CountIntervalFilter />

            <TransportLinesFilter />

            <ServicePatternFilter />

            <JourneyFilter />
            
            <SelectionFilter />
            <div style={{padding: '0 4px', display: 'flex', alignItems: 'center'}}>
                <IconButton 
                    icon={<ResetIcon />}
                    appearance='subtle'
                    title={t('ResetFilters')}
                    onClick={() => module.applyFilters({
                        servicePatternIds: [],
                        transportLineIds: [],
                        journeyId: undefined,
                        areaId: undefined,
                        stopPlaceId: undefined,
                        sectionId: undefined,
                        sectionDirection: undefined
                    })}

                />
            </div>
        </ModuleToolbar>
    </>
}

export default OnboardCountToolbar