import 'assets/css/button.css';

import React from "react";
import { Button, IconButton, Toggle } from "rsuite";


export const LabeledIconButton = ({
    label,
    showLabel,
    toggle,
    icon,
    ...props
}) => {
    const buttonProps = toggle
        ? {
            ...props,
            appearance: props?.checked ? 'primary' : 'subtle',
            checked: undefined,
            onClick: props?.onChange,
            onChange: undefined,
        }
        : props;

    return showLabel ? ( 
        <div className='labeledIconButton'>
            <Button {...buttonProps} >
                <div className='content'>
                    { icon }
                    { label }
                </div>
            </Button>
        </div>
    ) : (
        toggle
            ? <Toggle 
                title={label}
                checkedChildren={icon}
                unCheckedChildren={icon}
                {...props}
                size='md'
            />
            : <IconButton icon={icon} title={label} {...buttonProps}/>
    )
}