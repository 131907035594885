import DataChart, { Dataset } from 'features/chart/components/DataChart';
import OnboardCountContext from 'features/onboardCount/context/OnboardCountContext';
import { onboardCountColors } from 'features/onboardCount/utils/colors';
import { getChartLabels } from 'features/onboardCount/utils/labels';
import { t } from 'i18next';
import React from 'react'
import { formatString } from 'utils/format';

const AreaBarChart = React.forwardRef(({
    boardOnly,
    top=25,
    ...props
}, ref) => {
    
    const count = React.useContext(OnboardCountContext);

    const {
        areas = {}
    } = count?.elements || {};

    const countInterval = count?.selectedElements?.countInterval

    const datasets = [];

    datasets.push(
        Dataset({
            name: 'board',
            label: t('FT/DailyAverageBoardCount'),
            getValue: item => item?.traffic?.[countInterval?.str]?.board?.avg || 0,
            color: onboardCountColors.board,
        })
    );

    if (!boardOnly) {
        datasets.push(Dataset({
            name: 'alight',
            label: t('FT/DailyAverageAlightCount'),
            getValue: item => 0 - item?.traffic?.[countInterval?.str]?.alight?.avg || 0,
            color: onboardCountColors.alight,
        }))
    }


    const data = Object.values(areas)
        .sort((a, b) => {
            const trafficA = a?.traffic?.[countInterval?.str];
            const trafficB = b?.traffic?.[countInterval?.str];

            return trafficB?.board?.avg + trafficB?.alight?.avg - trafficA?.board?.avg - trafficA?.alight.avg
        })
        .slice(0, top)
    return (
        <DataChart 
            ref={ref}
            title={formatString(
                top && top < Object.values(areas).length ? t('FT/DailyAverageAreaCountChartTitleTopN') : t('FT/DailyAverageAreaCountChartTitle'), 
                {top: top && Math.min(top, data.length), ...getChartLabels(count)}
            )}
            data={data}
            sets={datasets}
            animation={false}
            scales={{
                y: {
                    stacked: true,
                    ticks: {
                        align: 'center',
                        minRotation: 0,
                        maxRotation: 0,
                    }
                },
                x: {
                    ticks: {
                        callback: val => Math.abs(val)
                    }
                }
            }}
            indexAxis='y'
            plugins={{
                tooltip: {
                    callbacks: {
                        label: ctx => {
                            return `${ctx.dataset.label}: ${Math.abs(ctx.dataset.data[ctx.dataIndex])}`
                        }
                    }
                }
            }}
            {...props}
        />
    )
});

export default AreaBarChart