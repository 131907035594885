import React from 'react';
import { AvatarCard } from 'components/cards';
import TransportLineBadge from '../badges/TransportLineBadge';
import { t } from 'i18next';


const ServicePatternCard = ({
    size='md',
    transportLine,
    routeName,
    name
}) => {
    return (
        <AvatarCard
            size={size}
            icon={<>
                <TransportLineBadge size={size} {...transportLine} />
            </>}
            title={routeName}
            subtitle={name}
            hint={`${t('FT/ServicePattern')}: ${transportLine.name} ${routeName} (${name})`}
        />
    );
};


export default ServicePatternCard;
